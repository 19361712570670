import React from 'react';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PunchCardsCard, DriverPunchCardsCard } from '../../components';
import { getPunchCardSummaries, refreshPunchCardSummaries } from '../../punchCardSlice';
import { PunchCardsForAllDrivers } from '../../../../models';

import './PunchCardsView.scss';

export function PunchCardsView() {
  const dispatch = useAppDispatch();

  const updatePunchCardAPI = useAppSelector(state => state.punchCards.updatePunchCardAPI);
  const refreshPunchCardSummariesAPI = useAppSelector(state => state.punchCards.refreshPunchCardSummariesAPI);
  const punchCardSummaries = useAppSelector(state => state.punchCards.punchCardSummaries);

  const [dateFrom, setDateFrom] = React.useState<moment.Moment>(moment().subtract(14,'d'));
  const [dateTo, setDateTo] = React.useState<moment.Moment>(moment());
  const [detailsDriver, setDetailsDriver] = React.useState<PunchCardsForAllDrivers | undefined>();

  React.useEffect(() => {
    dispatch(getPunchCardSummaries({
      fromDate: moment(dateFrom).format('YYYY-MM-DD'),
      toDate: moment(dateTo).format('YYYY-MM-DD'),
    }));
  }, []);

  React.useEffect(() => {
    if (!updatePunchCardAPI.loading && !!updatePunchCardAPI.value) {
      dispatch(refreshPunchCardSummaries({
        fromDate: moment(dateFrom).format('YYYY-MM-DD'),
        toDate: moment(dateTo).format('YYYY-MM-DD'),
      }));
    }
  }, [updatePunchCardAPI.loading, !!updatePunchCardAPI.value]);

  React.useEffect(() => {
    dispatch(refreshPunchCardSummaries({
      fromDate: moment(dateFrom).format('YYYY-MM-DD'),
      toDate: moment(dateTo).format('YYYY-MM-DD'),
    }));
  }, [dateFrom, dateTo]);

  // Manually trigger rerendering selected driver
  React.useEffect(() => {
    if (!!detailsDriver && !refreshPunchCardSummariesAPI.loading && !!refreshPunchCardSummariesAPI.value) {
      const currentDriverEmail = detailsDriver.driver.email;

      const summary = punchCardSummaries.find(s => s.driver.email === currentDriverEmail);

      if (!!summary) {
        setDetailsDriver(summary);
      }
    }
  }, [refreshPunchCardSummariesAPI.loading, !!refreshPunchCardSummariesAPI.value]);

  const onDriverDetailsClose = () => {
    setDetailsDriver(undefined);
  }

  return (
    <div className='punch-cards-view-component'>
      <PunchCardsCard fromDate={dateFrom} setFromDate={setDateFrom} toDate={dateTo} setToDate={setDateTo} viewDriverDetails={setDetailsDriver}/>

      {!!detailsDriver && <DriverPunchCardsCard summary={detailsDriver} onClose={onDriverDetailsClose}/>}
    </div>
  );
}
