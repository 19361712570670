import React from 'react';
import { BsMinecart, BsArrowRight, BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../app/hooks';

import './DumpYardsWidget.scss';

export function DumpYardsWidget() {
  const navigate = useNavigate();

  const dumpYardsLength = useAppSelector(state => state.dumpYards.templates.length);

  const onWidgetClick = () => {
    navigate('/dump_yards', { replace: true });
  };

  const onAddDriver = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

  };

  return (
    <div className='dumpyard-widget-component' onClick={onWidgetClick}>
      <div className='title-container'>
        <div className='title'>Dump Yards</div>
        <BsArrowRight />
      </div>

      <BsMinecart className='icon'/>

      {dumpYardsLength && <div className='title-total'>Total: <span className='title-total-count'>{dumpYardsLength}</span></div>}

      <div className='cta-container'>
        <div className='add-driver-button' onClick={onAddDriver}>
          <BsPlusCircle style={{ marginRight: 4 }}/>
          Add Dump Yard
        </div>
      </div>
    </div>
  );
}
