import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ConfirmationIcon } from '../../assets/images/confirmation.svg';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { useAppDispatch } from '../../app/hooks';
import { resetCreateOrder } from './createOrderSlice';
import { Order, OrderStatus } from '../../models';

import './CreateOrderConfirmation.scss';

export interface CreateOrderConfirmationProps {
  order: Order;
}

export function CreateOrderConfirmation(props: CreateOrderConfirmationProps) {
  const dispatch = useAppDispatch();

  const { order } = props;

  React.useEffect(() => {
    return () => {
      dispatch(resetCreateOrder())
    }
  }, []);

  const title = order.status === OrderStatus.OrderStatusCreated
    ? 'Order Created'
    : 'Order Received';

  return (
    <div className='create-order-confirmation-component'>
      <Link to='/'>
        <Close className='close-button'/>
      </Link>

      <div className='title'>{title}</div>
      <ConfirmationIcon className='confirmation-icon'/>
      <div className='order-id-text'>
        Order id: <span className='order-id-value-text'>{order.id}</span> 
        {order.status !== OrderStatus.OrderStatusCreated && <div>We will get back to you shortly</div>}
      </div>
    </div>
  );
}
