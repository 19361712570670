import React from 'react';

import { useAppDispatch } from '../../../../app/hooks';
import { getPendingOrders } from '../../clientDashboardSlice';
import { PendingOrdersTable } from '../PendingOrdersTable/PendingOrdersTable';

import './PendingOrdersCard.scss';

export function PendingOrdersCard() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getPendingOrders({}));
  }, []);

  return (
    <div className='pending-orders-card-component'>
      <div className='title'>Pending Orders</div>

      <PendingOrdersTable />
    </div>
  );
}
