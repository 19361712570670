import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { DumpYardTemplateForm, UpdateDumpYardInstructionsParams, triplePSDatasource } from '../../datasource';
import { DumpYard, DumpYardTemplate } from '../../models';
import { logout } from '../Auth';

export interface DriversState {
  getDumpYardTemplates: APIResult<DumpYardTemplate[]>;
  deleteDumpYardTemplate: APIResult<string>;
  createDumpYardTemplate: APIResult<DumpYardTemplate>;
  getDumpYards: APIResult<DumpYard[]>;
  refreshDumpYardsAPI: APIResult<DumpYard[]>;
  updateDumpYardTemplateAPI: APIResult<DumpYardTemplate>;
  updateDumpYardInstructionsAPI: APIResult<DumpYard>;

  templates: DumpYardTemplate[];
  dumpYards: DumpYard[];
  templateToRemove?: string;
  showAddDumpYardModal: boolean;
  showUpdateDumpYardModal?: DumpYardTemplate;
  showDeleteDumpYardConfirmationModal?: DumpYardTemplate;
  showUpdateDumpYardInstructionsModal?: DumpYard;
}

const initialState: DriversState = {
  getDumpYardTemplates: emptyAPIResult(),
  deleteDumpYardTemplate: emptyAPIResult(),
  createDumpYardTemplate: emptyAPIResult(),
  getDumpYards: emptyAPIResult(),
  refreshDumpYardsAPI: emptyAPIResult(),
  updateDumpYardTemplateAPI: emptyAPIResult(),
  updateDumpYardInstructionsAPI: emptyAPIResult(),

  templates: [],
  dumpYards: [],
  showAddDumpYardModal: false
}

export const getDumpYardTemplates = createAsyncThunk(
  'dumpYards/getDumpYardTemplates',
  async () => {
    return triplePSDatasource.getDumpYardTemplates();
  }
);

export const deleteDumpYardTemplate = createAsyncThunk(
  'dumpYards/deleteDumpYardTemplate',
  async (id: string) => {
    return triplePSDatasource.deleteDumpYardTemplate(id);
  }
);

export const createDumpYardTemplate = createAsyncThunk(
  'dumpYards/createDumpYardTemplate',
  async (form: DumpYardTemplateForm) => {
    return triplePSDatasource.createDumpYardTemplate(form);
  }
);

export const getDumpYards = createAsyncThunk(
  'dumpYards/getDumpYards',
  async (date: string) => {
    return triplePSDatasource.getDumpYards({ date });
  }
);

export const refreshDumpYards = createAsyncThunk(
  'dumpYards/refreshDumpYards',
  async (date: string) => {
    return triplePSDatasource.getDumpYards({ date });
  }
);

export const updateDumpYardTemplate = createAsyncThunk<DumpYardTemplate, DumpYardTemplate,{ rejectValue: ApiError }>(
  'dumpYards/updateDumpYardTemplate',
  async (template, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.updateDumpYardTemplate(template);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const updateDumpYardInstructions = createAsyncThunk<DumpYard, UpdateDumpYardInstructionsParams,{ rejectValue: ApiError }>(
  'dumpYards/updateDumpYardInstructions',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.updatePickupInstructions(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);


export const dumpYardsSlice = createSlice({
  name: 'dumpYards',
  initialState,
  reducers: {
    dumpYardUnscheduled(state, payload) {
      const dumpYards = state.dumpYards.filter(dy => dy.id !== payload.payload);
      state.dumpYards = dumpYards;
    },
    setTemplateToRemove(state, action) { state.templateToRemove = action.payload },
    toggleShowAddDumpYardModal(state) { state.showAddDumpYardModal = !state.showAddDumpYardModal },
    showDeleteDumpYardTemplateConfirmationModal(state, action) { state.showDeleteDumpYardConfirmationModal = action.payload },
    resetDeleteDumpYardTemplateConfirmationModal(state) { state.showDeleteDumpYardConfirmationModal = undefined },
    setShowUpdateDumpYardModal(state, action) { state.showUpdateDumpYardModal = action.payload },
    resetUpdateDumpYardTemplate(state) { state.updateDumpYardTemplateAPI = initialState.updateDumpYardTemplateAPI },
    setShowUpdateDumpYardInstructionsModal(state, action) { state.showUpdateDumpYardInstructionsModal = action.payload },
  },
  extraReducers: builder => {
    builder
      .addCase(getDumpYardTemplates.pending, state => {
        state.getDumpYardTemplates = loadingAPIResult();
      })
      .addCase(getDumpYardTemplates.fulfilled, (state, action) => {
        state.getDumpYardTemplates = successAPIResult(action.payload);
        state.templates = action.payload;
      })
      .addCase(getDumpYardTemplates.rejected, (state, action) => {
        state.getDumpYardTemplates = errorAPIResult(action.error);
      })
      .addCase(getDumpYards.pending, state => {
        state.getDumpYards = loadingAPIResult();
      })
      .addCase(getDumpYards.fulfilled, (state, action) => {
        state.getDumpYards = successAPIResult(action.payload);
        state.dumpYards = action.payload;
      })
      .addCase(getDumpYards.rejected, (state, action) => {
        state.getDumpYards = errorAPIResult(action.error);
      })
      .addCase(deleteDumpYardTemplate.pending, state => {
        state.deleteDumpYardTemplate = loadingAPIResult();
      })
      .addCase(deleteDumpYardTemplate.fulfilled, (state, action) => {
        state.deleteDumpYardTemplate = successAPIResult(action.payload);
        state.templates = state.templates ? state.templates.filter(t => t.id !== action.payload) : [];
        state.templateToRemove = undefined;
      })
      .addCase(deleteDumpYardTemplate.rejected, (state, action) => {
        state.deleteDumpYardTemplate = errorAPIResult(action.error);
      })
      .addCase(createDumpYardTemplate.pending, state => {
        state.createDumpYardTemplate = loadingAPIResult();
      })
      .addCase(createDumpYardTemplate.fulfilled, (state, action) => {
        state.createDumpYardTemplate = successAPIResult(action.payload);
        state.templates.push(action.payload);
      })
      .addCase(createDumpYardTemplate.rejected, (state, action) => {
        state.createDumpYardTemplate = errorAPIResult(action.error);
      })
      .addCase(refreshDumpYards.pending, state => {
        state.refreshDumpYardsAPI = loadingAPIResult();
      })
      .addCase(refreshDumpYards.fulfilled, (state, action) => {
        state.refreshDumpYardsAPI = successAPIResult(action.payload);
        state.dumpYards = action.payload;
      })
      .addCase(refreshDumpYards.rejected, (state, action) => {
        state.refreshDumpYardsAPI = errorAPIResult(action.error);
      })
      .addCase(updateDumpYardTemplate.pending, state => {
        state.updateDumpYardTemplateAPI = loadingAPIResult();
      })
      .addCase(updateDumpYardTemplate.fulfilled, (state, action) => {
        state.updateDumpYardTemplateAPI = successAPIResult(action.payload);
        state.templates = state.templates.map(t => t.id === action.payload.id ? action.payload : t);
      })
      .addCase(updateDumpYardTemplate.rejected, (state, action) => {
        state.updateDumpYardTemplateAPI = errorAPIResult(action.error);
      })
      .addCase(updateDumpYardInstructions.pending, state => {
        state.updateDumpYardInstructionsAPI = loadingAPIResult();
      })
      .addCase(updateDumpYardInstructions.fulfilled, (state, action) => {
        state.updateDumpYardInstructionsAPI = successAPIResult(action.payload);

        const dumpYards = state.dumpYards.map(dy => dy.id === action.payload.id ? action.payload : dy);
        state.dumpYards = dumpYards;
      })
      .addCase(updateDumpYardInstructions.rejected, (state, action) => {
        state.updateDumpYardInstructionsAPI = errorAPIResult(action.error);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getDumpYardTemplates = initialState.getDumpYardTemplates;
          state.deleteDumpYardTemplate = initialState.deleteDumpYardTemplate;
          state.createDumpYardTemplate = initialState.createDumpYardTemplate;
          state.getDumpYards = initialState.getDumpYards;
          state.refreshDumpYardsAPI = initialState.refreshDumpYardsAPI;

          state.templates = initialState.templates;
          state.dumpYards = initialState.dumpYards;
          state.templateToRemove = undefined;
          state.showAddDumpYardModal = initialState.showAddDumpYardModal;
          state.showDeleteDumpYardConfirmationModal = undefined;
        }
      )
  }
});

export const { 
  dumpYardUnscheduled, 
  setTemplateToRemove, 
  toggleShowAddDumpYardModal, 
  showDeleteDumpYardTemplateConfirmationModal, 
  resetDeleteDumpYardTemplateConfirmationModal, 
  setShowUpdateDumpYardModal, 
  resetUpdateDumpYardTemplate,
  setShowUpdateDumpYardInstructionsModal
} = dumpYardsSlice.actions;
export default dumpYardsSlice.reducer;
