import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import authReducer from '../features/Auth/authSlice';
import createOrderReducer from '../features/CreateOrder/createOrderSlice';
import cancelOrderReducer from '../features/CancelOrder/cancelOrderSlice';
import driversReducer from '../features/Drivers/driversSlice';
import ordersReducer from '../features/Orders/ordersSlice';
import schedulesReducer from '../features/Schedules/scheduleSlice';
import deliveriesReducer from '../features/Deliveries/deliveriesSlice';
import dumpYardsReducer from '../features/DumpYards/dumpYardsSlice';
import pickupsReducer from '../features/Pickups/pickUpsSlice';
import permanentRentalsReducer from '../features/PermanentRentals/permanentRentalsSlice';
import receivedOrdersReducer from '../features/ReceivedOrders/receivedOrdersSlice';
import clientDashboardReducer from '../features/ClientDashboard/clientDashboardSlice';
import punchCardReducer from '../features/PunchCards/punchCardSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    createOrder: createOrderReducer,
    cancelOrder: cancelOrderReducer,
    drivers: driversReducer,
    orders: ordersReducer,
    schedules: schedulesReducer,
    deliveries: deliveriesReducer,
    dumpYards: dumpYardsReducer,
    pickups: pickupsReducer,
    permanentRentals: permanentRentalsReducer,
    receivedOrders: receivedOrdersReducer,
    clientDashboard :clientDashboardReducer,
    punchCards: punchCardReducer,
  },
  middleware: (defaultMiddleware) => defaultMiddleware().concat(logger)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
