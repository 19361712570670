import React from 'react';
import { BsCalendar2Week, BsArrowRight, BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getDumpYardTemplates } from '../../DumpYards/dumpYardsSlice';

import './ScheduleWidget.scss';

export function ScheduleWidget() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dashboardScheduleStats = useAppSelector(state => state.schedules.dashboardScheduleStats);

  const onWidgetClick = () => {
    navigate('/schedules', { replace: true });
  };

  const onCreateSchedule = () => {
    console.log('onCreateSchedule');
  };

  React.useEffect(() => {
    dispatch(getDumpYardTemplates());
  }, []);

  return (
    <div className='schedule-widget-component' onClick={onWidgetClick}>
      <div className='title-container'>
        <div className='title'>Schedule</div>
        <BsArrowRight />
      </div>

      <BsCalendar2Week className='icon'/>

      <div className='totals-container'>
        <div className='title-total' style={{ marginRight: 8 }}>Today: <span className='title-total-count'>{dashboardScheduleStats ? dashboardScheduleStats.todayCount : 'NA'}</span></div>
        <div className='title-total'>This Month: <span className='title-total-count'>{dashboardScheduleStats ? dashboardScheduleStats.thisMonthCount : 'NA'}</span></div>
      </div>

      <div className='cta-container'>
        <div className='create-schedule-button' onClick={onCreateSchedule}>
          <BsPlusCircle style={{ marginRight: 4 }}/>
          Create Schedule
        </div>
      </div>
    </div>
  );
}
