import React from 'react';

import { useAppDispatch } from '../../../../app/hooks';
import { getActiveOrders } from '../../clientDashboardSlice';
import { ActiveOrdersTable } from '../ActiveOrdersTable/ActiveOrdersTable';

import './ActiveOrdersCard.scss';
import { Link } from 'react-router-dom';

export function ActiveOrdersCard() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getActiveOrders({}));
  }, []);

  return (
    <div className='active-orders-card-component'>
      <div className='row'>
        <div className='title'>Active Orders</div>
        
        <Link to='/customer_orders/history' className='order-history-button'>Order History</Link>
      </div>

      <ActiveOrdersTable />
    </div>
  );
}
