import { Entity } from './Entity';

export enum DumpsterSize {
  SIZE_10 = '10',
  SIZE_15 = '15',
  SIZE_CLEAN_FILL = 'clean_fill'
}

export enum ServiceType {
  DUMPSTER = 'dumpster',
  JUNK = 'junk'
}

export enum MaterialDisposing {
  CONSTRUCTION = 'construction',
  HOUSEHOLD = 'household',
  HOUSEHOLD_CONSTRUCTION = 'Household/Construction'
}

export enum CleanFillMaterialsDisposing {
  BRICK = 'brick',
  BLOCK = 'block',
  DIRT = 'dirt',
  CONCRETE = 'concrete',
  OTHER = 'other'
}

export enum PaymentType {
  CASH = 'cash',
  CARD = 'card',
  CHECK = 'check',
  PAYPAL = 'paypal',
  VENMO = 'venmo',
  OWES = 'owes'
}

export enum OrderStatus {
  OrderStatusCreated = 'created',
  OrderStatusScheduled = 'scheduled',
  OrderStatusDelivered = 'delivered',
  OrderStatusScheduledToPickup = 'pickup_scheduled',
  OrderStatusPickedUp = 'picked_up',
  OrderStatusComplete = 'complete',
  OrderStatusCanceled = 'canceled',
  OrderStatusReceived = 'received'
}

export interface Order extends Entity {
  userId?: string;
  name: string;
  address: string;
  zip: string;
  phoneNumber: string;
  email: string;
  materialDisposing?: MaterialDisposing;
  description?: string;
  specialInstructions?: string;
  service: ServiceType;
  dumpsterSize?: DumpsterSize;
  deliveryDate: string;
  pickupDate: string;
  status: string;
  createdAt: string;
  price: number;
  deliveryFee: number;
  permanentRentalId?: string;
  po?: string;
  discount: number;
  additionalCharges: number;
  paymentType?: PaymentType;
}

export const orderStatusToText: Record<OrderStatus, string> = {
  [OrderStatus.OrderStatusCreated]: 'Created',
  [OrderStatus.OrderStatusScheduled]: 'Scheduled',
  [OrderStatus.OrderStatusDelivered]: 'Delivered',
  [OrderStatus.OrderStatusScheduledToPickup]: 'Scheduled to pick up',
  [OrderStatus.OrderStatusPickedUp]: 'Picked up',
  [OrderStatus.OrderStatusComplete]: 'Complete',
  [OrderStatus.OrderStatusCanceled]: 'Canceled',
  [OrderStatus.OrderStatusReceived]: 'Received',
}

export const dumpsterSizeToText: Record<DumpsterSize, string> = {
  [DumpsterSize.SIZE_10]: '10 yards',
  [DumpsterSize.SIZE_15]: '15 yards',
  [DumpsterSize.SIZE_CLEAN_FILL]: 'Clean Fill'
}

export const materialDisposingToText: Record<MaterialDisposing, string> = {
  [MaterialDisposing.CONSTRUCTION]: 'Construction',
  [MaterialDisposing.HOUSEHOLD]: 'Household',
  [MaterialDisposing.HOUSEHOLD_CONSTRUCTION]: 'Mix'
}
