import { Order } from '../../../models';
import moment from 'moment';

export const getOrder = (data: any): Order => {
  return {
    id: data.id,
    name: data.attributes.name,
    address: data.attributes.address,
    zip: data.attributes.zip,
    phoneNumber: data.attributes.phone_number,
    email: data.attributes.email,
    service: data.attributes.service,
    materialDisposing: data.attributes.material_disposing,
    dumpsterSize: data.attributes.dumpster_size,
    specialInstructions: data.attributes.special_instructions,
    deliveryDate: moment(data.attributes.delivery_date).format('MM-DD-YYYY'),
    pickupDate: moment(data.attributes.pick_up_date).format('MM-DD-YYYY'),
    status: data.attributes.status,
    createdAt: moment(data.attributes.created_at).format('MM-DD-YYYY'),
    price: data.attributes.price,
    deliveryFee: data.attributes.delivery_fee,
    po: data.attributes.po,
    discount: data.attributes.discount,
    description: data.attributes.description,
    paymentType: data.attributes.payment_type,
    additionalCharges: +data.attributes.additional_charges,
    userId: data.attributes.user_id,
  };
}

export const getOrders = (data: any): Order[] => {
  return data.map(getOrder);
}
