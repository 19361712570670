import { Address } from '../../../models';

export const getAddress = (data: any): Address => {
  return {
    id: data.id,
    userId: data.attributes.user_id,
    address: data.attributes.address_1,
    zip: data.attributes.zip,
  };
}

export const getAddresses = (data: any): Address[] => {
  return data.map(getAddress);
}
