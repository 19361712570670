import React from 'react';
import moment from 'moment';

import { DumpsterSize, MaterialDisposing, Order, dumpsterSizeToText, materialDisposingToText } from '../../../../models';

import './OrderDetailsCard.scss';

export interface OrderDetailsCardProps {
  order: Order;
}

export function OrderDetailsCard(props: OrderDetailsCardProps) {
  const { order } = props;

  return (
    <div className='order-details-card-component'>
      <div className='title'>Order Details</div>

      <div className='details-container'>
        <div className='column'>
          <div className='item-container'>
            <div className='label'>Address:</div>
            <div className='value'>{order.address}</div>
          </div>

          <div className='item-container'>
            <div className='label'>Zip Code:</div>
            <div className='value'>{order.zip}</div>
          </div>

          <div className='item-container'>
            <div className='label'>Delivery Date:</div>
            <div className='value'>{moment(order.deliveryDate).format('ll')}</div>
          </div>

          <div className='item-container'>
            <div className='label'>Pickup Date:</div>
            <div className='value'>{moment(order.pickupDate).format('ll')}</div>
          </div>
        </div>

        <div className='column'>
          <div className='item-container'>
            <div className='label'>Dumpster Size:</div>
            <div className='value'>{dumpsterSizeToText[order.dumpsterSize as DumpsterSize]}</div>
          </div>

          <div className='item-container'>
            <div className='label'>Material Disposing:</div>
            <div className='value'>{materialDisposingToText[order.materialDisposing as MaterialDisposing]}</div>
          </div>

          <div className='item-container'>
            <div className='label'>Description:</div>
            <div className='value'>{order.description || 'NA'}</div>
          </div>

          <div className='item-container'>
            <div className='label'>Special Instructions:</div>
            <div className='value'>{order.specialInstructions || 'NA'}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
