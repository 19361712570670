import React from 'react';
import { useTable, Column, useSortBy } from 'react-table';
import { BsPlusCircle, BsSearch, BsSortDown, BsSortUp, BsTrash, BsPencil } from 'react-icons/bs';
import { Oval } from 'react-loader-spinner';

import { toggleShowAddDumpYardModal, showDeleteDumpYardTemplateConfirmationModal, setShowUpdateDumpYardModal } from '../dumpYardsSlice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { DumpYardTemplate } from '../../../models';

import './DumpYards.scss';

type ColumnsType = DumpYardTemplate;

export function DumpYards() {
  const dispatch = useAppDispatch();

  const dumpYardTemplates = useAppSelector(state => state.dumpYards.templates);
  const deleteDumpYardTemplateAPI = useAppSelector(state => state.dumpYards.deleteDumpYardTemplate);
  const templateToRemove = useAppSelector(state => state.dumpYards.templateToRemove);

  const [search, setSearch] = React.useState<string>('');

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value);
  }

  const onAddDumpYard = () => {
    dispatch(toggleShowAddDumpYardModal());
  }

  const data = React.useMemo(() => {
    if (search === '') return dumpYardTemplates;

    return dumpYardTemplates.filter(d => d.address.toLowerCase().includes(search) || d.zip.toLowerCase().includes(search));
  }, [dumpYardTemplates, search]);

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'ZIP',
        accessor: 'zip',
      },
      {
        Header: 'Dump Yard',
        accessor: 'isDumpYard',
        Cell: props => <div>{props.row.original.isDumpYard ? 'Yes' : 'No'}</div>
      },
      {
        Header: 'Storage',
        accessor: 'isStorage',
        Cell: props => <div>{props.row.original.isStorage ? 'Yes' : 'No'}</div>
      },
      {
        Header: 'Quick Stop',
        accessor: 'isQuickStop',
        Cell: props => <div>{props.row.original.isQuickStop ? 'Yes' : 'No'}</div>
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: props => {
          const { value, row } = props;

          const onEditOrder = () => {
            dispatch(setShowUpdateDumpYardModal(row.original));
          };

          const onRemoveTemplate = () => {
            dispatch(showDeleteDumpYardTemplateConfirmationModal(row.original));
          };

          return (
            <div className='actions-cell'>
              <div className='update-icon-container' onClick={onEditOrder}><BsPencil className='update-icon'/></div>
              {deleteDumpYardTemplateAPI.loading && templateToRemove === value ? <Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/> : <BsTrash className='delete-icon' onClick={onRemoveTemplate}/>}
            </div>
          );
        }
      }
    ],
    [templateToRemove]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  return (
    <div className='dump-yards-component'>
      <div className='controllers-container'>
        <div className='left-controllers-container'>
          <div className='title-container'>
            <div className='title-text'>Dump yards</div>
            <div className='title-count'>{data.length}</div>
          </div>

          <div className='search-container'>
            <BsSearch className='search-icon'/>
            <input
              value={search}
              placeholder='Search'
              onChange={onInputChange}
            />
          </div>
        </div>

        <div className='add-button' onClick={onAddDumpYard}>
          <BsPlusCircle className='icon'/>  
          Add Dump Yard
        </div>
      </div>

      <div className='table-container'>
        <table {...getTableProps()} className='tp-table'>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className='header-cell'>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <BsSortDown className='sorting-icon'/>
                          : <BsSortUp className='sorting-icon'/>
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
