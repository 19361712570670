import React from 'react';

import { DriverPunchCardsTable } from '../DriverPunchCardsTable/DriverPunchCardsTable';
import { PunchCardsForAllDrivers, getWorkTimeForDriver } from '../../../../models';

import { ReactComponent as Close } from '../../../../assets/images/close.svg';
import './DriverPunchCardsCard.scss';

export interface DriverPunchCardsCardProps {
  summary: PunchCardsForAllDrivers;
  onClose: () => void;
}

export function DriverPunchCardsCard(props: DriverPunchCardsCardProps) {
  const { summary, onClose } = props; 

  return (
    <div className='driver-punch-cards-card-component'>
      <div className='title-container'>
        <div className='left-container'>
          <div className='title'>{`${summary.driver.firstName} ${summary.driver.lastName}`}</div>
          <div className='subtitle'>{`Total: ${getWorkTimeForDriver(summary.summary)}`}</div>
        </div>

        <div className='right-container'>
          <Close className='close-button' onClick={onClose}/>
        </div>
      </div>
      
      <DriverPunchCardsTable summary={summary.summary}/>
    </div>
  );
}
