import React from 'react';

import { ReactComponent as PieIcon } from '../../../../assets/images/pie_icon.svg';
import './OrderDetailsOverviewCard.scss';

export function OrderDetailsOverviewCard() {
  return (
    <div className='overview-card-component'>
      <div className='icon-container'>
        <PieIcon />
      </div>

      <div className='title-container'>
        <div className='title'>Order Details</div>
        <div className='subtitle'>Details about your order</div>
      </div>
    </div>
  );
}
