import { ApproveOrderParams } from '../TriplePInterface';

export interface ApproveOrderPayload {
  additional_pricing: {
    delivery_fee: number;
    discount?: number;
  };
  additional_items: {
    description: string;
    quantity: number;
    base_price: number;
  }[]
}

export const toApproveOrderPayload = (form: ApproveOrderParams): ApproveOrderPayload => {
  const additionalItems = form.form.aditionalItems.map(i => {
    return {
      description: i.description,
      quantity: i.quantity,
      base_price: i.basePrice
    };
  })

  return {
    additional_pricing: {
      delivery_fee: form.form.deliveryFee,
      discount: form.form.discount
    },
    additional_items: additionalItems
  };
}
