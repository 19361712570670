import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult } from '../../lib/redux';
import { auth0Datasource, triplePSDatasource, CreateUserRequest, LoginRequest } from '../../datasource';
import { LOCAL_STORAGE_KEYS } from '../../lib/localStorage';
import { Role, User } from '../../models';
import { RootState } from '../../app/store';

export interface AuthState {
  createUser: APIResult<string>;
  login: APIResult<string>;
  jwtToken?: string;
  getProfile: APIResult<User>;
  user?: User;
}

const initialState: AuthState = {
  createUser: emptyAPIResult(),
  login: emptyAPIResult(),
  getProfile: emptyAPIResult()
}

export const createUser = createAsyncThunk(
  'auth/createUser',
  async (request: CreateUserRequest) => {
    return triplePSDatasource.createUser(request);
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (request: LoginRequest) => {
    return auth0Datasource.getToken(request);
  }
);

export const getProfile = createAsyncThunk(
  'auth/getProfile',
  async () => {
    return triplePSDatasource.getProfile();
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetCreateUser(state) { state.createUser = initialState.createUser },
    resetLogin(state) { state.login = initialState.login },
    setJwtToken(state, action) { state.jwtToken = action.payload.jwtToken },
    logout(state) {
      state.createUser = initialState.createUser;
      state.getProfile = initialState.getProfile;
      state.login = initialState.login;

      state.jwtToken = undefined;
      state.user = undefined;
      localStorage.removeItem(LOCAL_STORAGE_KEYS.JWT_TOKEN);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createUser.pending, state => {
        state.createUser = loadingAPIResult();
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.createUser = successAPIResult(action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUser = errorAPIResult(action.error);
      })
      .addCase(login.pending, state => {
        state.login = loadingAPIResult();
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login = successAPIResult(action.payload);
        state.jwtToken = action.payload;
        localStorage.setItem(LOCAL_STORAGE_KEYS.JWT_TOKEN, action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        state.login = errorAPIResult(action.error);
      })
      .addCase(getProfile.pending, state => {
        state.getProfile = loadingAPIResult()
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfile = successAPIResult(action.payload);
        state.user = action.payload;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.getProfile = errorAPIResult(action.error);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.JWT_TOKEN);
      })
  }
});

const isAdmin = (state: RootState) => state.auth.user?.roles.includes(Role.ADMIN);
export const isAdminSelector = createSelector([isAdmin], isAdmin => isAdmin);

const isClient = (state: RootState) => state.auth.user?.roles.includes(Role.CLIENT);
export const isClientSelector = createSelector([isClient], isClient => isClient);

export const { resetCreateUser, resetLogin, setJwtToken, logout } = authSlice.actions;
export default authSlice.reducer;
