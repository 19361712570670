import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import * as Yup from 'yup';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { cancelOrderAPI } from './cancelOrderSlice';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { CancelOrderConfirmation } from './CancelOrderConfirmation';

import './CancelOrder.scss';

export interface CancelOrderFormModel {
  id: string;
}

const validationSchema = Yup.object().shape({
  id: Yup.string()
    .required('Order ID is required'),
});

export function CancelOrder() {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const isLoading = useAppSelector(state => state.cancelOrder.cancelOrder.loading);
  const error = useAppSelector(state => state.cancelOrder.cancelOrder.error);
  const cancelOrderSuccess = useAppSelector(state => !!state.cancelOrder.cancelOrder.value);

  const orderId = query.get('order_id') || '';

  const initialValues: CancelOrderFormModel = {
    id: orderId
  };

  React.useEffect(() => {
    if (!isLoading && !!error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!isLoading && !error && !!cancelOrderSuccess) {
      toast.success('Successfully canceled order.');
    }
  }, [!!cancelOrderSuccess, error]);

  const onSubmit = (form: CancelOrderFormModel) => {
    dispatch(cancelOrderAPI(form.id));
  }

  if (cancelOrderSuccess) {
    return <CancelOrderConfirmation />
  }

  return (
    <div className='cancel-order-component'>
      <Link to='/'>
        <Close className='close-button'/>
      </Link>

      <div className='title'>Cancel Order</div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
         {({ errors, touched }) => (
          <Form className='cancel-order-form-component'>
            <div className='field-container'>
              <div className='label'>Order ID:</div>
              <Field id='id' name='id' className='text-input'/>
              {errors.id && touched.id && <div className='form-error'>{errors.id}</div>}
            </div>

            <button type='submit' className='cancel-order-button'  disabled={isLoading}>
              Cancel Order
              {isLoading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
            </button>
          </Form>
         )}
      </Formik>
    </div>
  );
}
