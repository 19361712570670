import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import './ScheduleViewToggle.scss';

export interface ScheduleViewToggleProps {
  selected: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

export function ScheduleViewToggle(props: ScheduleViewToggleProps) {
  const { selected, onChange } = props;

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    onChange(newValue);
  };

  return (
    <div className='schedule-view-toggle-component'>
      <ToggleButtonGroup
        value={selected}
        exclusive
        onChange={handleAlignment}
        size='small'
      >
        <ToggleButton value='schedule'>
          <div>Schedule</div>
        </ToggleButton>
        <ToggleButton value='map'>
          <div>Map</div>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
