import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult } from '../../lib/redux';
import { ApproveOrderParams, OrderFilters, RejectOrderParams, TriplePPagination, triplePSDatasource } from '../../datasource';
import { Order, OrdersDashboard } from '../../models';
import { logout } from '../Auth/authSlice';

export interface ReceivedOrdersState {
  getRecivedOrdersAPI: APIResult<Order[]>;
  rejectOrderAPI: APIResult<Order>;
  approveOrderAPI: APIResult<Order>;
  getOrdersDashboardAPI: APIResult<OrdersDashboard>;

  receivedOrders: Order[];
  ordersDashboard?: OrdersDashboard;
}

const initialState: ReceivedOrdersState = {
  getRecivedOrdersAPI: emptyAPIResult(),
  rejectOrderAPI: emptyAPIResult(),
  approveOrderAPI: emptyAPIResult(),
  getOrdersDashboardAPI: emptyAPIResult(),

  receivedOrders: []
}

export const getReceivedOrders = createAsyncThunk(
  'receivedOrders/getReceivedOrders',
  async (data: { pagination: TriplePPagination, filters: OrderFilters}) => {
    return triplePSDatasource.getOrders(data.filters, data.pagination);
  }
);

export const rejectOrder = createAsyncThunk(
  'receivedOrders/rejectOrder',
  async (params: RejectOrderParams) => {
    return triplePSDatasource.rejectOrder(params);
  }
);

export const approveOrder = createAsyncThunk(
  'receivedOrders/approveOrder',
  async (params: ApproveOrderParams) => {
    return triplePSDatasource.approveOrder(params);
  }
);

export const getOrdersDashboard = createAsyncThunk(
  'receivedOrders/getOrdersDashboard',
  async () => {
    return triplePSDatasource.getOrdersDashboard();
  }
);

export const receivedOrdersSlice = createSlice({
  name: 'receivedOrders',
  initialState,
  reducers: {
    resetRejectOrder(state) { state.rejectOrderAPI = initialState.rejectOrderAPI },
    resetApproveOrder(state) { state.approveOrderAPI = initialState.approveOrderAPI },
  },
  extraReducers: builder => {
    builder
      .addCase(getReceivedOrders.pending, state => {
        state.getRecivedOrdersAPI = loadingAPIResult();
      })
      .addCase(getReceivedOrders.fulfilled, (state, action) => {
        state.getRecivedOrdersAPI = successAPIResult(action.payload);

        if (action.meta.arg.pagination.offset === 0) {
          state.receivedOrders = action.payload;
        } else {
          state.receivedOrders.push(...action.payload);
        }
      })
      .addCase(getReceivedOrders.rejected, (state, action) => {
        state.getRecivedOrdersAPI = errorAPIResult(action.error);
      })
      .addCase(rejectOrder.pending, state => {
        state.rejectOrderAPI = loadingAPIResult();
      })
      .addCase(rejectOrder.fulfilled, (state, action) => {
        state.rejectOrderAPI = successAPIResult(action.payload);
        state.receivedOrders = state.receivedOrders.filter(o => o.id !== action.payload.id);
      })
      .addCase(rejectOrder.rejected, (state, action) => {
        state.rejectOrderAPI = errorAPIResult(action.error);
      })
      .addCase(approveOrder.pending, state => {
        state.approveOrderAPI = loadingAPIResult();
      })
      .addCase(approveOrder.fulfilled, (state, action) => {
        state.approveOrderAPI = successAPIResult(action.payload);
        state.receivedOrders = state.receivedOrders.filter(o => o.id !== action.payload.id);
      })
      .addCase(approveOrder.rejected, (state, action) => {
        state.approveOrderAPI = errorAPIResult(action.error);
      })
      .addCase(getOrdersDashboard.pending, state => {
        state.getOrdersDashboardAPI = loadingAPIResult();
      })
      .addCase(getOrdersDashboard.fulfilled, (state, action) => {
        state.getOrdersDashboardAPI = successAPIResult(action.payload);
        state.ordersDashboard = action.payload;
      })
      .addCase(getOrdersDashboard.rejected, (state, action) => {
        state.getOrdersDashboardAPI = errorAPIResult(action.error);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getRecivedOrdersAPI = initialState.getRecivedOrdersAPI;
          state.approveOrderAPI = initialState.approveOrderAPI;
          state.rejectOrderAPI = initialState.rejectOrderAPI;

          state.receivedOrders = [];
        } 
      )
  }
});

export const { resetRejectOrder, resetApproveOrder } = receivedOrdersSlice.actions;
export default receivedOrdersSlice.reducer;
