import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { ChangeDeliveryPriorityParams, triplePSDatasource } from '../../datasource';
import { Delivery, DeliveryStatus, ScheduleItemType } from '../../models';

import { createScheduleItem } from '../Schedules/scheduleSlice';
import { logout } from '../Auth';

export interface DeliveriesState {
  getDeliveries: APIResult<Delivery[]>;
  changeDeliveryPriorityAPI: APIResult<Delivery>;

  deliveries: Delivery[];
}

const initialState: DeliveriesState = {
  getDeliveries: emptyAPIResult(),
  changeDeliveryPriorityAPI: emptyAPIResult(),

  deliveries: []
}

export const getDeliveries = createAsyncThunk(
  'deliveries/getDeliveries',
  async (date: string) => {
    return triplePSDatasource.getDeliveries(date);
  }
);

export const changeDeliveryPriority = createAsyncThunk<Delivery, ChangeDeliveryPriorityParams,{ rejectValue: ApiError }>(
  'deliveries/changeDeliveryPriority',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.changeDeliveryPriority(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const deliveriesSlice = createSlice({
  name: 'deliveries',
  initialState,
  reducers: {
    deliveryUnscheduled(state, payload) {
      const delivery = state.deliveries.find(d => d.id === payload.payload);
      if (!!delivery) {
        delivery.status = DeliveryStatus.CREATED;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDeliveries.pending, state => {
        state.getDeliveries = loadingAPIResult();
      })
      .addCase(getDeliveries.fulfilled, (state, action) => {
        state.getDeliveries = successAPIResult(action.payload);
        state.deliveries = action.payload;
      })
      .addCase(getDeliveries.rejected, (state, action) => {
        state.getDeliveries = errorAPIResult(action.error);
      })
      .addCase(changeDeliveryPriority.pending, state => {
        state.changeDeliveryPriorityAPI = loadingAPIResult();
      })
      .addCase(changeDeliveryPriority.fulfilled, (state, action) => {
        state.changeDeliveryPriorityAPI = successAPIResult(action.payload);
        state.deliveries = state.deliveries.map(d => d.id === action.payload.id ? action.payload : d);
      })
      .addCase(changeDeliveryPriority.rejected, (state, action) => {
        state.changeDeliveryPriorityAPI = errorAPIResult(action.error);
      })
      .addMatcher(
        isAnyOf(
          createScheduleItem.fulfilled
        ),
        (state, action) => {
          if (action.payload.type === ScheduleItemType.DELIVERY) {
            const delivery = state.deliveries.find(d => d.id === action.payload.deliveryId);

            if (!!delivery) {
              delivery.status = DeliveryStatus.SCHEDULED;
            }
          }
        } 
      )
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getDeliveries = initialState.getDeliveries;
          state.deliveries = initialState.deliveries;
        }
      )
  }
});

export const { deliveryUnscheduled } = deliveriesSlice.actions;
export default deliveriesSlice.reducer;
