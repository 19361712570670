import React from 'react';
import { BsChevronDown, BsChevronUp, BsX } from 'react-icons/bs';
import { useDrag } from 'react-dnd';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { ScheduleItem, ScheduleItemType } from '../../../models';
import { changeScheduleItemOrder, unassignScheduleItem } from '../../Schedules/scheduleSlice';
import { dumpYardUnscheduled, setShowUpdateDumpYardInstructionsModal } from '../dumpYardsSlice';

import './ScheduledDumpYardItem.scss';

export interface ScheduledDumpYardItemProps {
  scheduleItem: ScheduleItem;

  allowMoveUp: boolean;
  allowMoveDown: boolean;
}

export function ScheduledDumpYardItem(props: ScheduledDumpYardItemProps) {
  const dispatch = useAppDispatch();

  const { scheduleItem, allowMoveDown, allowMoveUp } = props;

  const [isUnassigning, setIsUnassigning] = React.useState(false);

  const dumpYard = useAppSelector(state => state.dumpYards.dumpYards.find(d => d.id === scheduleItem.dumpYardId));
  const template = useAppSelector(state => state.dumpYards.templates.find(t => t.id === dumpYard?.templateId));
  const isLoading = useAppSelector(state => state.schedules.unassignScheduleItem.loading);

  React.useEffect(() => {
    return () => {
      if (isUnassigning) {
        dispatch(dumpYardUnscheduled(scheduleItem.dumpYardId));
      }
    };
  }, [isUnassigning]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ScheduleItemType.DELIVERY,
    item: { id: scheduleItem.id, type: ScheduleItemType.DELIVERY, scheduleId: scheduleItem.scheduleId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  if (!dumpYard) {
    return null;
  }

  const onUnassignScheduleItem = () => {
    if (!isLoading) {
      setIsUnassigning(true);
      dispatch(unassignScheduleItem(scheduleItem.id));
    }
  }

  const onShiftUp = () => {
    if (!isLoading) {
      if (allowMoveUp) {
        const nextOrder = scheduleItem.orderNumber - 1;

        dispatch(changeScheduleItemOrder({ id: scheduleItem.id, newOrderNumber: nextOrder }))
      }
    }
  }

  const onShiftDown = () => {
    if (!isLoading) {
      if (allowMoveDown) {
        const nextOrder = scheduleItem.orderNumber + 1;
        
        dispatch(changeScheduleItemOrder({ id: scheduleItem.id, newOrderNumber: nextOrder }))
      }
    }
  }

  const onDoubleClick = () => {
    dispatch(setShowUpdateDumpYardInstructionsModal(dumpYard));
  }

  const address = `${dumpYard.address}, ${dumpYard.zip}`;

  return (
    <div className='scheduled-dump-yard-item-component'>
      <div className='order-container'>
        {allowMoveUp && <BsChevronUp className='shift-button' onClick={onShiftUp}/>}
        <div className='order-text'>{scheduleItem.orderNumber}</div>
        {allowMoveDown && <BsChevronDown className='shift-button' onClick={onShiftDown}/>}
      </div>

      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
        }}
        className={`dump-yard-content-container ${template?.isDumpYard ? 'dump-yard' : ''} ${template?.isStorage ? 'storage' : ''} ${template?.isQuickStop ? 'quick-stop' : ''}`}
        onDoubleClick={onDoubleClick}
      >
        <div className='title-container'>
          <div className='name-container'>
            <div className='name-text'>{dumpYard.name}</div>
          </div>

          <BsX className='x-icon' onClick={onUnassignScheduleItem}/>
        </div>

        <div>
          <div className='address-text'>{address}</div>
        </div>

        {dumpYard.instructions.length > 0 && <div className='instructions-text'>{dumpYard.instructions}</div>}
      </div>
    </div>
  );
}
