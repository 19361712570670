import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { OrderHistoryOverviewCard, BackButton, OrderHistoryCard } from '../../components';
import { getInvoices } from '../../clientDashboardSlice';

import './ClientOrderHistory.scss';

export function ClientOrderHistory() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const orderId = params.id;
  const order = useAppSelector(state => state.clientDashboard.activeOrders.find(o => o.id === orderId));

  React.useEffect(() => {
    if (!orderId) return;

    dispatch(getInvoices({
      orderId
    }));
  }, []);

  const onBackButtonClick = () => {
    navigate(-1);
  }

  return (
    <div className='client-order-history-component'>
      <div className='back-button-container'>
        <BackButton onClick={onBackButtonClick}/>
      </div>

      <OrderHistoryOverviewCard />

      <OrderHistoryCard />
    </div>
  );
}
