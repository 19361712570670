import React from 'react';
import { useTable, Column, useSortBy } from 'react-table';
import { BsPlusCircle, BsFilter, BsSearch, BsSortDown, BsSortUp, BsTrash, BsArrowUpRightSquare } from 'react-icons/bs';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { User, addressToHuman } from '../../../models';
import { toggleShowAddDriverModal, showDeleteDriverConfirmationModal } from '../driversSlice';

import './Drivers.scss';

type ColumnsType = Pick<User, 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'id' | 'address'> ;

export function Drivers() {
  const dispatch = useAppDispatch();

  const drivers = useAppSelector(state => state.drivers.drivers) || [];
  const removeDriverAPI = useAppSelector(state => state.drivers.removeDriver);
  const driverToRemove = useAppSelector(state => state.drivers.driverToRemove);

  const [search, setSearch] = React.useState<string>('');

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value);
  }

  const onAddDriver = () => {
    dispatch(toggleShowAddDriverModal());
  }

  const data = React.useMemo(() => {
    if (search === '') return drivers;

    return drivers.filter(d => d.email.toLowerCase().includes(search) ||
      d.firstName.toLowerCase().includes(search) ||
      d.lastName.toLowerCase().includes(search) ||
      d.phoneNumber.toLowerCase().includes(search)
    );
  }, [drivers, search]);

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber'
      },
      {
        Header: 'Start/End Address',
        accessor: 'address',
        Cell: props => <div>{addressToHuman(props.row.original.address)}</div>
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: props => {
          const { value, row } = props;
          
          const onRemoveDriver = () => {
            dispatch(showDeleteDriverConfirmationModal(row.original));
          };

          return (
            <div className='actions-cell'>
              {removeDriverAPI.loading && driverToRemove === value ? <Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/> : <BsTrash className='delete-icon' onClick={onRemoveDriver}/>}
              <Link to={`/drivers/${value}`} className='schedule-icon'><BsArrowUpRightSquare className='schedule-icon'/></Link>
            </div>
          );
        }
      }
    ],
    [driverToRemove]
  )
  
  React.useEffect(() => {
    if (!removeDriverAPI.loading ) {
      if (!!removeDriverAPI.value) {
        toast.success('Successfully removed driver.');
      }

      if (!!removeDriverAPI.error) {
        toast.error('Something went wrong. Please try again.');
      }
    }
  }, [removeDriverAPI.loading, removeDriverAPI.value, removeDriverAPI.error]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  return (
    <div className='drivers-component'>
      <div className='controllers-container'>
        <div className='left-controllers-container'>
          <div className='title-container'>
            <div className='title-text'>Drivers</div>
            <div className='title-count'>{data.length}</div>
          </div>

          <div className='search-container'>
            <BsSearch className='search-icon'/>
            <input
              value={search}
              placeholder='Search'
              onChange={onInputChange}
            />
          </div>
        </div>

        <div className='add-button' onClick={onAddDriver}>
          <BsPlusCircle className='icon'/>  
          Add Driver
        </div>
      </div>

      <div className='table-container'>
        <table {...getTableProps()} className='tp-table'>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className='header-cell'>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <BsSortDown className='sorting-icon'/>
                          : <BsSortUp className='sorting-icon'/>
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
