import { DumpYardTemplate } from '../../../models';

export const getDumpYardTemplate = (data: any): DumpYardTemplate => {
  return {
    id: data.id,
    address: data.attributes.address,
    zip: data.attributes.zip,
    name: data.attributes.name || '',
    latitude: data.attributes.latitude,
    longitude: data.attributes.longitude,
    isDumpYard: data.attributes.is_dump_yard,
    isStorage: data.attributes.is_storage,
    isQuickStop: data.attributes.is_quick_stop
  };
}

export const getDumpYardTemplates = (data: any): DumpYardTemplate[] => {
  return data.map(getDumpYardTemplate);
}
