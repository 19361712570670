import { DumpYardTemplate } from '../../../models';
import { DumpYardTemplateForm } from '../TriplePInterface';

export interface DumpYardTemplatePayload {
  data: {
    type: string;
    attributes: {
      address: string;
      zip: string;
      name?: string;
      is_dump_yard?: boolean;
      is_storage?: boolean;
      is_quick_stop?: boolean;
    }
  }
}

export const toDumpYardTemplate = (form: DumpYardTemplateForm): DumpYardTemplatePayload => {
  return {
    data: {
      type: 'dump_yard_templates',
      attributes: {
        address: form.address,
        zip: form.zip,
        name: form.name,
        is_dump_yard: form.isDumpYard,
        is_storage: form.isStorage,
        is_quick_stop: form.isQuickStop,
      }
    }
  };
}

export interface UpdateDumpYardTemplatePayload {
  data: {
    id: string;
    type: string;
    attributes: {
      address: string;
      zip: string;
      name?: string;
      latitude: number;
      longitute: number;
      is_dump_yard?: boolean;
      is_storage?: boolean;
      is_quick_stop?: boolean;
    }
  }
}

export const toUpdateDumpYardTemplate = (template: DumpYardTemplate): UpdateDumpYardTemplatePayload => {
  return {
    data: {
      id: template.id,
      type: 'dump_yard_templates',
      attributes: {
        address: template.address,
        zip: template.zip,
        name: template.name,
        latitude: template.latitude,
        longitute: template.longitude,
        is_dump_yard: template.isDumpYard,
        is_storage: template.isStorage,
        is_quick_stop: template.isQuickStop,
      }
    }
  };
}
