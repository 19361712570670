import React from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { isAdminSelector } from '../Auth/authSlice';
import { store } from '../../app/store';
import { NavigationBar } from '../NavigationBar'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getProfile, setJwtToken } from '../Auth/authSlice';
import { LOCAL_STORAGE_KEYS } from '../../lib/localStorage';
import { AddDriverModal, DeleteDriverConfirmationModal } from '../Drivers';
import { CreateScheduleModal } from '../Schedules';
import { AddDumpYardModal, EditDumpYardModal, UpdateInstructionsModal } from '../DumpYards';
import { UpdateOrderModal, DeleteOrderModal, PickupInstructionsModal, OrderDetailsModal } from '../Orders';
import { AddPermanentRentalModal } from '../PermanentRentals/AddPermanentRentalModal/AddPermanentRentalModal';
import { DeleteDumpYardConfirmationModal } from '../DumpYards/DeleteDumpYardConfirmationModal/DeleteDumpYardConfirmationModal';
import { DeletePermanentRentalConfirmationModal } from '../PermanentRentals/DeletePermanentRentalConfirmationModal/DeletePermanentRentalConfirmationModal';
import { EditPunchCardModal } from '../PunchCards/modals';

import './Layout.scss';

export function Layout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const jwtToken = useAppSelector(state => state.auth.jwtToken);
  const getProfileLoading = useAppSelector(state => state.auth.getProfile.loading);
  const didGetProfileFail = useAppSelector(state => !!state.auth.getProfile.error);
  const isAdmin = isAdminSelector(store.getState());

  React.useEffect(() => {
    const jwtToken = localStorage.getItem(LOCAL_STORAGE_KEYS.JWT_TOKEN);
    if (!!jwtToken) {
      dispatch(setJwtToken({ jwtToken }));
    }
  }, []);

  React.useEffect(() => {
    if (!!jwtToken) {
      dispatch(getProfile());
      navigate('/dashboard', { replace: true });
    }
  }, [jwtToken]);

  React.useEffect(() => {
    if (!getProfileLoading && didGetProfileFail) {
      dispatch(setJwtToken({ jwtToken: undefined }));
      navigate('/auth/login', { replace: true });
    }
  }, [getProfileLoading, didGetProfileFail]);

  const isDevelopment = React.useMemo(() => !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development', [process.env.REACT_APP_ENVIRONMENT]);

  return (
    <>
      {isDevelopment && <div className='not-production-banner'>This is not production!</div>}
      <NavigationBar />

      <AddDriverModal />
      <CreateScheduleModal />
      <AddDumpYardModal />
      <EditDumpYardModal />
      <UpdateInstructionsModal />
      <AddPermanentRentalModal />
      <UpdateOrderModal />
      {isAdmin && <DeleteOrderModal />}
      <OrderDetailsModal />
      <DeleteDumpYardConfirmationModal />
      <DeletePermanentRentalConfirmationModal />
      <DeleteDriverConfirmationModal />
      <PickupInstructionsModal />
      {isAdmin && <EditPunchCardModal />}

      <Outlet />
    </>
  );
}
