import { Schedule, ScheduleItem } from '../../../models';
import { getScheduleItem } from './getScheduleItem';

export const getSchedule = (data: any, included: any): Schedule => {
  const items: ScheduleItem[] = (included || []).map(getScheduleItem);
  
  let scheduleItems: ScheduleItem[] = [];
  if (!!data.relationships && !!data.relationships["schedule_items"]) {
    scheduleItems = data.relationships["schedule_items"].data.map((si: { type: string; id: string; }) => {
      return items.find(s => s.id === si.id);
    });
  }

  return {
    id: data.id,
    driverId: data.attributes.driver_id,
    date: data.attributes.date,
    isOptimizing: data.attributes.is_optimizing,
    optimizedTime: data.attributes.optimized_time,
    optimizedDistance: data.attributes.optimized_distance,
    scheduleItems
  };
}

export const getSchedules = (response: any): Schedule[] => {
  const schedules = response.data.map((s: any) => {
    const schedule = getSchedule(s, response.included);
    return schedule;
  });

  return schedules;
}
