import React from 'react';

import { ReactComponent as LeftIcon } from '../../../../assets/images/left_icon.svg';
import { ReactComponent as RightIcon } from '../../../../assets/images/right_icon.svg';
import './Pagination.scss';

export interface PaginationProps {
  current: number;

  onNextPage: () => void;
  onPreviousPage: () => void;
}

export function Pagination(props: PaginationProps) {
  const { current, onNextPage, onPreviousPage } = props;

  const onPreviousPress = () => {
    if (current - 1 >= 0) {
      onPreviousPage();
    }
  }

  return (
    <div className='pagination-component'>
      <div className='copy'>{`Page ${current + 1}`}</div>

      <div className='controls-container'>
        <div className='icon-container' onClick={onPreviousPress}>
          <LeftIcon />
        </div>
        <div className='icon-container' onClick={onNextPage}>
          <RightIcon />
        </div>
      </div>
    </div>
  );
}
