import { PermanentRentalTemplate } from '../../../models/PermanentRentalTemplate';

export const getPermanentRentalTemplate = (data: any): PermanentRentalTemplate => {
  return {
    id: data.id,
    name: data.attributes.name,
    email: data.attributes.email,
    phoneNumber: data.attributes.phone_number,
    address: data.attributes.address,
    zip: data.attributes.zip,
    dumpsterSize: data.attributes.dumpster_size
  };
}

export const getPermanentRentalTemplates = (data: any): PermanentRentalTemplate[] => {
  return data.map(getPermanentRentalTemplate);
}
