import React from "react";
import Modal from 'react-modal';

import './TermsAndConditionsModal.scss';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import SignatureCanvas from 'react-signature-canvas';
import { updateSignature } from "../../Orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { BsX } from "react-icons/bs";

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF",
    height: "calc(100% - 50px)"
  },
};

export interface TermsAndConditionsModalProps {
  isOpen: boolean;

  onClose: () => void;
  onAccept: () => void;
}

export function TermsAndConditionsModal(props: TermsAndConditionsModalProps) {
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onAccept } = props;

  const signatureCanvas = React.createRef<SignatureCanvas>();

  const [signature, setSignature] = React.useState<string | undefined>();

  const closeModal = () => {
    setSignature(undefined);
    onClose();
  }

  const acceptTerms = () => {
    dispatch(updateSignature(signature));
    setSignature(undefined);
    onAccept();
  }

  const onStrokeEnd = () => {
    const base64URL = signatureCanvas.current!.getTrimmedCanvas().toDataURL();
    const base64Str = base64URL.replace('data:image/png;base64,', '')
    setSignature(base64Str);
  }

  const clearCanvas = () => {
    signatureCanvas.current?.clear();
    setSignature(undefined);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <div className='terms-and-conditions-modal-component'>
        <Close className='close-button' onClick={closeModal}/>
        <div className='title'>Terms and Conditions</div>
        <div className='text-content'>
          <div className='paragraph'>
            <p className='paragraph-text'>Load is to be level - not over top or sides of dumpster. We can not safely haul dumpsters over full. Any materials deemed to be in excess of capacity may be removed and left on property.</p>
          </div>
          <div className='paragraph'>
            <p className='paragraph-title'>Not permitted in dumpster</p>
            <p className='paragraph-text'> • No clean fill materials - unless previously discussed (bricks, block, stone, dirt, fire pit debris, etc)</p>
            <p className='paragraph-text'> • No hazardous materials - unless previously discussed (liquid chemicals, TVs, Tires)</p>
          </div>
          <div className='paragraph'>
            <p className='paragraph-title'>Rental time is 7 days</p>
            <p className='paragraph-text'>  • We do not call for pickup</p>
            <p className='paragraph-text'>  • If no one is present please make sure the dumpster is accessible</p>
            <p className='paragraph-text'>  • If the dumpster is unable to be picked up a $75 to $150 service fee will be charged</p>
            <p className='paragraph-text'>  • Fee depends on location of delivery</p>
            <p className='paragraph-text'>  • If extra days are requested, cost is $10 per day</p>
            <p className='paragraph-text'>  • This must be communicated no later than 4 days into rental time</p>
          </div>
          <div className='paragraph'>
            <p className='paragraph-text'>Please check with your  Township or Borough if a permit is needed. This is your responsibility</p>
            <p className='paragraph-text'>Please note: There is a $50 service fee for all returned checks</p>
            <p className='paragraph-text'>Payment is due on delivery of dumpster. Any additional charges for additional tonnage, will be billed and payment is due on receipt</p>
          </div>
          <div className='paragraph'>
            <p className='paragraph-text'>TRIPLE P DISPOSAL is not responsible for damage to driveways, yards, or any properties when dropping a dumpster. When requested to leave on a public street, you are responsible for obtaining the proper permit. TRIPLE P DISPOSAL has permission to enter property to get a dumpster at any time. TRIPLE P DISPOSAL will determine if a spot to place a dumpster is safe or not.</p>
          </div>

          <div className='signature-container'>
            <div className='paragraph-title'>Signature</div>
            <div className='canvas-container'>
              <Close className='clear-canvas-button' onClick={clearCanvas} />
              <SignatureCanvas 
                penColor='black' 
                canvasProps={{width: 450, height: 200, className: 'signature-canvas'}} 
                ref={signatureCanvas}
                onEnd={onStrokeEnd}
              />
            </div>
          </div>

          <div className='accept-btn-container'>
            <button 
              className={`accept-btn ${signature ? '' : 'disabled'}`} 
              disabled={!signature} 
              onClick={acceptTerms}>
                I ACCEPT TERMS
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
