import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { GetDumpYardsParams, ScheduleForm, ScheduleItemForm, triplePSDatasource } from '../../datasource';
import { DashboardScheduleStats, DumpYard, Schedule, ScheduleItem } from '../../models';
import { logout } from '../Auth';

export interface SchedulesState {
  getSchedules: APIResult<Schedule[]>;
  getScheduleAPI: APIResult<Schedule>;
  getScheduleItems: APIResult<ScheduleItem[]>;
  createSchedule: APIResult<Schedule>;
  deleteSchedule: APIResult<string>;
  getScheduleStatsAPI: APIResult<DashboardScheduleStats>;
  getUncompletedDumpYardsCountAPI: APIResult<DumpYard[]>;
  optimizeScheduleAPI: APIResult<Schedule>;
  calculateStatsAPI: APIResult<Schedule>;

  createScheduleItem: APIResult<ScheduleItem>;
  unassignScheduleItem: APIResult<string>;
  changeScheduleScheduleItem: APIResult<ScheduleItem>;
  changeScheduleItemOrder: APIResult<ScheduleItem>;
  
  schedules: Schedule[];
  dateMoment: string;
  showCreateScheduleModal: boolean;
  dashboardScheduleStats?: DashboardScheduleStats;
  uncompletedDumpYards: number;
}

const initialState: SchedulesState = {
  getSchedules: emptyAPIResult(),
  getScheduleAPI: emptyAPIResult(),
  getScheduleItems: emptyAPIResult(),
  createSchedule: emptyAPIResult(),
  deleteSchedule: emptyAPIResult(),
  getScheduleStatsAPI: emptyAPIResult(),
  getUncompletedDumpYardsCountAPI: emptyAPIResult(),
  optimizeScheduleAPI: emptyAPIResult(),
  calculateStatsAPI: emptyAPIResult(),

  createScheduleItem: emptyAPIResult(),
  unassignScheduleItem: emptyAPIResult(),
  changeScheduleScheduleItem: emptyAPIResult(),
  changeScheduleItemOrder: emptyAPIResult(),
  
  schedules: [],
  dateMoment: moment().add(1, 'day').format('YYYY-MM-DD'),
  showCreateScheduleModal: false,
  uncompletedDumpYards: 0
}

export const getSchedules = createAsyncThunk(
  'schedules/getSchedules',
  async (date: string) => {
    return triplePSDatasource.getSchedules(date);
  }
);

export const getScheduleItems = createAsyncThunk(
  'schedules/getScheduleItems',
  async (id: string) => {
    return triplePSDatasource.getScheduleItems(id);
  }
);

export const createSchedule = createAsyncThunk<Schedule, ScheduleForm,{ rejectValue: ApiError }>(
  'schedules/createSchedule',
  async (form, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.createSchedule(form);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const deleteSchedule = createAsyncThunk(
  'schedules/deleteSchedule',
  async (id: string) => {
    return triplePSDatasource.deleteSchedule(id);
  }
);

export const createScheduleItem = createAsyncThunk(
  'schedules/createScheduleItem',
  async (form: ScheduleItemForm) => {
    return triplePSDatasource.createScheduleItem(form);
  }
);

export const unassignScheduleItem = createAsyncThunk(
  'schedules/unassignScheduleItem',
  async (id: string) => {
    return triplePSDatasource.unassign(id);
  }
);

export const changeScheduleScheduleItem = createAsyncThunk(
  'schedules/changeScheduleScheduleItem',
  async (data: { id: string, newScheduleId: string }) => {
    return triplePSDatasource.changeSchedule(data.id, data.newScheduleId);
  }
);

export const changeScheduleItemOrder = createAsyncThunk(
  'schedules/changeScheduleItemOrder',
  async (data: { id: string, newOrderNumber: number }) => {
    return triplePSDatasource.updateOrderScheduleItem(data.id, data.newOrderNumber);
  }
);

export const getDashboardScheduleStats = createAsyncThunk(
  'schedules/getDashboardScheduleStats',
  async () => {
    return triplePSDatasource.getScheduleStats();
  }
);

export const getUncompletedDumpYardsCount = createAsyncThunk<DumpYard[], GetDumpYardsParams,{ rejectValue: ApiError }>(
  'schedules/getUncompletedDumpYardsCount',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.getDumpYards(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const optimizeSchedule = createAsyncThunk<Schedule, string,{ rejectValue: ApiError }>(
  'schedules/optimizeSchedule',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.optimizeSchedule(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const calculateStats = createAsyncThunk<Schedule, string,{ rejectValue: ApiError }>(
  'schedules/calculateStats',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.calculateStats(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const getSchedule = createAsyncThunk<Schedule, string,{ rejectValue: ApiError }>(
  'schedules/getSchedule',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.getSchedule(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    toggleShowCreateScheduleModal(state) { state.showCreateScheduleModal = !state.showCreateScheduleModal },
    setDateMoment(state, payload) { state.dateMoment = payload.payload },
  },
  extraReducers: builder => {
    builder
      .addCase(getSchedules.pending, state => {
        state.getSchedules = loadingAPIResult();
      })
      .addCase(getSchedules.fulfilled, (state, action) => {
        state.getSchedules = successAPIResult(action.payload);
        state.schedules = action.payload;
      })
      .addCase(getSchedules.rejected, (state, action) => {
        state.getSchedules = errorAPIResult(action.error);
      })
      .addCase(getScheduleItems.pending, state => {
        state.getScheduleItems = loadingAPIResult();
      })
      .addCase(getScheduleItems.fulfilled, (state, action) => {
        state.getScheduleItems = successAPIResult(action.payload);
        
        const schedule = state.schedules.find(s => action.payload.length > 0 && s.id === action.payload[0].scheduleId);
        if (!!schedule) {
          schedule.scheduleItems = action.payload;
        }
      })
      .addCase(getScheduleItems.rejected, (state, action) => {
        state.getScheduleItems = errorAPIResult(action.error);
      })
      .addCase(createSchedule.pending, state => {
        state.createSchedule = loadingAPIResult();
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        state.createSchedule = successAPIResult(action.payload);
        state.schedules?.push(action.payload);
      })
      .addCase(createSchedule.rejected, (state, action) => {
        state.createSchedule = errorAPIResult(action.payload!);
      })
      .addCase(deleteSchedule.pending, state => {
        state.deleteSchedule = loadingAPIResult();
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        state.deleteSchedule = successAPIResult(action.payload);
        const newSchedules = state.schedules.filter(s => s.id !== action.payload);
        state.schedules = newSchedules;
      })
      .addCase(deleteSchedule.rejected, (state, action) => {
        state.deleteSchedule = errorAPIResult(action.error);
      })
      .addCase(createScheduleItem.pending, state => {
        state.createScheduleItem = loadingAPIResult();
      })
      .addCase(createScheduleItem.fulfilled, (state, action) => {
        state.createScheduleItem = successAPIResult(action.payload);

        const schedule = state.schedules.find(s => s.id === action.payload.scheduleId);
        if (!!schedule) {
          schedule.scheduleItems.push(action.payload);
        }
      })
      .addCase(createScheduleItem.rejected, (state, action) => {
        state.createScheduleItem = errorAPIResult(action.error);
      })
      .addCase(unassignScheduleItem.pending, state => {
        state.unassignScheduleItem = loadingAPIResult();
      })
      .addCase(unassignScheduleItem.fulfilled, (state, action) => {
        state.unassignScheduleItem = successAPIResult(action.payload);

        const schedule = state.schedules.find(s => s.scheduleItems.find(si => si.id === action.payload));
        if (!!schedule) {
          const scheduleItemOrderNumber = schedule.scheduleItems.find(s => s.id === action.payload);
          const newScheduleItems = schedule.scheduleItems.filter(s => s.id !== action.payload);
          if (scheduleItemOrderNumber) {
            newScheduleItems.forEach(nsi => {
              if (nsi.orderNumber > scheduleItemOrderNumber.orderNumber) {
                nsi.orderNumber = nsi.orderNumber - 1;
              }
            })
          }
          schedule.scheduleItems = newScheduleItems;
        }
      })
      .addCase(unassignScheduleItem.rejected, (state, action) => {
        state.unassignScheduleItem = errorAPIResult(action.error);
      })
      .addCase(changeScheduleScheduleItem.pending, state => {
        state.changeScheduleScheduleItem = loadingAPIResult();
      })
      .addCase(changeScheduleScheduleItem.fulfilled, (state, action) => {
        state.changeScheduleScheduleItem = successAPIResult(action.payload);

        state.schedules.forEach(s => {
          const newScheduleItems = s.scheduleItems.filter(s => s.deliveryId !== action.payload.deliveryId || s.pickupId !== action.payload.pickupId || s.dumpYardId !== action.payload.dumpYardId);
          s.scheduleItems = newScheduleItems;
        });

        const schedule = state.schedules.find(s => s.id === action.payload.scheduleId);
        if (!!schedule) {
          schedule.scheduleItems.push(action.payload);
        }
      })
      .addCase(changeScheduleScheduleItem.rejected, (state, action) => {
        state.changeScheduleScheduleItem = errorAPIResult(action.error);
      })
      .addCase(changeScheduleItemOrder.pending, state => {
        state.changeScheduleItemOrder = loadingAPIResult();
      })
      .addCase(changeScheduleItemOrder.fulfilled, (state, action) => {
        state.changeScheduleItemOrder = successAPIResult(action.payload);
      })
      .addCase(changeScheduleItemOrder.rejected, (state, action) => {
        state.changeScheduleItemOrder = errorAPIResult(action.error);
      })
      .addCase(getDashboardScheduleStats.pending, state => {
        state.getScheduleStatsAPI = loadingAPIResult();
      })
      .addCase(getDashboardScheduleStats.fulfilled, (state, action) => {
        state.getScheduleStatsAPI = successAPIResult(action.payload);
        state.dashboardScheduleStats = action.payload;
      })
      .addCase(getDashboardScheduleStats.rejected, (state, action) => {
        state.getScheduleStatsAPI = errorAPIResult(action.error);
      })
      .addCase(getUncompletedDumpYardsCount.pending, state => {
        state.getUncompletedDumpYardsCountAPI = loadingAPIResult();
      })
      .addCase(getUncompletedDumpYardsCount.fulfilled, (state, action) => {
        state.getUncompletedDumpYardsCountAPI = successAPIResult(action.payload);
        state.uncompletedDumpYards = action.payload.length;
      })
      .addCase(getUncompletedDumpYardsCount.rejected, (state, action) => {
        state.getUncompletedDumpYardsCountAPI = errorAPIResult(action.error);
      })
      .addCase(optimizeSchedule.pending, state => {
        state.optimizeScheduleAPI = loadingAPIResult();
      })
      .addCase(optimizeSchedule.fulfilled, (state, action) => {
        state.optimizeScheduleAPI = successAPIResult(action.payload);
      
        const newSchedules = state.schedules.map(s => {
          if (s.id !== action.payload.id) {
            return s;
          }

          s.isOptimizing = action.payload.isOptimizing;
          s.optimizedDistance = 0
          s.optimizedTime = 0

          return s;
        })
        state.schedules = newSchedules;
      })
      .addCase(optimizeSchedule.rejected, (state, action) => {
        state.optimizeScheduleAPI = errorAPIResult(action.error);
      })
      .addCase(calculateStats.pending, state => {
        state.calculateStatsAPI = loadingAPIResult();
      })
      .addCase(calculateStats.fulfilled, (state, action) => {
        state.calculateStatsAPI = successAPIResult(action.payload);
      
        const newSchedules = state.schedules.map(s => {
          if (s.id !== action.payload.id) {
            return s;
          }

          s.isOptimizing = action.payload.isOptimizing;
          s.optimizedDistance = action.payload.optimizedDistance;
          s.optimizedTime = action.payload.optimizedTime;

          return s;
        })
        state.schedules = newSchedules;
      })
      .addCase(calculateStats.rejected, (state, action) => {
        state.calculateStatsAPI = errorAPIResult(action.error);
      })
      .addCase(getSchedule.pending, state => {
        state.getScheduleAPI = loadingAPIResult();
      })
      .addCase(getSchedule.fulfilled, (state, action) => {
        state.getScheduleAPI = successAPIResult(action.payload);
        const newSchedules = state.schedules.map(s => {
          return s.id === action.payload.id ? action.payload : s;
        });
        state.schedules = newSchedules;
      })
      .addCase(getSchedule.rejected, (state, action) => {
        state.getScheduleAPI = errorAPIResult(action.payload!);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getSchedules = initialState.getSchedules;
          state.createSchedule = initialState.createSchedule;
          state.deleteSchedule = initialState.deleteSchedule;
          state.schedules = initialState.schedules;
          state.getUncompletedDumpYardsCountAPI = initialState.getUncompletedDumpYardsCountAPI;

          state.createScheduleItem = initialState.createScheduleItem;
          state.unassignScheduleItem = initialState.unassignScheduleItem;
          state.changeScheduleScheduleItem = initialState.changeScheduleScheduleItem;
          state.changeScheduleItemOrder = initialState.changeScheduleItemOrder;

          state.dateMoment = initialState.dateMoment;
          state.showCreateScheduleModal = initialState.showCreateScheduleModal;
          state.uncompletedDumpYards = initialState.uncompletedDumpYards;
        }
      )
  }
});

export const { toggleShowCreateScheduleModal, setDateMoment } = schedulesSlice.actions;
export default schedulesSlice.reducer;
