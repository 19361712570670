import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { triplePSDatasource } from '../../datasource';
import { Address, DriversSchedule, ScheduleItem, User } from '../../models';
import { logout } from '../Auth';

export interface DriversState {
  getDrivers: APIResult<User[]>;
  getDriverAddressesAPI: APIResult<Address[]>;
  addDriver: APIResult<User>;
  removeDriver: APIResult<string>;
  showDeleteDriverConfirmationModal?: User
  driverToRemove?: string;
  getSchedule: APIResult<DriversSchedule>;
  updateScheduleItemOrder: APIResult<ScheduleItem>;
  getTripTicket: APIResult<string[]>;
  
  drivers: User[];
  showAddDriverModal: boolean;
  scheduleDate: string;
  schedule?: DriversSchedule;
}

const initialState: DriversState = {
  getDrivers: emptyAPIResult(),
  getDriverAddressesAPI: emptyAPIResult(),
  addDriver: emptyAPIResult(),
  removeDriver: emptyAPIResult(),
  getSchedule: emptyAPIResult(),
  updateScheduleItemOrder: emptyAPIResult(),
  getTripTicket: emptyAPIResult(),

  drivers: [],
  showAddDriverModal: false,
  scheduleDate: moment().format('YYYY-MM-DD')
}

export const getDrivers = createAsyncThunk(
  'drivers/getDrivers',
  async () => {
    return triplePSDatasource.getDrivers();
  }
);

export const addDriver = createAsyncThunk(
  'drivers/addDriver',
  async (email: string) => {
    return triplePSDatasource.addDriver(email);
  }
);

export const removeDriver = createAsyncThunk(
  'drivers/removeDriver',
  async (driverId: string) => {
    return triplePSDatasource.removeDriver(driverId);
  }
);

export const getSchedule = createAsyncThunk(
  'drivers/getSchedule',
  async (request: {date: string, driverId: string}) => {
    return triplePSDatasource.getDriversSchedule(request.date, request.driverId);
  }
);

export const updateScheduleItemOrder = createAsyncThunk(
  'drivers/updateScheduleItemOrder',
  async (request: {id: string, newOrder: number}) => {
    return triplePSDatasource.updateOrderScheduleItem(request.id, request.newOrder);
  }
);

export const getTripTicket = createAsyncThunk(
  'drivers/getTripTicket',
  async (params: {id: string, orderIds: string[]}) => {
    return triplePSDatasource.getTripTicket(params.id, params.orderIds);
  }
);

export const getDriverAddresses = createAsyncThunk<Address[], undefined,{ rejectValue: ApiError }>(
  'drivers/getDriverAddresses',
  async (undefined, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.getDriverAddreses();

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    toggleShowAddDriverModal(state) { state.showAddDriverModal = !state.showAddDriverModal },
    setDriverToRemove(state, action) { state.driverToRemove = action.payload },
    setScheduleDate(state, payload) { state.scheduleDate = payload.payload },
    showDeleteDriverConfirmationModal(state, action) { state.showDeleteDriverConfirmationModal = action.payload },
    resetShowDeleteDriverConfirmationModal(state) { state.showDeleteDriverConfirmationModal = undefined },
    resetGetTripTicketl(state) { state.getTripTicket = initialState.getTripTicket }
  },
  extraReducers: builder => {
    builder
      .addCase(getDrivers.pending, state => {
        state.getDrivers = loadingAPIResult();
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.getDrivers = successAPIResult(action.payload);
        state.drivers = action.payload;
      })
      .addCase(getDrivers.rejected, (state, action) => {
        state.getDrivers = errorAPIResult(action.error);
      })
      .addCase(addDriver.pending, state => {
        state.addDriver = loadingAPIResult();
      })
      .addCase(addDriver.fulfilled, (state, action) => {
        state.addDriver = successAPIResult(action.payload);
        state.drivers?.push(action.payload);
      })
      .addCase(addDriver.rejected, (state, action) => {
        state.addDriver = errorAPIResult(action.error);
      })
      .addCase(removeDriver.pending, state => {
        state.removeDriver = loadingAPIResult();
      })
      .addCase(removeDriver.fulfilled, (state, action) => {
        state.removeDriver = successAPIResult(action.payload);
        state.drivers = state.drivers ? state.drivers.filter(d => d.id !== action.payload) : [];
        state.driverToRemove = undefined;
      })
      .addCase(removeDriver.rejected, (state, action) => {
        state.removeDriver = errorAPIResult(action.error);
        state.driverToRemove = undefined;
      })
      .addCase(getSchedule.pending, state => {
        state.getSchedule = loadingAPIResult();
        state.schedule = initialState.schedule;
      })
      .addCase(getSchedule.fulfilled, (state, action) => {
        state.getSchedule = successAPIResult(action.payload);
        state.schedule = action.payload;
        state.schedule?.items.sort((a, b) => parseInt(a.order) - parseInt(b.order));
      })
      .addCase(getSchedule.rejected, (state, action) => {
        state.getSchedule = errorAPIResult(action.error);
      })
      .addCase(updateScheduleItemOrder.pending, state => {
        state.updateScheduleItemOrder = loadingAPIResult();
      })
      .addCase(updateScheduleItemOrder.fulfilled, (state, action) => {
        state.updateScheduleItemOrder = successAPIResult(action.payload);
      })
      .addCase(updateScheduleItemOrder.rejected, (state, action) => {
        state.updateScheduleItemOrder = errorAPIResult(action.error);
      })
      .addCase(getTripTicket.pending, state => {
        state.getTripTicket = loadingAPIResult();
      })
      .addCase(getTripTicket.fulfilled, (state, action) => {
        state.getTripTicket = successAPIResult(action.payload);
      })
      .addCase(getTripTicket.rejected, (state, action) => {
        state.getTripTicket = errorAPIResult(action.error);
      })
      .addCase(getDriverAddresses.pending, state => {
        state.getDriverAddressesAPI = loadingAPIResult();
      })
      .addCase(getDriverAddresses.fulfilled, (state, action) => {
        state.getDriverAddressesAPI = successAPIResult(action.payload);
        
        const newDrivers = state.drivers.map(d => {
          const address = action.payload.find(a => a.userId === d.id);
          d.address = address

          return d;
        });

        state.drivers = newDrivers;
      })
      .addCase(getDriverAddresses.rejected, (state, action) => {
        state.getDriverAddressesAPI = errorAPIResult(action.error);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getDrivers = initialState.getDrivers;
          state.drivers = initialState.drivers;
          state.addDriver = initialState.addDriver;
          state.removeDriver = initialState.removeDriver;
          state.driverToRemove = undefined;
          state.getSchedule = initialState.getSchedule;
          state.updateScheduleItemOrder = initialState.updateScheduleItemOrder;
          state.getTripTicket = initialState.getTripTicket;

          state.showAddDriverModal = initialState.showAddDriverModal;
          state.scheduleDate = initialState.scheduleDate;
          state.schedule = undefined;
          state.showDeleteDriverConfirmationModal = undefined;
        }
      )
  }
});

export const { toggleShowAddDriverModal, setDriverToRemove, setScheduleDate, showDeleteDriverConfirmationModal, resetShowDeleteDriverConfirmationModal, resetGetTripTicketl } = driversSlice.actions;
export default driversSlice.reducer;
