import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { GetInvoicesParams, OrderFilters, TriplePPagination, triplePSDatasource } from '../../datasource';
import { Invoice, Order, OrderStatus } from '../../models';
import { logout } from '../Auth/authSlice';

export interface OrdersState {
  getActiveOrdersAPI: APIResult<Order[]>;
  getPendingOrdersAPI: APIResult<Order[]>;
  getCompletedOrdersAPI: APIResult<Order[]>;
  getInvoicesAPI: APIResult<Invoice[]>;
  getInvoicePDFAPI: APIResult<string>;

  activeOrders: Order[];
  pendingOrders: Order[];
  completedOrders: Order[];
  invoices: Invoice[];
  invoicePDF?: string;
}

const initialState: OrdersState = {
  getActiveOrdersAPI: emptyAPIResult(),
  getPendingOrdersAPI: emptyAPIResult(),
  getCompletedOrdersAPI: emptyAPIResult(),
  getInvoicesAPI: emptyAPIResult(),
  getInvoicePDFAPI: emptyAPIResult(),

  activeOrders: [],
  pendingOrders: [],
  completedOrders: [],
  invoices: [],
}

export const getActiveOrders = createAsyncThunk<Order[], {},{ rejectValue: ApiError }>(
  'clientDashboard/getActiveOrders',
  async ({}, { rejectWithValue }) => {
    try {
      const filters: OrderFilters = {
        statuses: [
          OrderStatus.OrderStatusCreated,
          OrderStatus.OrderStatusScheduled,
          OrderStatus.OrderStatusDelivered,
          OrderStatus.OrderStatusScheduledToPickup,
          OrderStatus.OrderStatusPickedUp
        ]
      };

      const pagination: TriplePPagination = {
        offset: 0,
        count: 50
      }; 

      const data = await triplePSDatasource.getOrders(filters, pagination);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const getPendingOrders = createAsyncThunk<Order[], {},{ rejectValue: ApiError }>(
  'clientDashboard/getPendingOrders',
  async ({}, { rejectWithValue }) => {
    try {
      const filters: OrderFilters = {
        statuses: [
          OrderStatus.OrderStatusReceived,
        ]
      };

      const pagination: TriplePPagination = {
        offset: 0,
        count: 50
      }; 

      const data = await triplePSDatasource.getOrders(filters, pagination);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const getCompletedOrders = createAsyncThunk<Order[], TriplePPagination,{ rejectValue: ApiError }>(
  'clientDashboard/getCompletedOrders',
  async (pagination, { rejectWithValue }) => {
    try {
      const filters: OrderFilters = {
        statuses: [
          OrderStatus.OrderStatusComplete,
        ]
      }; 

      const data = await triplePSDatasource.getOrders(filters, pagination);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const getInvoices = createAsyncThunk<Invoice[], GetInvoicesParams,{ rejectValue: ApiError }>(
  'clientDashboard/getInvoices',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.getInvoices(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const getInvoicePDF = createAsyncThunk<string, any,{ rejectValue: ApiError }>(
  'invoices/getInvoicePDF',
  async (params: { id: string }, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.getInvoicePDF(params.id);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const clientDashboardSlice = createSlice({
  name: 'clientDashboard',
  initialState,
  reducers: {
    resetInvoicePDF(state) {
      state.getInvoicePDFAPI = initialState.getInvoicePDFAPI;
      state.invoicePDF = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getActiveOrders.pending, state => {
        state.getActiveOrdersAPI = loadingAPIResult();
      })
      .addCase(getActiveOrders.fulfilled, (state, action) => {
        state.getActiveOrdersAPI = successAPIResult(action.payload);
        state.activeOrders = action.payload;
      })
      .addCase(getActiveOrders.rejected, (state, action) => {
        state.getActiveOrdersAPI = errorAPIResult(action.error);
      })
      .addCase(getPendingOrders.pending, state => {
        state.getPendingOrdersAPI = loadingAPIResult();
      })
      .addCase(getPendingOrders.fulfilled, (state, action) => {
        state.getPendingOrdersAPI = successAPIResult(action.payload);
        state.pendingOrders = action.payload;
      })
      .addCase(getPendingOrders.rejected, (state, action) => {
        state.getPendingOrdersAPI = errorAPIResult(action.error);
      })
      .addCase(getCompletedOrders.pending, state => {
        state.getCompletedOrdersAPI = loadingAPIResult();
      })
      .addCase(getCompletedOrders.fulfilled, (state, action) => {
        state.getCompletedOrdersAPI = successAPIResult(action.payload);
        state.completedOrders = action.payload;
      })
      .addCase(getCompletedOrders.rejected, (state, action) => {
        state.getCompletedOrdersAPI = errorAPIResult(action.error);
      })
      .addCase(getInvoices.pending, state => {
        state.getInvoicesAPI = loadingAPIResult();
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.getInvoicesAPI = successAPIResult(action.payload);
        state.invoices = action.payload;
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.getInvoicesAPI = errorAPIResult(action.error);
      })
      .addCase(getInvoicePDF.pending, state => {
        state.getInvoicePDFAPI = loadingAPIResult();
      })
      .addCase(getInvoicePDF.fulfilled, (state, action) => {
        state.getInvoicePDFAPI = successAPIResult(action.payload);
        state.invoicePDF = action.payload;
      })
      .addCase(getInvoicePDF.rejected, (state, action) => {
        state.getInvoicePDFAPI = errorAPIResult(action.payload!);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getActiveOrdersAPI = initialState.getActiveOrdersAPI;
          state.getPendingOrdersAPI = initialState.getPendingOrdersAPI;
          state.getInvoicesAPI = initialState.getInvoicesAPI;
          state.getInvoicePDFAPI = initialState.getInvoicePDFAPI;

          state.activeOrders = initialState.activeOrders;
          state.pendingOrders = initialState.pendingOrders;
          state.invoices = initialState.invoices;
          state.invoicePDF = initialState.invoicePDF;
        } 
      )
  }
});

export const { resetInvoicePDF } = clientDashboardSlice.actions;
export default clientDashboardSlice.reducer;
