import React from 'react';
import { Link } from 'react-router-dom';

import { User } from '../../../../models';

import { ReactComponent as ProfileIcon } from '../../../../assets/images/profile_icon.svg';
import './ProfileInfoCard.scss';

export interface ProfileInfoCardProps {
  customer: User;
}

export function ProfileInfoCard(props: ProfileInfoCardProps) {
  const { customer } = props;

  return (
    <div className='profile-info-card-component'>
      <div className='name-container'>
        <div className='icon-container'>
          <ProfileIcon />
        </div>

        <div>
          <div className='name'>{`${customer.firstName} ${customer.lastName}`}</div>
          <div className='role'>{customer.email}</div>
        </div>
      </div>

      <div className='balance-container'>
        <Link to={`/create-order`} className='create-order-button'>Create order</Link>
      </div>
    </div>
  );
}
