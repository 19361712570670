import { UpdatePunchCardsParams } from '../TriplePInterface';

export interface PunchCardPayload {
  punch_in: string;
  punch_out?: string;
}

export const toPunchCard = (params: UpdatePunchCardsParams): PunchCardPayload => {
  return {
    punch_in: params.punchIn,
    punch_out: params.punchOut
  };
}
