import { PunchCard } from './PunchCard';
import { User } from './User';

export interface PunchCardSummary {
  workingTime: number;
	onClock: boolean;
}

export interface PunchCardsForDriver {
  punchCards: PunchCard[];
  summary: PunchCardSummary;
}

export const getWorkTimeForDriver = (pc?: PunchCardsForDriver): string => {
  if (!pc) return 'NA';

  const diff = pc.summary.workingTime;

  const hours = Math.floor(diff / 60);
  const minutes = diff % 60;

  return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
}

export interface PunchCardsForAllDrivers {
  summary: PunchCardsForDriver;
	driver: User;
}
