import React from 'react';
import { useDrag } from 'react-dnd';

import { DumpYardTemplate, ScheduleItemType } from '../../../models';

import './DumpYardTemplateItem.scss';

export interface DumpYardTemplateItemProps {
  template: DumpYardTemplate;
}

export function DumpYardTemplateItem(props: DumpYardTemplateItemProps) {
  const { template } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ScheduleItemType.DUMP_YARD,
    item: { id: template.id, type: ScheduleItemType.DUMP_YARD },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  const address = `${template.address}, ${template.zip}`;

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
      className={`dump-yard-template-item-component ${template.isDumpYard ? 'dump-yard' : ''} ${template.isStorage ? 'storage' : ''} ${template.isQuickStop ? 'quick-stop' : ''}`}
    >
      <div className='name-container'>
        <div className='name-text'>{template.name}</div>
      </div>

      <div>
        <div className='address-text'>{address}</div>
      </div>
    </div>
  );
}
