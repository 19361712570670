import { ScheduleForm } from '../TriplePInterface';

export interface SchedulePayload {
  data: {
    type: string;
    attributes: {
      driver_id: string;
      date: string;
    }
  }
}

export const toSchedule = (form: ScheduleForm): SchedulePayload => {
  return {
    data: {
      type: 'schedules',
      attributes: {
        driver_id: form.driverId,
        date: form.date
      }
    }
  };
}
