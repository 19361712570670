import axios from 'axios';

import { GoogleAddress } from '../models';

export const verifyAddress = async (address: string): Promise<GoogleAddress | undefined> => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyDriqceHS-zAat-UBCGfVvt43yIU0OQ6mI`
    );

    if (response.data.status === 'OK' && response.data.results.length > 0) {
      const result = response.data.results[0];

      // Check if the result has a premise type or street address
      if (result.types.includes('premise') || result.types.includes('street_address') || result.types.includes('route')) {
        const addressComponents = result.address_components;

        let number = '';
        let street = '';
        let cityName = '';
        let zip = '';

        for (let i = 0; i < addressComponents.length; i++) {
          const component = addressComponents[i];
          const types = component.types;

          if (types.includes('street_number')) {
            number = component.long_name;
          } else if (types.includes('route')) {
            street = component.long_name;
          } else if (types.includes('locality')) {
            cityName = component.long_name;
          } else if (types.includes('postal_code')) {
            zip = component.long_name;
          }
        }

        // if (number === '' || street === '' || cityName === '' || zip === '') return undefined;

        return {
          address: `${number} ${street}`,
          city: cityName,
          zip: zip,
        };
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  } catch (error) {
    console.error('Error getting coordinates:', error);
    return undefined;
  }
};
