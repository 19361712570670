import React from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { BsXCircle } from 'react-icons/bs';

import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { Order } from '../../../../models';
import { approveOrder, rejectOrder } from '../../receivedOrdersSlice';

import './ReceivedOrderCard.scss';

export interface AcceptOrderFormModel {
  deliveryFee: number;
  discount: number;
  aditionalItems: AcceptOrderAdditionalItem[];
}

export interface AcceptOrderAdditionalItem {
  description: string;
  quantity: number;
  basePrice: number;
}

const validationSchema = Yup.object().shape({
  deliveryFee: Yup.number()
    .required('Delivery fee is required'),
  aditionalItems: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string()
          .required('Description is required'),
        quantity: Yup.number()
          .min(1, 'Must be atleast 1'),
        basePrice: Yup.number()
          .positive('Must be positive')
      })
    )
});

const initialValues: AcceptOrderFormModel = {
  deliveryFee: 0,
  discount: 0,
  aditionalItems: []
};

export interface ReceivedOrderCardProps {
  order: Order;
}

export function ReceivedOrderCard(props: ReceivedOrderCardProps) {
  const dispatch = useAppDispatch();

  const { order } = props;

  const approveOrderAPI = useAppSelector(state => state.receivedOrders.approveOrderAPI);
  const rejectOrderAPI = useAppSelector(state => state.receivedOrders.rejectOrderAPI);

  const preventAction = approveOrderAPI.loading || rejectOrderAPI.loading;

  const onRejectPress = () => {
    if (preventAction) return;

    dispatch(rejectOrder({
      id: order.id
    }));
  }

  const onApprovePress = (form: AcceptOrderFormModel) => {
    if (preventAction) return;

    dispatch(approveOrder({
      id: order.id,
      form
    }));
  }

  return (
    <div className='received-order-card-component'>
      <div className='info-container'>
        <div className='info-item'>
          <div className='info-label'>Name:</div>
          <div className='info-value'>{order.name}</div>
        </div>

        <div className='info-item'>
          <div className='info-label'>Phone Number:</div>
          <div className='info-value'>{order.phoneNumber}</div>
        </div>

        <div className='info-item'>
          <div className='info-label'>Email:</div>
          <div className='info-value'>{order.email}</div>
        </div>

        <div className='info-item'>
          <div className='info-label'>Address:</div>
          <div className='info-value'>{`${order.address}, ${order.zip}`}</div>
        </div>

        <div className='info-item'>
          <div className='info-label'>Delivery date:</div>
          <div className='info-value'>{order.deliveryDate}</div>
        </div>

        <div className='info-item'>
          <div className='info-label'>Service:</div>
          <div className='info-value'>{`${order.service}, ${order.dumpsterSize}`}</div>
        </div>

        <div className='info-item'>
          <div className='info-label'>Material Disposing:</div>
          <div className='info-value'>{order.materialDisposing}</div>
        </div>

        <div className='info-item'>
          <div className='info-label'>Instructions:</div>
          <div className='info-value'>{order.specialInstructions}</div>
        </div>
      </div>

      <div className='vertical-divider'/>

      <Formik
        initialValues={initialValues}
        onSubmit={onApprovePress}
        validationSchema={validationSchema}
      >
        {({ errors, touched, values }) => (
          <Form className='approve-order-form-component'>
            <div className='column'>
              <div className='column-title'>Additional Items</div>

              <div className='row'>
                <div className='label width-150'>Description:</div>
                <div className='label width-50'>Quantity:</div>
                <div className='label width-50'>Price:</div>
              </div>

              <FieldArray name='aditionalItems'>
                {({ remove, push }) => (
                  <div>
                    {values.aditionalItems.length > 0 &&
                      values.aditionalItems.map((item, index) => (
                        <div className='row' key={index}>
                          <div className='field width-150'>
                            <Field
                              className='text-input'
                              name={`aditionalItems.${index}.description`}
                              type='text'
                            />

                            <ErrorMessage
                              name={`aditionalItems.${index}.description`}
                              component='div'
                              className='field-error'
                            />
                          </div>

                          <div className='field width-50'>
                            <Field
                              className='text-input'
                              name={`aditionalItems.${index}.quantity`}
                              type='number'
                            />

                            <ErrorMessage
                              name={`aditionalItems.${index}.quantity`}
                              component='div'
                              className='field-error'
                            />
                          </div>

                          <div className='field width-50'>
                            <Field
                              className='text-input'
                              name={`aditionalItems.${index}.basePrice`}
                              type='number'
                            />

                            <ErrorMessage
                              name={`aditionalItems.${index}.basePrice`}
                              component='div'
                              className='field-error'
                            />
                          </div>

                          <div className='secondary' onClick={() => remove(index)}><BsXCircle className='remove-icon'/></div>
                        </div>
                      ))}

                    <div
                      className='add-item-button'
                      onClick={() => push({ description: '', quantity: 1, basePrice: 0 })}
                    >
                      Add Item
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>

            <div className='vertical-divider'/>
            
            <div className='column'>
              <div className='column-title'>Additional Charges</div>

              <div className='field-container'>
                <div className='label'>Delivery fee:</div>
                <Field id='deliveryFee' name='deliveryFee' className='text-input'/>
                {errors.deliveryFee && touched.deliveryFee && <div className='form-error'>{errors.deliveryFee}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Discount:</div>
                <Field id='discount' name='discount' className='text-input'/>
                {errors.discount && touched.discount && <div className='form-error'>{errors.discount}</div>}
              </div>

              <div className='buttons-container'>
                <button type='submit' className='button selected'>
                  Accept
                </button>
                <div className='button' onClick={onRejectPress}>Reject</div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
