import { Address } from './Address';
import { Entity } from './Entity';

export interface User extends Entity {
  auth0Id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roles: Role[];
  address?: Address;
}

export enum Role {
  CLIENT = 'client',
  DRIVER = 'driver',
  ADMIN = 'admin'
}
