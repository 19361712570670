import { Invoice } from '../../../models';

export const getInvoice = (data: any): Invoice => {
  return {
    id: data.id,
    orderId: data.attributes.order_id,
    total: data.attributes.total,
    type: data.attributes.type,
  };
}

export const getInvoices = (data: any): Invoice[] => {
  return data.map(getInvoice);
}
