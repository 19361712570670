import React from 'react';
import { Link } from 'react-router-dom';

import { RegisterAccountForm } from './RegisterAccountForm';
import { ReactComponent as Close } from '../../../assets/images/close.svg';

import './RegisterAccount.scss';

export function RegisterAccount() {
  return (
    <div className='register-account-component'>
      <Link to='/'>
        <Close className='close-button'/>
      </Link>
      <div className='layout-container'>
        <div className='content-container'>
          <div className='title'>Create Account</div>

          <RegisterAccountForm />

          <div className='login-container'>
            <div className='already-text'>Already have an account?</div>
            <Link to='/auth/login' className='login-link'>
              <div className='login-text'>Login</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
