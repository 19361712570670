import React from 'react';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { OrderStatus } from '../../../../models';
import { getReceivedOrders, resetApproveOrder, resetRejectOrder } from '../../receivedOrdersSlice';
import { ReceivedOrderCard } from '../../components';

import './ReceivedOrdersView.scss';

const PAGE_SIZE = 20;

export function ReceivedOrdersView() {
  const dispatch = useAppDispatch();

  const receivedOrders = useAppSelector(state => state.receivedOrders.receivedOrders);
  const getRecivedOrdersAPI = useAppSelector(state => state.receivedOrders.getRecivedOrdersAPI);
  const approveOrderAPI = useAppSelector(state => state.receivedOrders.approveOrderAPI);
  const rejectOrderAPI = useAppSelector(state => state.receivedOrders.rejectOrderAPI);

  const [fetchOrders, setFetchOrders] = React.useState<boolean>(true);
  const [offset, setOffset] = React.useState<number>(0);

  const isLoading = approveOrderAPI.loading || rejectOrderAPI.loading || getRecivedOrdersAPI.loading;

  React.useEffect(() => {
    if (!getRecivedOrdersAPI.loading && !!getRecivedOrdersAPI.value && getRecivedOrdersAPI.value.length === PAGE_SIZE) {
      setOffset(offset + PAGE_SIZE);
    }
  }, [getRecivedOrdersAPI.loading, !!getRecivedOrdersAPI.value]);

  React.useEffect(() => {
    // We already fetched orders
    if (fetchOrders || offset !== 0) {
      dispatch(getReceivedOrders({
        pagination: {
          offset,
          count: PAGE_SIZE
        },
        filters: {
          statuses: [OrderStatus.OrderStatusReceived],
        }
      }));

      setFetchOrders(false);
    }
  }, [offset, fetchOrders]);

  React.useEffect(() => {
    if (!rejectOrderAPI.loading && !!rejectOrderAPI.error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!rejectOrderAPI.loading && !!rejectOrderAPI.value) {
      toast.success('Successfully rejected order.');
    }

    if (!rejectOrderAPI.loading) {
      dispatch(resetRejectOrder())
    }
  }, [rejectOrderAPI.loading, !!rejectOrderAPI.error, !!rejectOrderAPI.value]);

  React.useEffect(() => {
    if (!approveOrderAPI.loading && !!approveOrderAPI.error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!approveOrderAPI.loading && !!approveOrderAPI.value) {
      toast.success('Successfully accepted order.');
    }

    if (!approveOrderAPI.loading) {
      dispatch(resetApproveOrder())
    }
  }, [approveOrderAPI.loading, !!approveOrderAPI.error, !!approveOrderAPI.value]);

  return (
    <div className='received-orders-view-component'>
      <div className='title-container'>
        <div className='title-text'>Received Orders</div>
        <div className='title-count'>{receivedOrders.length}</div>
        {isLoading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#0A60BB"/></div>}
      </div>

      <div className='orders-container'>
        {receivedOrders.map(o => <ReceivedOrderCard key={o.id} order={o}/>)}
      </div>
    </div>
  );
}
