import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult } from '../../lib/redux';
import { triplePSDatasource } from '../../datasource';
import { logout } from '../Auth/authSlice';

export interface CancelOrderState {
  cancelOrder: APIResult<string>;
}

const initialState: CancelOrderState = {
  cancelOrder: emptyAPIResult()
}

export const cancelOrderAPI = createAsyncThunk(
  'cancelOrder/cancelOrderAPI',
  async (id: string) => {
    return triplePSDatasource.cancelOrder(id);
  }
);

export const cancelOrderSlice = createSlice({
  name: 'cancelOrder',
  initialState,
  reducers: {
    resetCancelOrder(state) { state.cancelOrder = initialState.cancelOrder },
  },
  extraReducers: builder => {
    builder
      .addCase(cancelOrderAPI.pending, state => {
        state.cancelOrder = loadingAPIResult();
      })
      .addCase(cancelOrderAPI.fulfilled, (state, action) => {
        state.cancelOrder = successAPIResult(action.payload);
      })
      .addCase(cancelOrderAPI.rejected, (state, action) => {
        state.cancelOrder = errorAPIResult(action.error);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.cancelOrder = initialState.cancelOrder;
        }
      )
  }
});

export const { resetCancelOrder } = cancelOrderSlice.actions;
export default cancelOrderSlice.reducer;
