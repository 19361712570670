import Modal from 'react-modal';
import React from "react";
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setShowDeleteOrderModal } from "../ordersSlice";
import { cancelOrderAPI as cancelOrder, resetCancelOrder } from '../../CancelOrder/cancelOrderSlice';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { Oval } from 'react-loader-spinner';

import './DeleteOrderModal.scss'

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: "#FFFFFF"
    },
  };

export const DeleteOrderModal = () => {
    const dispatch = useAppDispatch();

    const showDeleteOrderModal = useAppSelector(state => state.orders.showDeleteOrderModal);
    const cancelOrderAPI = useAppSelector(state => state.cancelOrder.cancelOrder);

    const isOpen = showDeleteOrderModal ? true : false;

    React.useEffect(() => {
      if (!cancelOrderAPI.loading && !!cancelOrderAPI.error) {
          toast.error('Something went wrong. Please try again.');
      }

      if (!cancelOrderAPI.loading && !cancelOrderAPI.error && cancelOrderAPI.value) {
          toast.success('Successfully canceled order.');
          dispatch(resetCancelOrder());
          dispatch(setShowDeleteOrderModal(undefined));
      }
    }, [!!cancelOrderAPI.error, !!cancelOrderAPI.value, !!cancelOrderAPI.loading]);

    const onClose = () => {
      dispatch(setShowDeleteOrderModal(undefined));
    }

    const confirmDeletion = () => {
      if (!!showDeleteOrderModal) {
        dispatch(cancelOrder(showDeleteOrderModal));
      }
    }

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
        <div className='delete-order-modal-container'>
          <Close className='close-button' onClick={onClose}/>

          <div className='title'>Cancel order?</div>

          <div className='buttons-container'>
            <button type='submit' className='button secondary-button' disabled={cancelOrderAPI.loading} onClick={onClose}>
              Cancel
            </button>
            <button type='submit' className='button' disabled={cancelOrderAPI.loading} onClick={confirmDeletion}>
              Delete
              {cancelOrderAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
            </button>
          </div>
        </div>
      </Modal>
    );
}
