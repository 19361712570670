import React from 'react';

import './MaterialDisposingPill.scss';

export interface MaterialDisposingPillProps {
  materialDisposing: string;
}

export const MaterialDisposingPill = (props: MaterialDisposingPillProps) => {
  const { materialDisposing } = props;

  let label = 'na';

  switch (materialDisposing) {
    case 'construction':
      label = 'C';
      break;
    case 'Household/Construction':
      label = 'M';
      break;
    case 'household':
      label = 'H';
      break;

    case 'dirt':
      label = 'dirt';
      break;
    case 'brick':
      label = 'brick';
      break;
    case 'block':
      label = 'block';
      break;
    case 'concrete':
      label = 'concrete';
      break;
    case 'other':
      label = 'other';
      break;

    default:
      break;
  }

  return (
    <div className='material-disposing-pill'>
      {label}
    </div>
  );
};
