import React from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';

import { store } from '../../app/store';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { createOrderAPI } from './createOrderSlice';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { CleanFillMaterialsDisposing, DumpsterSize, MaterialDisposing, Order, OrderStatus, ServiceType } from '../../models';
import { RadioButton } from '../Shared';
import { CreateOrderConfirmation } from './CreateOrderConfirmation';
import { isClientSelector, isAdminSelector } from '../Auth/authSlice';
import { TermsAndConditionsModal } from './TermsAndConditionsModal/TermsAndConditionsModal';
import { setCreateOrderPermanentRental } from '../PermanentRentals/permanentRentalsSlice';
import { verifyAddress } from '../../lib/googleAddress';

import './CreateOrder.scss';

export type CreateOrderFormModel = Omit<Order, 'id' | 'pickupDate' | 'createdAt'>;

export function CreateOrder() {
  const dispatch = useAppDispatch();

  const isClient = isClientSelector(store.getState());
  const isAdmin = isAdminSelector(store.getState());

  const isLoading = useAppSelector(state => state.createOrder.createOrder.loading);
  const error = useAppSelector(state => state.createOrder.createOrder.error);
  const createdOrder = useAppSelector(state => state.createOrder.createOrder.value);
  const user = useAppSelector(state => state.auth.user);
  const signature = useAppSelector(state => state.orders.signature);
  const permanentOrderTemplate = useAppSelector(state => state.permanentRentals.createOrderPermanentRental);

  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(!isAdmin);
  const [isChecked, setIsChecked] = React.useState(isAdmin); // Don't require admin to read T&A

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('First name is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .test({
        name: 'phone test',
        message: 'Phone number must be valid US number.',
        test: value => !!value && isValidPhoneNumber(value, 'US')
      }),
    email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    address: Yup.string()
      .required('Address is required'),
    deliveryDate: Yup.string()
      .required('Delivery date is required')
      .test({
        name: 'date test',
        message: 'Delivery date must be on a weekday.',
        test: value => {
          if (!value) {
            return true
          }
          const date = new Date(value);
          return date.getDay() != 6 && date.getDay() != 5;
        }
      })
      .test({
        name: 'date test 2',
        message: 'Delivery date can not be today or before.',
        test: value => {
          if (!value) {
            return true
          }
          const date = moment(value, 'YYYY-MM-DD');
          
          let dateToCompare = moment().startOf('day');
          if (isAdmin) {
            dateToCompare = dateToCompare.add(-1, 'day');
          }
          return date.diff(dateToCompare) > 0;
        }
      })
      .test({
        name: 'same day date test',
        message: 'Orders for tomorrow accepted untill 8PM.',
        test: value => {
          if (!value) {
            return true;
          }

          if (!!isAdmin) {
            return true;
          }

          const currentTime = moment().add(1, 'day');
          const currentTimeString = currentTime.format('YYYY-MM-DD');

          if (value != currentTimeString) {
            return true;
          }

          var currentHour = moment().format("HH");
          return parseInt(currentHour, 10) < 20;
        }
      }),
    zip: Yup.string()
      .required('ZIP is required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits'),
    price: Yup.number()
      .required('Price is required'),
    deliveryFee: Yup.number()
      .required('Delivery fee is required'),
    discount: Yup.number()
      .required('Discount is required'),
    dumpsterSize: Yup.string().required('Dumpster size is required'),
    specialInstructions: Yup.string()
      .test('length', 'Must be less than 500 characters', val => !val || (!!val && val.length < 500))
  });

  const initialValues: CreateOrderFormModel = {
    userId: user && isClient ? user.id : undefined,
    name: user && isClient 
      ? `${user.firstName} ${user.lastName}`
      : !!permanentOrderTemplate ? permanentOrderTemplate.name : '',
    address: !!permanentOrderTemplate ? permanentOrderTemplate.address : '',
    zip: !!permanentOrderTemplate ? permanentOrderTemplate.zip : '',
    phoneNumber: user && isClient
      ? `${user.phoneNumber}`
      : !!permanentOrderTemplate ? permanentOrderTemplate.phoneNumber : '',
    email: user && isClient 
      ? `${user.email}`
      : !!permanentOrderTemplate ? permanentOrderTemplate.email : '',
    service: ServiceType.DUMPSTER,
    dumpsterSize: !!permanentOrderTemplate ? permanentOrderTemplate.dumpsterSize : undefined,
    materialDisposing: MaterialDisposing.CONSTRUCTION,
    description: '',
    deliveryDate: '',
    status: isAdmin ? OrderStatus.OrderStatusCreated : OrderStatus.OrderStatusReceived,
    specialInstructions: '',
    price: 0,
    deliveryFee: 0,
    discount: 0,
    permanentRentalId: permanentOrderTemplate?.id,
    po: '',
    additionalCharges: 0
  };

  React.useEffect(() => {
    return () => {
      // Reset permament rental template
      dispatch(setCreateOrderPermanentRental(undefined));
    };
  }, []);

  React.useEffect(() => {
    if (!isLoading && !!error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!isLoading && !error && !!createdOrder) {
      // Invoke this function so google can track it.
      // @ts-ignore
      window.gtag_report_conversion(undefined, createdOrder.id);

      toast.success('Successfully created order.');
    }
  }, [!!createdOrder, error]);

  const onSubmit = async (form: CreateOrderFormModel) => {
    const address = `${form.address}, ${form.zip}`;

    const googleAddress = await verifyAddress(address);

    if (!googleAddress) {
      alert('Address not valid. Please enter valid address.');
      return;
    }
    
    if (isClient) {
      form.userId = user?.id;
    }
    
    dispatch(createOrderAPI({
      order: {
        ...form,
        address: form.address,
        zip: form.zip,
      },
      signature
    }));
  }

  if (!!createdOrder) {
    return <CreateOrderConfirmation order={createdOrder}/>
  }

  const onTermsClick = () => {
    setShowTermsAndConditionsModal(true);
  }

  const onCloseTermsAndConditions = () => {
    setShowTermsAndConditionsModal(false);
    if (!!signature) {
      setIsDisabled(false);
    }
  };

  const onAcceptTermsAndConditions = () => {
    setIsDisabled(false);
    setIsChecked(true);
    setShowTermsAndConditionsModal(false);
  };

  const checkboxClick = () => {
    if (isDisabled) {return;}
    setIsChecked(!isChecked);
  }

  return (
    <div className='create-order-component'>
      <Link to='/'>
        <Close className='close-button'/>
      </Link>

      <div className='title'>Fill out the form</div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
         {({ values, errors, touched }) => (
          <Form className='create-order-form-component'>
            <div className='fields-scroll-container'>
              <div className='field-container'>
                <div className='label'>Full name / Company name:</div>
                <Field id='name' name='name' className='text-input'/>
                {errors.name && touched.name && <div className='form-error'>{errors.name}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Phone number:</div>
                <Field id='phoneNumber' name='phoneNumber' className='text-input'/>
                {errors.phoneNumber && touched.phoneNumber && <div className='form-error'>{errors.phoneNumber}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Email:</div>
                <Field id='email' name='email' className='text-input'/>
                {errors.email && touched.email && <div className='form-error'>{errors.email}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Delivery Address:</div>
                <Field id='address' name='address' className='text-input'/>
                {errors.address && touched.address && <div className='form-error'>{errors.address}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>ZIP code:</div>
                <Field id='zip' name='zip' className='text-input' type='number'/>
                {errors.zip && touched.zip && <div className='form-error'>{errors.zip}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Delivery date:</div>
                <Field id='deliveryDate' name='deliveryDate' className='text-input' type='date'/>
                {errors.deliveryDate && touched.deliveryDate && <div className='form-error'>{errors.deliveryDate}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Service:</div>
                <div className='radio-button-options-container'>
                  <RadioButton name="service" value={ServiceType.DUMPSTER} label='Dumpster rental'/>
                  {isAdmin && <RadioButton name="service" value={ServiceType.JUNK} label='Junk removal'/>}
                </div>
              </div>

              <div className='field-container'>
                <div className='label'>Size of dumpster:</div>
                <div className='radio-button-options-container'>
                  <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_10} label='10 yards'/>
                  <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_15} label='15 yards'/>
                  <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_CLEAN_FILL} label='Clean Fill Box'/>
                </div>
                {errors.dumpsterSize && touched.dumpsterSize && <div className='form-error'>{errors.dumpsterSize}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Material disposing:</div>
                <div className='radio-button-options-container'>
                  {values.dumpsterSize === DumpsterSize.SIZE_CLEAN_FILL && (
                    <>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.BRICK} label='Brick'/>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.BLOCK} label='Block'/>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.DIRT} label='Dirt'/>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.CONCRETE} label='Concrete'/>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.OTHER} label='Other'/>
                    </>
                  )}
                  {(values.dumpsterSize === DumpsterSize.SIZE_10 || values.dumpsterSize === DumpsterSize.SIZE_15) && (
                    <>
                      <RadioButton name="materialDisposing" value={MaterialDisposing.CONSTRUCTION} label='Construction'/>
                      <RadioButton name="materialDisposing" value={MaterialDisposing.HOUSEHOLD} label='Household'/>
                      <RadioButton name="materialDisposing" value={MaterialDisposing.HOUSEHOLD_CONSTRUCTION} label='Mix'/>
                    </>
                  )}
                </div>
              </div>

              {values.service === ServiceType.DUMPSTER && (
                <div className='field-container'>
                  <div className='label'>Description of material:</div>
                  <Field id='description' name='description' className='text-input' placeholder='Drywall, carpet, trash, etc..'/>
                </div>
              )}

              {values.service === ServiceType.JUNK && (
                <div className='field-container'>
                  <div className='label'>Price:</div>
                  <Field id='price' name='price' className='text-input' type='number'/>
                  {errors.price && touched.price && <div className='form-error'>{errors.price}</div>}
                </div>
              )}

              {isAdmin && (
                <div className='field-container'>
                  <div className='label'>Delivery fee:</div>
                  <Field id='deliveryFee' name='deliveryFee' className='text-input' type='number'/>
                  {errors.deliveryFee && touched.deliveryFee && <div className='form-error'>{errors.deliveryFee}</div>}
                </div>
              )}

              {isAdmin && (
                <div className='field-container'>
                  <div className='label'>Discount:</div>
                  <Field id='discount' name='discount' className='text-input' type='number'/>
                  {errors.discount && touched.discount && <div className='form-error'>{errors.discount}</div>}
                </div>
              )}

              <div className='field-container'>
                <div className='label'>Delivery instructions:</div>
                <Field id='specialInstructions' name='specialInstructions' className='text-input' component='textarea' rows='4' placeholder='Drop street, drop backyard, payment in mailbox, etc..'/>
                {errors.specialInstructions && touched.specialInstructions && <div className='form-error'>{errors.specialInstructions}</div>}
              </div>
              
              <div className='field-container'>
                <div className='label'>PO (if applicable):</div>
                <Field id='po' name='po' className='text-input'/>
              </div>

              <div className='checkbox-field'>
                <label className='checkbox-label'>
                  <Field id='termsAndConditions' name='termsAndConditions' type='checkbox' disabled={isDisabled} checked={isChecked} onClick={checkboxClick}/>
                  <span className='termsAndConditionsText'>I accept <span className='termsAndConditionsLink' onClick={onTermsClick}>Terms and Conditions</span></span>
                </label>
              </div>
            </div>

            <button type='submit' className='create-order-button'  disabled={isLoading || !isChecked}>
              Confirm Order
              {isLoading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
            </button>
          </Form>
         )}
      </Formik>
      <div style={{ height: 0 }}>
        <TermsAndConditionsModal isOpen={showTermsAndConditionsModal} onClose={onCloseTermsAndConditions} onAccept={onAcceptTermsAndConditions}/>
      </div>
    </div>
  );
}
