import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RegisterAccount } from './RegisterAccount/RegisterAccount';
import { Login } from './Login/Login';

export function Auth() {
  return (
    <Routes>
      <Route path='register' element={<RegisterAccount />} />
      <Route path='login' element={<Login />} />
    </Routes>
  );
}
