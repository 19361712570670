import { ScheduleItemForm } from '../TriplePInterface';

export interface ScheduleItemPayload {
  data: {
    type: string;
    attributes: {
      schedule_id: string;
      type: string;
      delivery_id?: string;
      pickup_id?: string;
      dump_yard_id?: string;
    }
  }
}

export const toScheduleItem = (form: ScheduleItemForm): ScheduleItemPayload => {
  return {
    data: {
      type: 'schedule_items',
      attributes: {
        schedule_id: form.scheduleId,
        type: form.type,
        delivery_id: form.deliveryId,
        pickup_id: form.pickupId,
        dump_yard_id: form.dumpYardId
      }
    }
  };
}
