import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { createUser, resetCreateUser } from '../authSlice';
import { formatNumber, isValidPhoneNumber, parse } from 'libphonenumber-js';

export interface RegisterAccountFormModel {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const initialValues: RegisterAccountFormModel = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  confirmPassword: ''
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
  .required('First name is required'),
  lastName: Yup.string()
  .required('Last name is required'),
  phoneNumber: Yup.string()
  .required('Phone number is required')
  .test({
    name: 'phone test',
    message: 'Phone number must be valid US number.',
    test: value => !!value && isValidPhoneNumber(value, 'US')
  }),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain 8 characters, one uppercase, one lowercase and one number'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export function RegisterAccountForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoading = useAppSelector(state => state.auth.createUser.loading);
  const error = useAppSelector(state => state.auth.createUser.error);
  const createUserSuccess = useAppSelector(state => !!state.auth.createUser.value);

  React.useEffect(() => {
    if (!isLoading && !!error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!isLoading && !error && createUserSuccess) {
      toast.success('Successfully created account.');
      dispatch(resetCreateUser());
      navigate('/auth/login');
    }
  }, [createUserSuccess, error]);

  const onSubmit = (form: RegisterAccountFormModel) => {
    dispatch(createUser({
      first_name: form.firstName,
      last_name: form.lastName,
      phone_number: formatNumber(parse(form.phoneNumber, 'US'), 'E.164'),
      email: form.email,
      password: form.password
    }));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className='register-account-form-component'>
          <div className='form-field-container'>
            <Field id='firstName' name='firstName' placeholder='First name' className='form-field-text'/>
            {errors.firstName && touched.email && <div className='form-error'>{errors.email}</div>}
          </div>

          <div className='form-field-container'>
            <Field id='lastName' name='lastName' placeholder='Last name' className='form-field-text'/>
            {errors.lastName && touched.lastName && <div className='form-error'>{errors.lastName}</div>}
          </div>

          <div className='form-field-container'>
            <Field id='phoneNumber' name='phoneNumber' placeholder='Phone number' className='form-field-text'/>
            {errors.phoneNumber && touched.phoneNumber && <div className='form-error'>{errors.phoneNumber}</div>}
          </div>

          <div className='form-field-container'>
            <Field id='email' name='email' placeholder='Email' className='form-field-text'/>
            {errors.email && touched.email && <div className='form-error'>{errors.email}</div>}
          </div>

          <div className='form-field-container'>
            <Field id='password' name='password' placeholder='Password' type="password" className='form-field-text'/>
            {errors.password && touched.password && <div className='form-error'>{errors.password}</div>}
          </div>

          <div className='form-field-container'>
            <Field id='confirmPassword' name='confirmPassword' placeholder='Confirm Password' type="password" className='form-field-text'/>
            {errors.confirmPassword && touched.confirmPassword && <div className='form-error'>{errors.confirmPassword}</div>}
          </div>

          <div className='button-container'>
            <button type='submit' className='register-button' disabled={isLoading}>
              Create Account
              {isLoading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
            </button>

            {!!error && (
              <div className='error-text'>
                Something went wrong. Please try again.
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
