import { Delivery } from '../../../models';

export const getDelivery = (data: any): Delivery => {
  return {
    id: data.id,
    address: data.attributes.address,
    zip: data.attributes.zip,
    service: data.attributes.service,
    dumpsterSize: data.attributes.dumpster_size,
    deliveryDate: data.attributes.delivery_date,
    status: data.attributes.status,
    orderId: data.attributes.order_id,
    driverId: data.attributes.driver_id,
    name: data.attributes.name,
    instructions: data.attributes.instructions,
    materialDisposing: data.attributes.material_disposing,
    latitude: data.attributes.latitude,
    longitude: data.attributes.longitude,
    priority: data.attributes.priority
  };
}

export const getDeliveries = (data: any): Delivery[] => {
  return data.map(getDelivery);
}
