import React from 'react';

import { useAppDispatch } from '../../../../app/hooks';
import { getCompletedOrders } from '../../clientDashboardSlice';
import { OrderHistoryTable } from '../OrderHistoryTable/OrderHistoryTable';
import { Pagination } from '../Pagination/Pagination';

import './OrderHistoryCard.scss';

const PAGE_SIZE = 20;

export function OrderHistoryCard() {
  const dispatch = useAppDispatch();

  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    dispatch(getCompletedOrders({
      offset: page * PAGE_SIZE,
      count: PAGE_SIZE
    }));
  }, [page]);

  const onNextPage = () => { setPage(page + 1) }
  const onPreviosPage = () => { setPage(page - 1) }

  return (
    <div className='active-orders-card-component'>
      <div className='row'>
        <div className='title'>Completed Orders</div>
      </div>

      <OrderHistoryTable />
      <Pagination 
        current={page}
        onNextPage={onNextPage}
        onPreviousPage={onPreviosPage}
      />
    </div>
  );
}
