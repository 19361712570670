import React from 'react';
import { Link } from 'react-router-dom';
import { BsFacebook, BsYoutube, BsInstagram } from 'react-icons/bs';

import './Home.scss';

export function Home() {
  return (
    <div className='home-component'>
      <div className='title'>Dumpster Rentals & Trash Removal</div>
      <div className='subtitle'>in Allegheny, Westmoreland, Butler & Armstrong County.</div>

      <div className='buttons-container'>
        <Link to='/create-order'>
          <div className='create-button'>Create order</div>
        </Link>

        <Link to='/cancel-order'>
          <div className='cancel-button'>Cancel order</div>
        </Link>
      </div>

      <div className='social-media-container'>
        <a
          href='https://www.facebook.com/Triple-P-Disposal-LLC-1594677190661015'
          target='_blank'
        >
          <BsFacebook className='icon'/>
        </a>

        <a
          href='https://www.youtube.com/channel/UCtTzsfwZUEK04CcKKtfYhlw'
          target='_blank'
        >
          <BsYoutube className='icon'/>
        </a>

        <a
          href='https://www.instagram.com/triplepdisposalllc'
          target='_blank'
        >
          <BsInstagram className='icon'/>
        </a>
      </div>
    </div>
  );
}
