import React from 'react';
import Modal from 'react-modal';
import { BsCheck2 } from 'react-icons/bs';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { OrderFilters } from '../../../datasource';
import { OrderStatus, orderStatusToText } from '../../../models';

import './FiltersModal.scss';

const modalStyles = {
  content: {
    left: 'calc(100% - 500px)',
    top: '0%',
    right: '0%',
    bottom: '0%',
    backgroundColor: "#FFFFFF"
  },
};

export interface FiltersModalProps {
  isOpen: boolean;
  filters: OrderFilters;

  onClose: () => void;
  setFilters: (filter: OrderFilters) => void;
}

export function FiltersModal(props: FiltersModalProps) {
  const { isOpen, onClose, filters, setFilters } = props;
  
  const [pickupDateFrom, setPickupDateFrom] = React.useState<moment.Moment | undefined>(!!filters.pickupStartDate ? moment(filters.pickupStartDate) : undefined);
  const [pickupDateTo, setPickupDateTo] = React.useState<moment.Moment | undefined>(!!filters.pickupEndDate ? moment(filters.pickupEndDate) : undefined);

  const [createdDateFrom, setCreatedDateFrom] = React.useState<moment.Moment | undefined>(!!filters.createdStartDate ? moment(filters.createdStartDate) : undefined);
  const [createdDateTo, setCreatedDateTo] = React.useState<moment.Moment | undefined>(!!filters.createdEndDate ? moment(filters.createdEndDate) : undefined);

  const onCloseModal = () => {
    setFilters({
      ...filters,
      createdStartDate: !!createdDateFrom ? createdDateFrom.format('YYYY-MM-DD') : undefined,
      createdEndDate: !!createdDateTo ? createdDateTo.format('YYYY-MM-DD') : undefined,
      pickupStartDate: !!pickupDateFrom ? pickupDateFrom.format('YYYY-MM-DD') : undefined,
      pickupEndDate: !!pickupDateTo ? pickupDateTo.format('YYYY-MM-DD') : undefined,
    })
    
    onClose();
  }

  const renderStatus = (status: OrderStatus): React.ReactNode => {
    const selected = filters.statuses.includes(status);

    const onClick = () => {
      let statuses = filters.statuses;

      if (selected) {
        statuses = filters.statuses.filter(s => s !== status);
      } else {
        statuses.push(status);
      }

      setFilters({
        ...filters,
        statuses
      });
    };

    return (
      <div key={status} className='status-item-container' onClick={onClick}>
        <div className='status-item-title'>{orderStatusToText[status]}</div>
        {selected && <BsCheck2 />}
      </div>
    );
  }

  const renderCreatedDateOptionsFilter = (): React.ReactNode => {
    const onFromChange = (value: moment.Moment | null) => {
      if (!value || !value.isValid()) setCreatedDateFrom(undefined);

      setCreatedDateFrom(value!);
    }

    const onToChange = (value: moment.Moment | null) => {
      if (!value || !value.isValid()) setCreatedDateTo(undefined);

      setCreatedDateTo(value!);
    }

    return (
      <div className='pickup-date-container'>
        <DatePicker label='From' value={createdDateFrom} onChange={onFromChange} className='from'/>

        <DatePicker label='To' value={createdDateTo} onChange={onToChange}/>
      </div>
    );
  }

  const renderPickupDateOptionsFilter = (): React.ReactNode => {
    const onFromChange = (value: moment.Moment | null) => {
      if (!value || !value.isValid()) setPickupDateFrom(undefined);

      setPickupDateFrom(value!);
    }

    const onToChange = (value: moment.Moment | null) => {
      if (!value || !value.isValid()) setPickupDateTo(undefined);

      setPickupDateTo(value!);
    }

    return (
      <div className='pickup-date-container'>
        <DatePicker label='From' value={pickupDateFrom} onChange={onFromChange} className='from'/>

        <DatePicker label='To' value={pickupDateTo} onChange={onToChange}/>
      </div>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      style={modalStyles}
    >
      <div className='filters-modal-component'>
        <Close className='close-button' onClick={onCloseModal}/>

        <div className='title'>Filters</div>

        <div className='filter-container'>
          <div className='filter-title'>Statuses</div>
          {[
            OrderStatus.OrderStatusCanceled,
            OrderStatus.OrderStatusComplete,
            OrderStatus.OrderStatusCreated,
            OrderStatus.OrderStatusDelivered,
            OrderStatus.OrderStatusPickedUp,
            OrderStatus.OrderStatusScheduled,
            OrderStatus.OrderStatusScheduledToPickup,
            OrderStatus.OrderStatusReceived
          ].map(renderStatus)}
        </div>
          
        <div className='filter-container'>
          <div className='filter-title'>Created on date</div>
          {renderCreatedDateOptionsFilter()}
        </div>

        <div className='filter-container'>
          <div className='filter-title'>Pick up date</div>
          {renderPickupDateOptionsFilter()}
        </div>
      </div>
    </Modal>
  );
}
