import moment from 'moment';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import './DateRangePicker.scss';

export interface DateRangePickerProps {
  fromDate: moment.Moment;
  setFromDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  toDate: moment.Moment;
  setToDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const { fromDate, toDate, setFromDate, setToDate } = props;

  const onFromChange = (newFrom: moment.Moment | null) => {
    if (!newFrom) return;

    setFromDate(newFrom);
  }

  const onToChange = (newTo: moment.Moment | null) => {
    if (!newTo) return;

    setToDate(newTo);
  }

  return (
    <div className='date-range-picker-component'>
      <DatePicker label='From' value={fromDate} onChange={onFromChange} className='from'/>

      <DatePicker label='To' value={toDate} onChange={onToChange}/>
    </div>
  );
}
