import { Entity } from './Entity';

export enum DumpYardStatus {
  CREATED = 'created',
  SCHEDULED = 'scheduled',
  COMPLETE = 'complete',
  CANCELED = 'canceled'
}

export interface DumpYard extends Entity {
  address: string;
  zip: string;
  templateId: string;
  date: string;
  status: DumpYardStatus;
  name: string;
  instructions: string;
}
