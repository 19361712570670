import React from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { toggleShowCreateScheduleModal, createSchedule, deleteSchedule } from '../scheduleSlice';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { User } from '../../../models';

import './CreateScheduleModal.scss';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF"
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  }
};

export function CreateScheduleModal() {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(state => state.schedules.showCreateScheduleModal);
  const drivers = useAppSelector(state => state.drivers.drivers) || [];
  const schedules = useAppSelector(state => state.schedules.schedules) || [];
  const date = useAppSelector(state => state.schedules.dateMoment);
  const createScheduleAPI = useAppSelector(state => state.schedules.createSchedule);

  React.useEffect(() => {
    if (!createScheduleAPI.loading && !!createScheduleAPI.error) {
      if (createScheduleAPI.error.code === "3004") {
        toast.error('Schedule can\'t be created for date in the past.');
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    }

    if (!createScheduleAPI.loading && !createScheduleAPI.error && createScheduleAPI.value) {
      toast.success('Successfully added schedule.');
    }
  }, [!createScheduleAPI.loading, !!createScheduleAPI.error, !!createScheduleAPI.value]);

  const toggleModal = () => {
    dispatch(toggleShowCreateScheduleModal());
  }

  const renderDriver = (driver: User): React.ReactNode => {
    const alreadyCreated = schedules.find(s => s.driverId === driver.id);

    const onAdd = () => {
      if (createScheduleAPI.loading) return;

      dispatch(createSchedule({
        driverId: driver.id,
        date
      }))
    }

    const onRemove = () => {
      if (createScheduleAPI.loading) return;

      if (!alreadyCreated) {
        return;
      }

      dispatch(deleteSchedule(alreadyCreated.id));
    }

    return (
      <div className='driver-container' key={driver.id}>
        <div className='driver-name'>{`${driver.firstName} ${driver.lastName}`}</div>
        {alreadyCreated ? (
          <div className='driver-action' onClick={onRemove}>Remove</div>
        ) : (
          <div className='driver-action' onClick={onAdd}>Add</div>
        )}
      </div>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      style={modalStyles}
    >
      <div className='create-schedule-modal-component'>
        <Close className='close-button' onClick={toggleModal}/>

        <div className='title-container'>
          <div className='title'>Create Schedule</div>
          {createScheduleAPI.loading && <Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/>}
        </div>

        <div className='drivers'>
          {drivers.map(renderDriver)}
        </div>
      </div>
    </Modal>
  );
}
