import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { stat } from 'fs/promises';
import { PermanentRentalTemplateForm, triplePSDatasource } from '../../datasource';
import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult } from '../../lib/redux';
import { PermanentRentalTemplate } from '../../models';
import { logout } from '../Auth/authSlice';

export interface PermanentRentalTemplateState {
    getPermanentRentalTemplates: APIResult<PermanentRentalTemplate[]>;
    deletePermanentRentalTemplate: APIResult<string>;
    createPermanentRentalTemplate: APIResult<PermanentRentalTemplate>;

    templates: PermanentRentalTemplate[];
    showDeletePermanentRentalConfirmationModal?: PermanentRentalTemplate;
    templateToRemove?: string;
    showAddPermanentRentalModal: boolean;
    createOrderPermanentRental?: PermanentRentalTemplate;
}

const initialState: PermanentRentalTemplateState = {
    getPermanentRentalTemplates: emptyAPIResult(),
    deletePermanentRentalTemplate: emptyAPIResult(),
    createPermanentRentalTemplate: emptyAPIResult(),

    templates: [],
    showAddPermanentRentalModal: false
}

export const getPermanentRentalTemplates = createAsyncThunk(
    'permanentRentals/getPermanentRentalTemplates',
    async () => {
        return triplePSDatasource.getPermanentRentalTemplates();
    }
);

export const deletePermanentRentalTemplate = createAsyncThunk(
  'permanentRentals/deletePermanentRentalTemplate',
  async (id: string) => {
    return triplePSDatasource.deletePermanentRentalTemplate(id);
  }
)

export const createPermanentRentalTemplate = createAsyncThunk(
  'permanentRentals/createPermanentRentalTemplate',
  async (form: PermanentRentalTemplateForm) => {
    return triplePSDatasource.createPermanentRentalTemplate(form);
  }
)

export const permanentRentalsSlice = createSlice({
  name: 'permanentRentals',
  initialState,
  reducers: {
    setTemplateToRemove(state, action) { state.templateToRemove = action.payload },
    toggleShowAddPermanentRentalModal(state) { state.showAddPermanentRentalModal = !state.showAddPermanentRentalModal },
    showDeletePermanentRentalConfirmationModal(state, action) { state.showDeletePermanentRentalConfirmationModal = action.payload },
    resetShowDeletePermanentRentalConfirmationModal(state) { state.showDeletePermanentRentalConfirmationModal = undefined },
    setCreateOrderPermanentRental(state, action) { state.createOrderPermanentRental = action.payload }
  },
  extraReducers: builder => {
    builder
      .addCase(getPermanentRentalTemplates.pending, state => {
        state.getPermanentRentalTemplates = loadingAPIResult();
      })
      .addCase(getPermanentRentalTemplates.fulfilled, (state, action) => {
        state.getPermanentRentalTemplates = successAPIResult(action.payload);
        state.templates = action.payload;
      })
      .addCase(getPermanentRentalTemplates.rejected, (state, action) => {
        state.getPermanentRentalTemplates = errorAPIResult(action.error);
      })
      .addCase(deletePermanentRentalTemplate.pending, state => {
        state.deletePermanentRentalTemplate = loadingAPIResult();
      })
      .addCase(deletePermanentRentalTemplate.fulfilled, (state, action) => {
        state.deletePermanentRentalTemplate = successAPIResult(action.payload);

        state.templates = state.templates ? state.templates.filter(t => t.id !== action.payload) : [];
        state.templateToRemove = undefined;
      })
      .addCase(deletePermanentRentalTemplate.rejected, (state, action) => {
        state.deletePermanentRentalTemplate = errorAPIResult(action.error);
      })
      .addCase(createPermanentRentalTemplate.pending, state => {
        state.createPermanentRentalTemplate = loadingAPIResult();
      })
      .addCase(createPermanentRentalTemplate.fulfilled, (state, action) => {
        state.createPermanentRentalTemplate = successAPIResult(action.payload);
        state.templates.push(action.payload);
      })
      .addCase(createPermanentRentalTemplate.rejected, (state, action) => {
        state.createPermanentRentalTemplate = errorAPIResult(action.error);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getPermanentRentalTemplates = initialState.getPermanentRentalTemplates;
          state.deletePermanentRentalTemplate = initialState.deletePermanentRentalTemplate;
          state.createPermanentRentalTemplate = initialState.createPermanentRentalTemplate;

          state.templates = initialState.templates;
          state.templateToRemove = undefined;
          state.showAddPermanentRentalModal = initialState.showAddPermanentRentalModal;
          state.showDeletePermanentRentalConfirmationModal = undefined;
        }
      )
  }
});

export const { setTemplateToRemove, toggleShowAddPermanentRentalModal, showDeletePermanentRentalConfirmationModal, resetShowDeletePermanentRentalConfirmationModal, setCreateOrderPermanentRental } = permanentRentalsSlice.actions;
export default permanentRentalsSlice.reducer;
