import React from 'react';
import Modal from 'react-modal';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setPunchCardToUpdate } from '../../punchCardSlice';
import { EditPunchCardForm } from '../../components';

import { ReactComponent as Close } from '../../../../assets/images/close.svg';
import './EditPunchCardModal.scss';

const modalStyles = {
  overlay: {
    background: "#131313B3"
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF",
    padding: 0
  },
};

export function EditPunchCardModal() {
  const dispatch = useAppDispatch();

  const punchCardToUpdate = useAppSelector(state => state.punchCards.punchCardToUpdate);
  const updatePunchCardAPI = useAppSelector(state => state.punchCards.updatePunchCardAPI);

  const isOpen = !!punchCardToUpdate;

  React.useEffect(() => {
    if (!updatePunchCardAPI.loading && !!updatePunchCardAPI.value) {
      onClose();
    }
  }, [!updatePunchCardAPI.loading, !!updatePunchCardAPI.value]);

  const onClose = () => {
    dispatch(setPunchCardToUpdate(undefined));
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
      ariaHideApp={false}
    >
      <div className='edit-punch-card-modal-component'>
        <div onClick={onClose} className='close-button'>
          <Close />
        </div>

        <div className='title'>Update Punch Card</div>

        <EditPunchCardForm punchCard={punchCardToUpdate}/>
      </div>
    </Modal>
  );
}
