import { TriplePServiceDatasource, TriplePServiceDatasourceConfig } from './TriplePService/TriplePServiceDatasource';
import { Auth0Datasource, Auth0DatasourceConfig } from './Auth0/Auth0Datasource';

export * from './TriplePService/TriplePInterface';
export * from './Auth0/Auth0DatasourceInterface';

const triplePSDatasourceConfig: TriplePServiceDatasourceConfig = {
  baseURL: process.env.REACT_APP_TRIPLEP_API_URL ?? ''
};
export const triplePSDatasource = new TriplePServiceDatasource(triplePSDatasourceConfig);

const auth0DatasourceConfig: Auth0DatasourceConfig = {
  baseURL: process.env.REACT_APP_AUTH0_URL ?? ''
};
export const auth0Datasource = new Auth0Datasource(auth0DatasourceConfig);

