import { PunchCardsForAllDrivers } from '../../../models';
import { getPunchCardsForDriver } from './getPunchCardsForDriver';
import { getUserJSON } from './getUser';

export const getPunchCardSummary = (data: any): PunchCardsForAllDrivers => {
  return {
    driver: getUserJSON(data.driver),
    summary: getPunchCardsForDriver(data.summary)
  };
}

export const getPunchCardSummaries = (data: any): PunchCardsForAllDrivers[] => {
  return data.map(getPunchCardSummary);
}
