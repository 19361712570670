import React from 'react';
import { useTable, Column, useSortBy } from 'react-table';
import { BsPlusCircle, BsFilter, BsSearch, BsTrash, BsArrowUpRightSquare, BsArrowClockwise, BsSortDown, BsSortUp, BsPencil, BsClipboardPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Order, OrderStatus } from '../../../models';
import { getOrders, refreshOrders, setShowUpdateOrderModal, setShowDeleteOrderModal, setShowPickupInstructionsModal } from '../ordersSlice';
import { FiltersModal } from '../FiltersModal/FiltersModal';
import { OrderFilters } from '../../../datasource';

import './Orders.scss';

const PAGE_SIZE = 10;

type ColumnsType = Order;

export function Orders() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const orders = useAppSelector(state => state.orders.orders) || [];
  const ordersAPI = useAppSelector(state => state.orders.getOrders);
  const cancelOrderAPI = useAppSelector(state => state.cancelOrder.cancelOrder);
  const orderToCancel = useAppSelector(state => state.orders.showDeleteOrderModal);

  const [fetchOrders, setFetchOrders] = React.useState<boolean>(true);
  const [offset, setOffset] = React.useState<number>(0);
  const [search, setSearch] = React.useState<string>('');
  const [showFiltersModal, setShowFiltersModal] = React.useState(false);
  const [filters, setFilters] = React.useState<OrderFilters>({
    statuses: [OrderStatus.OrderStatusCreated]
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value);
  }
  
  const data = React.useMemo(() => {
    if (search === '') return orders;

    const searchBy = search.toLowerCase();
    return orders.filter(o => o.name.toLowerCase().includes(searchBy) ||
      o.phoneNumber.toLowerCase().includes(searchBy) ||
      o.address.toLowerCase().includes(searchBy) ||
      o.zip.toLowerCase().includes(searchBy) ||
      o.status.toLocaleLowerCase().includes(searchBy)
    );
  }, [orders, search]);

  React.useEffect(() => {
    if (!ordersAPI.loading && !!ordersAPI.value && ordersAPI.value.length === PAGE_SIZE) {
      setOffset(offset + PAGE_SIZE);
    }
  }, [ordersAPI.loading, !!ordersAPI.value]);

  React.useEffect(() => {
    // We already fetched orders
    if (fetchOrders || offset !== 0) {
      dispatch(getOrders({
        pagination: {
          offset,
          count: PAGE_SIZE
        },
        filters
      }));

      setFetchOrders(false);
    }
  }, [offset, fetchOrders]);

  const onCloseFilters = () => {
    setOffset(0);
    setFetchOrders(true);

    setShowFiltersModal(false);
  };

  const onAddOrderClick = () => {
    navigate('/create-order');
  }

  const onRefreshClick = () => {
    dispatch(refreshOrders());
    setOffset(0);
    setFetchOrders(true);
  }

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Address',
        accessor: 'address'
      },
      {
        Header: 'ZIP',
        accessor: 'zip'
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
      },
      {
        Header: 'Service Type',
        accessor: 'service'
      },
      {
        Header: 'Dumpster Size',
        accessor: 'dumpsterSize'
      },
      {
        Header: 'Material Disposing',
        accessor: 'materialDisposing'
      },
      {
        Header: 'Material Description',
        accessor: 'description'
      },
      {
        id: 'price',
        Header: 'Base Price',
        accessor: row => `$${row.price}.00`
      },
      {
        id: 'discount',
        Header: 'Discount',
        accessor: row => `$${row.discount}.00`
      },
      {
        id: 'delivery_fee',
        Header: 'Delivery Fee',
        accessor: row => `$${row.deliveryFee}.00`
      },
      {
        id: 'additionalCharges',
        Header: 'Additional Charges',
        accessor: row => `$${row.additionalCharges}.00`
      },
      {
        id: 'totalPrice',
        Header: 'Total Price',
        accessor: row => `$${row.price + row.deliveryFee + row.additionalCharges - row.discount}.00`
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt'
      },
      {
        Header: 'Delivery Date',
        accessor: 'deliveryDate'
      },
      {
        Header: 'Pickup Date',
        accessor: 'pickupDate'
      },
      {
        Header: 'Instructions',
        accessor: 'specialInstructions'
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: props => {
          const { value, row } = props;

          const onCancelOrder = () => {
            dispatch(setShowDeleteOrderModal(value));
          };

          const onEditOrder = () => {
            dispatch(setShowUpdateOrderModal(row.original));
          };

          const onAddPickupInstructions = () => {
            dispatch(setShowPickupInstructionsModal(value));
          };

          return (
            <div className='actions-cell'>
              <Link to={`/orders/${value}`} className='open-icon'><BsArrowUpRightSquare className='open-icon'/></Link>
              <div className='update-icon-container' onClick={onEditOrder}><BsPencil className='update-icon'/></div>
              {
                row.original.status === OrderStatus.OrderStatusCreated
                  ? cancelOrderAPI.loading && orderToCancel === value ? <Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/> : <BsTrash className='delete-icon' onClick={onCancelOrder}/>
                  : null
              }
              <div className='instructions-icon-container' onClick={onAddPickupInstructions}><BsClipboardPlus className='instructions-icon'/></div>
            </div>
          );
        }
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        id: 'id1',
        Header: 'ID',
        accessor: row => row.id
      },
      {
        Header: 'PO',
        accessor: 'po'
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data, initialState: { sortBy: [{ id: 'name', desc: false }] } }, useSortBy);

  return (
    <div className='orders-component'>
      <div className='controllers-container'>
        <div className='left-controllers-container'>
          <div className='title-container'>
            <div className='title-text'>Orders</div>
            <div className='title-count'>{data.length}</div>
          </div>

          <div className='search-container'>
            <BsSearch className='search-icon'/>
            <input
              value={search}
              placeholder='Search'
              onChange={onInputChange}
            />
          </div>

          <div className='refresh-button' onClick={onRefreshClick}>
            <BsArrowClockwise />
          </div>

          <div className='filter-button' onClick={() => setShowFiltersModal(true)}>
            <BsFilter className='icon'/>  
            Filter
          </div>
        </div>

        <div className='add-button' onClick={onAddOrderClick}>
          <BsPlusCircle className='icon'/>  
          Add Order
        </div>
      </div>

      {offset === 0 && ordersAPI.loading && <Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/>}

      <div className='table-container'>
        <table {...getTableProps()} className='tp-table'>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className='header-cell'>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <BsSortDown className='sorting-icon'/>
                          : <BsSortUp className='sorting-icon'/>
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <FiltersModal isOpen={showFiltersModal} onClose={onCloseFilters} filters={filters} setFilters={setFilters}/>
    </div>
  );
}
