import Modal from 'react-modal';
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { deleteDumpYardTemplate, resetDeleteDumpYardTemplateConfirmationModal, setTemplateToRemove, showDeleteDumpYardTemplateConfirmationModal } from "../dumpYardsSlice";
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { Oval } from 'react-loader-spinner';

import './DeleteDumpYardConfirmationModal.scss'
import toast from 'react-hot-toast';

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: "#FFFFFF"
    },
  };

export const DeleteDumpYardConfirmationModal = () => {
    const dispatch = useAppDispatch();

    const showDeleteDumpYardConfirmationModel = useAppSelector(state => state.dumpYards.showDeleteDumpYardConfirmationModal);
    const deleteDumpYardAPI = useAppSelector(state => state.dumpYards.deleteDumpYardTemplate);

    const isOpen = showDeleteDumpYardConfirmationModel ? true : false;

    React.useEffect(() => {
        if (!deleteDumpYardAPI.loading && !!deleteDumpYardAPI.error) {
            toast.error('Something went wrong. Please try again.');
        }
      
        if (!deleteDumpYardAPI.loading && !deleteDumpYardAPI.error && deleteDumpYardAPI.value) {
            toast.success('Successfully removed dump yard template.');
            dispatch(resetDeleteDumpYardTemplateConfirmationModal());
        }
    }, [!!deleteDumpYardAPI.error, !!deleteDumpYardAPI.value, !!deleteDumpYardAPI.loading]);

    const cancelDeletion = () => {
        dispatch(resetDeleteDumpYardTemplateConfirmationModal());
    }

    const confirmDeletion = () => {
        if (!!showDeleteDumpYardConfirmationModel) {
            dispatch(setTemplateToRemove(showDeleteDumpYardConfirmationModel.id));
            dispatch(deleteDumpYardTemplate(showDeleteDumpYardConfirmationModel.id));
        }
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={cancelDeletion} style={modalStyles}>
            <div className='delete-dump-yard-modal-container'>
                <Close className='close-button' onClick={cancelDeletion}/>

                <div className='title'>Would you like to proceed?</div>

                <div className='buttons-container'>
                    <button type='submit' className='button secondary-button' disabled={deleteDumpYardAPI.loading} onClick={cancelDeletion}>
                        Cancel
                    </button>
                    <button type='submit' className='button' disabled={deleteDumpYardAPI.loading} onClick={confirmDeletion}>
                        Delete
                        {deleteDumpYardAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
