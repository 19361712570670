import React from 'react';
import { useTable, Column } from 'react-table';
import moment from 'moment';

import { useAppDispatch } from '../../../../app/hooks';
import { PunchCard, PunchCardsForDriver, getWorkTime } from '../../../../models';
import { setPunchCardToUpdate } from '../../punchCardSlice';

import './DriverPunchCardsTable.scss';

type ColumnsType = PunchCard;

export interface DriverPunchCardsTableProps {
  summary: PunchCardsForDriver;
}

export function DriverPunchCardsTable(props: DriverPunchCardsTableProps) {
  const dispatch = useAppDispatch();

  const { summary } = props;

  const renderActionsCell = (props: any) => {
    return (
      <div className='view-button' onClick={onEditClick(props.row.original)}>Edit</div>
    );
  };

  const onEditClick = (punchCard: PunchCard) => () => {
    dispatch(setPunchCardToUpdate(punchCard));
  }

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: cell =>  moment(cell.date).format('ll')
      },
      {
        Header: 'Punch in',
        accessor: cell => moment(cell.punchIn).format('LT')
      },
      {
        Header: 'Punch out',
        accessor: cell => `${cell.punchOut ? moment(cell.punchOut).format('LT') : 'NA'}`
      },
      {
        Header: 'On clock',
        accessor: cell => getWorkTime(cell)
      },
      {
        Header: 'Actions',
        width: 80,
        maxWidth: 80,
        Cell: renderActionsCell
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: summary.punchCards });

  if (summary.punchCards.length === 0) return (
    <div className='driver-punch-cards-table-component empty-state'>
      <div className='text'>There are no punch cards.</div>
    </div>
  );

  return (
    <div className='driver-punch-cards-table-component'>
      <table {...getTableProps()} className='new-table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}>
                  <div className='header-cell'>
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}
