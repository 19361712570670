import React from 'react';

import { InvoicesTable } from '../InvoicesTable/InvoicesTable';

import './InvoicesCard.scss';

export function InvoicesCard() {
  return (
    <div className='invoices-card-component'>
      <div className='title'>Invoices</div>

      <InvoicesTable />
    </div>
  );
}
