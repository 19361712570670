import { Entity } from './Entity';

export interface DumpYardTemplate extends Entity {
  address: string;
  zip: string;
  name: string;
  latitude: number;
  longitude: number;
  isDumpYard: boolean;
  isStorage: boolean;
  isQuickStop: boolean;
}

export enum DumpYardTemplateType {
  DUMP_YARD = 'dump_yard',
  STORAGE = 'storage',
  QUICK_STOP = 'quick_stop'
}
