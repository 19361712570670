import { DumpYard } from '../../../models';

export const getDumpYard = (data: any): DumpYard => {
  return {
    id: data.id,
    address: data.attributes.address,
    zip: data.attributes.zip,
    templateId: data.attributes.template_id,
    date: data.attributes.date,
    status: data.attributes.status,
    name: data.attributes.name,
    instructions: data.attributes.instructions,
  };
}

export const getDumpYards = (data: any): DumpYard[] => {
  return data.map(getDumpYard);
}
