import React from 'react';
import Modal from 'react-modal';
import { Field, Form, Formik } from 'formik';
import { Oval } from 'react-loader-spinner';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { toggleShowAddDriverModal, addDriver } from '../driversSlice';
import { ReactComponent as Close } from '../../../assets/images/close.svg';

import './AddDriverModal.scss';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF"
  },
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required')
});

export interface AddDriverFormModel {
  email: string;
};

const initialValues: AddDriverFormModel = {
  email: ''
};

export function AddDriverModal() {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(state => state.drivers.showAddDriverModal);
  const isLoading = useAppSelector(state => state.drivers.addDriver.loading);
  const error = useAppSelector(state => state.drivers.addDriver.error);
  const driver = useAppSelector(state => !!state.drivers.addDriver.value);

  React.useEffect(() => {
    if (!isLoading && !!error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!isLoading && !error && driver) {
      toast.success('Successfully added driver.');
      dispatch(toggleShowAddDriverModal());
    }
  }, [driver, error]);

  const toggleModal = () => {
    dispatch(toggleShowAddDriverModal());
  }

  const onSubmit = (form: AddDriverFormModel) => {
    dispatch(addDriver(form.email));
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      style={modalStyles}
    >
      <div className='add-driver-modal-component'>
        <Close className='close-button' onClick={toggleModal}/>

        <div className='title'>Add Driver</div>
        
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form className='add-driver-form'>
              <div className='field-container'>
                <div className='label'>Email:</div>
                <Field id='email' name='email' className='text-input'/>
                {errors.email && touched.email && <div className='form-error'>{errors.email}</div>}
              </div>

              <button type='submit' className='add-driver-button'  disabled={isLoading}>
                Add Driver
                {isLoading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
              </button>

              {!!error && (
                <div className='error-text'>
                  Something went wrong. Please try again.
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
