import React from 'react';
import { useTable, Column } from 'react-table';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateScheduleItemOrder } from '../driversSlice';
import { DriversScheduleItem, ScheduleItemType } from '../../../models';
import { ScheduleTableItem } from './ScheduleTableItem';

import './ScheduleTable.scss';

type ColumnsType = DriversScheduleItem;

export function ScheduleTable() {
  const dispatch = useAppDispatch();

  const schedule = useAppSelector(state => state.drivers.schedule);

  const renderTypeTag = (type: string): JSX.Element => {
    const text = type === ScheduleItemType.DELIVERY
      ? 'Delivery'
      : type === ScheduleItemType.PICK_UP
        ? 'Pick Up'
        : 'Dump Yard';

    const colorClass = type === ScheduleItemType.DELIVERY
    ? 'delivery'
    : type === ScheduleItemType.PICK_UP
      ? 'pick-up'
      : 'dump-yard';

    return (
      <div className={`type-tag ${colorClass}`}>{text}</div>
    );
  }

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Order',
        accessor: 'order',
        Cell: props => {
          const hiddenUp = props.value === '1';
          const hiddenDown = parseInt(props.value, 10) === schedule?.items.length;

          const onUpClick = () => {
            if (!hiddenDown) {
              dispatch(updateScheduleItemOrder({
                id: props.row.original.scheduleItemId,
                newOrder: parseInt(props.value, 10) + 1
              }));
            }
          }

          const onDownClick = () => {
            if (!hiddenUp) {
              dispatch(updateScheduleItemOrder({
                id: props.row.original.scheduleItemId,
                newOrder: parseInt(props.value, 10) - 1
              }));
            }
          }

          return (
            <div className='order-cell-container'>
              <BsChevronDown className={`icon ${hiddenDown ? 'hidden' : ''}`} onClick={onUpClick}/>
              <div className='value'>{props.value}</div>
              <BsChevronUp className={`icon ${hiddenUp ? 'hidden' : ''}`} onClick={onDownClick}/> 
            </div>
          );
        }
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: props => renderTypeTag(props.value)
      },
      {
        Header: 'Name',
        accessor: 'contactName',
      },
      {
        Header: 'Phone',
        accessor: 'contactPhone',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'ZIP',
        accessor: 'zip'
      },
      {
        Header: 'Service',
        accessor: 'service',
        Cell: props => {
          const text = props.value === 'dumpster' ? 'Dumpster Rental' : props.value === 'junk' ? 'Junk Removal' : '';

          return <div>{text}</div>
        }
      },
      {
        Header: 'Dumpster Size',
        accessor: 'dumpsterSize',
        Cell: props => props.value ? <div className='dumpster-size-tag'>{props.value}</div> : null
      },
      {
        Header: 'Material Disposing',
        accessor: 'materialDisposing'
      },
      {
        Header: 'Special Instructions',
        accessor: 'specialInstructions'
      },
    ],
    [schedule]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: schedule?.items || [] });

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='table-container drivers-schedule-table'>
        <table {...getTableProps()} className='tp-table'>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    <div className='header-cell'>
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);

              return <ScheduleTableItem row={row}/>;
            })}
          </tbody>
        </table>
      </div>
    </DndProvider>
  );
}
