import React from 'react';
import { BsFileEarmarkText, BsArrowRight, BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { store } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';
import { isAdminSelector } from '../../Auth/authSlice';

import './OrdersWidget.scss';

export function OrdersWidget() {
  const navigate = useNavigate();

  const orderStats = useAppSelector(state => state.orders.dashboardOrderStats);

  const isAdmin = isAdminSelector(store.getState());

  const onWidgetClick = () => {
    navigate('/orders', { replace: true });
  };

  const onAddOrder = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    navigate('/create-order');
  };

  return (
    <div className='orders-widget-component' onClick={onWidgetClick}>
      <div className='title-container'>
        <div className='title'>Orders</div>
        <BsArrowRight />
      </div>

      <BsFileEarmarkText className='icon'/>

      {isAdmin && (
        <div className='totals-container'>
          <div className='title-total' style={{ marginRight: 8 }}>Today: <span className='title-total-count'>{orderStats?.todayCount}</span></div>
          <div className='title-total'>This Month: <span className='title-total-count'>{orderStats?.thisMonthCount}</span></div>
        </div>
      )}

      <div className='cta-container'>
        <div className='add-order-button' onClick={onAddOrder}>
          <BsPlusCircle style={{ marginRight: 4 }}/>
          Add Order
        </div>
      </div>
    </div>
  );
}
