import React from 'react';
import { BsCardList, BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../app/hooks';

import './ReceivedOrdersWidget.scss';

export function ReceivedOrdersWidget() {
  const navigate = useNavigate();

  const ordersDashboard = useAppSelector(state => state.receivedOrders.ordersDashboard);

  const onWidgetClick = () => {
    navigate('/received_orders', { replace: true });
  };

  return (
    <div className='received-orders-widget-component' onClick={onWidgetClick}>
      <div className='title-container'>
        <div className='title'>Received Orders</div>
        <BsArrowRight />
      </div>

      <BsCardList className='icon'/>

      <div className='title-total'>Total: <span className='title-total-count'>{ordersDashboard ? ordersDashboard.received : 'NA'}</span></div>

      <div className='cta-container'/>
    </div>
  );
}
