import Modal from 'react-modal';
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { removeDriver, resetShowDeleteDriverConfirmationModal, setDriverToRemove } from "../driversSlice";
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { Oval } from 'react-loader-spinner';

import './DeleteDriverConfirmationModal.scss'
import toast from 'react-hot-toast';

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: "#FFFFFF"
    },
  };

export const DeleteDriverConfirmationModal = () => {
    const dispatch = useAppDispatch();

    const showDeleteDriverConfirmationModal = useAppSelector(state => state.drivers.showDeleteDriverConfirmationModal);
    const deleteDriverAPI = useAppSelector(state => state.drivers.removeDriver);

    const isOpen = showDeleteDriverConfirmationModal ? true : false;

    React.useEffect(() => {
        if (!deleteDriverAPI.loading && !!deleteDriverAPI.error) {
            toast.error('Something went wrong. Please try again.');
        }
      
        if (!deleteDriverAPI.loading && !deleteDriverAPI.error && deleteDriverAPI.value) {
            toast.success('Successfully removed driver.');
            dispatch(resetShowDeleteDriverConfirmationModal());
        }
    }, [!!deleteDriverAPI.error, !!deleteDriverAPI.value, !!deleteDriverAPI.loading]);

    const cancelDeletion = () => {
        dispatch(resetShowDeleteDriverConfirmationModal());
    }

    const confirmDeletion = () => {
        if (!!showDeleteDriverConfirmationModal) {
            dispatch(setDriverToRemove(showDeleteDriverConfirmationModal.id));
            dispatch(removeDriver(showDeleteDriverConfirmationModal.id));
        }
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={cancelDeletion} style={modalStyles}>
            <div className='delete-driver-modal-container'>
                <Close className='close-button' onClick={cancelDeletion}/>

                <div className='title'>Would you like to proceed?</div>

                <div className='buttons-container'>
                    <button type='submit' className='button secondary-button' disabled={deleteDriverAPI.loading} onClick={cancelDeletion}>
                        Cancel
                    </button>
                    <button type='submit' className='button' disabled={deleteDriverAPI.loading} onClick={confirmDeletion}>
                        Delete
                        {deleteDriverAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
