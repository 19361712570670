import React from 'react';
import { Oval } from 'react-loader-spinner';

import { useAppSelector } from '../../../../app/hooks';
import { PunchCardsTable } from '../PunchCardsTable/PunchCardsTable';
import { DateRangePicker } from '../../../../components';
import { PunchCardsForAllDrivers } from '../../../../models';

import './PunchCardsCard.scss';

export interface PunchCardsCardProps {
  fromDate: moment.Moment;
  setFromDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  toDate: moment.Moment;
  setToDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  viewDriverDetails: React.Dispatch<React.SetStateAction<PunchCardsForAllDrivers | undefined>>;
}

export function PunchCardsCard(props: PunchCardsCardProps) {
  const { fromDate, setFromDate, toDate, setToDate, viewDriverDetails } = props; 

  const getPunchCardSummariesAPI = useAppSelector(state => state.punchCards.getPunchCardSummariesAPI);
  const refreshPunchCardSummariesAPI = useAppSelector(state => state.punchCards.refreshPunchCardSummariesAPI);

  const isLoading = getPunchCardSummariesAPI.loading || refreshPunchCardSummariesAPI.loading;

  return (
    <div className='punch-cards-card-component'>
      <div className='title-container'>
        <div className='left-container'>
          <div className='title'>Punch Cards</div>
          {isLoading && <Oval color="#0A60BB" height={20} width={20} secondaryColor="#B3B3B3"/>}
        </div>

        <div className='right-container'>
          <DateRangePicker fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate}/>
        </div>
      </div>
      
      <PunchCardsTable onViewDriverDetails={viewDriverDetails}/>
    </div>
  );
}
