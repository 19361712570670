import React from 'react';
import { Oval } from 'react-loader-spinner';

import { AdminDashboard } from './AdminDashboard/AdminDashboard';
import { ClientDashboard } from '../ClientDashboard';
import { isAdminSelector, isClientSelector } from '../Auth/authSlice';
import { store } from '../../app/store';
import { useAppSelector } from '../../app/hooks';

export function Dashboard() {
  const isLoading = useAppSelector(state => state.auth.getProfile.loading);

  const isAdmin = isAdminSelector(store.getState());
  const isClient = isClientSelector(store.getState());

  if (isLoading) {
    return <div style={{ marginLeft: 8, alignSelf: 'center', marginTop: '60px'}}><Oval color="#131313" height={60} width={60} secondaryColor="#FBD20A"/></div>
  }

  if (isAdmin) {
    return <AdminDashboard />;
  }

  if (isClient) {
    return <ClientDashboard />
  }

  return null;
}
