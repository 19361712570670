import { ScheduleItem } from '../../../models';

export const getScheduleItem = (data: any): ScheduleItem => {
  return {
    id: data.id,
    scheduleId: data.attributes.schedule_id,
    orderNumber: data.attributes.order_number,
    type: data.attributes.type,
    deliveryId: data.attributes.delivery_id,
    pickupId: data.attributes.pickup_id,
    dumpYardId: data.attributes.dump_yard_id
  };
}

export const getScheduleItems = (data: any): ScheduleItem[] => {
  return data.map(getScheduleItem);
}
