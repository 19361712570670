import { DriversSchedule, DriversScheduleItem } from '../../../models';

export const getDriversSchedule = (data: any): DriversSchedule => {
  return {
    scheduleId: data.schedule_id,
    items: getDriversScheduleItems(data.schedule_items)
  };
}

export const getDriversScheduleItem = (data: any): DriversScheduleItem => {
  return {
    order: data.order,
    address: data.address,
    zip: data.zip,
    type: data.type,
    dumpsterSize: data.dumpster_size,
    materialDisposing: data.material_disposing,
    specialInstructions: data.special_instructions,
    service: data.service,
    scheduleItemId: data.schedule_item_id,
    contactName: data.contact_name,
    contactPhone: data.contact_phone
  };
}

export const getDriversScheduleItems = (data: any): DriversScheduleItem[] => {
  return data.map(getDriversScheduleItem);
}
