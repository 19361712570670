import moment from 'moment';

import { Entity } from './Entity';

export interface PunchCard extends Entity {
  driverId: string;
  date: string;
  punchIn: string;
  punchOut?: string;
}

export const isClockedOut = (pc?: PunchCard): boolean => {
  return !!pc && !!pc.punchOut;
}


export const getWorkTime = (pc?: PunchCard): string => {
  if (!pc?.punchIn) return 'NA';

  const currentTime = isClockedOut(pc) ? moment(pc.punchOut) : moment();
  const diff = currentTime.diff(moment(pc.punchIn), 'minutes');

  const hours = Math.floor(diff / 60);
  const minutes = diff % 60;

  return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
}
