import { User } from '../../../models';

export const getUserProfile = (data: any): User => {
  return {
    id: data.user.id,
    email: data.user.email,
    auth0Id: data.user.auth0_id,
    firstName: data.user.first_name,
    lastName: data.user.last_name,
    phoneNumber: data.user.phone_number,
    roles: data.roles
  };
}

export const getUser = (data: any): User => {
  return {
    id: data.id,
    email: data.attributes.email,
    auth0Id: data.attributes.auth0_id,
    firstName: data.attributes.first_name,
    lastName: data.attributes.last_name,
    phoneNumber: data.attributes.phone_number,
    roles: []
  };
}

export const getUsers = (data: any): User[] => {
  return data.map(getUser);
}

export const getUserJSON = (data: any): User => {
  return {
    id: "",
    email: data.email,
    auth0Id: "",
    firstName: data.first_name,
    lastName: data.last_name,
    phoneNumber: data.phone_number,
    roles: []
  };
}
