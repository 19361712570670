import React from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { ProfileInfoCard, ActiveOrdersCard, PendingOrdersCard } from '../../components';

import './ClientDashboard.scss';

export function ClientDashboard() {
  const customer = useAppSelector(state => state.auth.user);

  if (!customer) {
    return null;
  }

  return (
    <div className='client-dashboard-component'>
      <ProfileInfoCard customer={customer}/>

      <ActiveOrdersCard />
      <PendingOrdersCard />
    </div>
  );
}
