import { formatNumber, parse } from 'libphonenumber-js';

import { CreateOrderParams, UpdateOrderFormModel } from '../TriplePInterface';
import { ServiceType } from '../../../models';

export interface OrderPayload {
  data: {
    type: string;
    id?: string;
    attributes: {
      id?: string;
      user_id?: string;
      name: string;
      address: string;
      zip: string;
      phone_number: string;
      email: string;
      material_disposing?: string;
      description?: string;
      special_instructions?: string;
      service: string;
      dumpster_size?: string;
      delivery_date: string;
      pick_up_date?: string;
      status: string;
      price?: number;
      delivery_fee?: number;
      permanent_rental_id?: string;
      po?: string;
      discount?: number;
      payment_type?: string;
      additional_charges: number;
    }
  }
}

export interface CreateOrderPayload {
  order: {
    id?: string;
    user_id?: string;
    name: string;
    address: string;
    zip: string;
    phone_number: string;
    email: string;
    material_disposing?: string;
    description?: string;
    special_instructions?: string;
    service: string;
    dumpster_size?: string;
    delivery_date: string;
    pick_up_date?: string;
    status: string;
    price?: number;
    delivery_fee?: number;
    permanent_rental_id?: string;
    po?: string;
    discount?: number;
  };
  signature?: string;
}

export const toOrder = (params: CreateOrderParams): CreateOrderPayload => {
  return {
    order: {
      user_id: params.order.userId,
      name: params.order.name,
      address: params.order.address,
      zip: params.order.zip + "",
      phone_number: formatNumber(parse(params.order.phoneNumber, 'US'), 'E.164'),
      email: params.order.email,
      service: params.order.service,
      delivery_date: params.order.deliveryDate,
      dumpster_size: params.order.dumpsterSize,
      material_disposing: params.order.materialDisposing,
      description: params.order.description,
      special_instructions: params.order.specialInstructions,
      status: params.order.status,
      price: params.order.service === ServiceType.JUNK ? +params.order.price : undefined,
      delivery_fee: +params.order.deliveryFee,
      permanent_rental_id: params.order.permanentRentalId,
      po: params.order.po,
      discount: +params.order.discount
    },
    signature: params.signature
  };
}

export const toOrderFromUpdate = (form: UpdateOrderFormModel): OrderPayload => {
  return {
    data: {
      type: 'orders',
      id: form.id,
      attributes: {
        user_id: form.userId,
        name: form.name,
        address: form.address,
        zip: form.zip + "",
        phone_number: formatNumber(parse(form.phoneNumber, 'US'), 'E.164'),
        email: form.email,
        service: form.service,
        delivery_date:form.deliveryDate,
        dumpster_size: form.dumpsterSize,
        material_disposing: form.materialDisposing,
        description: form.description,
        special_instructions: form.specialInstructions,
        status: form.status,
        pick_up_date: form.pickupDate,
        price: +form.price,
        delivery_fee: +form.deliveryFee,
        permanent_rental_id: form.permanentRentalId,
        po: form.po,
        discount: +form.discount,
        payment_type: form.paymentType,
        additional_charges: form.additionalCharges
      }
    }
  };
}
