import React from 'react';
import moment from 'moment';
import { BsChevronDown, BsChevronUp, BsChevronLeft, BsChevronRight, BsPlusCircle } from 'react-icons/bs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Oval } from 'react-loader-spinner';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getSchedules, toggleShowCreateScheduleModal, setDateMoment as reduxSetDate, getUncompletedDumpYardsCount } from '../scheduleSlice';
import { getDeliveries } from '../../Deliveries/deliveriesSlice';
import { getPickups } from '../../Pickups/pickUpsSlice';
import { getDumpYards, refreshDumpYards } from '../../DumpYards/dumpYardsSlice';
import { ScheduleDriverWidget } from '../ScheduleDriverWidget/ScheduleDriverWidget';
import { DeliveryItem } from '../../Deliveries';
import { PickupItem } from '../../Pickups';
import { DumpYardTemplateItem } from '../../DumpYards';
import { DeliveryStatus, DumpYardStatus, PickUpStatus, ScheduleItemType } from '../../../models';
import { ScheduleViewToggle, MapView } from '../components';

import './Schedules.scss';

export function Schedules() {
  const dispatch = useAppDispatch();

  const schedules = useAppSelector(state => state.schedules.schedules);
  const deliveries = useAppSelector(state => state.deliveries.deliveries);
  const pickups = useAppSelector(state => state.pickups.pickups);
  const dumpYardTemplates = useAppSelector(state => state.dumpYards.templates.filter(t => t.isDumpYard));
  const storageTemplates = useAppSelector(state => state.dumpYards.templates.filter(t => t.isStorage));
  const quickStopTemplates = useAppSelector(state => state.dumpYards.templates.filter(t => t.isQuickStop));
  const createScheduleItem = useAppSelector(state => state.schedules.createScheduleItem);
  const changeScheduleScheduleItemAPI = useAppSelector(state => state.schedules.changeScheduleScheduleItem);
  const uncompletedDumpYards = useAppSelector(state => state.schedules.uncompletedDumpYards);
  const getUncompletedDumpYardsCountAPI = useAppSelector(state => state.schedules.getUncompletedDumpYardsCountAPI);
  const getSchedulesAPI = useAppSelector(state => state.schedules.getSchedules);
  const getScheduleItemsAPI = useAppSelector(state => state.schedules.getScheduleItems);
  const unassignScheduleItemAPI = useAppSelector(state => state.schedules.unassignScheduleItem);
  const createScheduleItemAPI = useAppSelector(state => state.schedules.createScheduleItem);
  const changeScheduleItemOrderAPI = useAppSelector(state => state.schedules.changeScheduleItemOrder);
  const getScheduleAPI = useAppSelector(state => state.schedules.getScheduleAPI);

  const readyToScheduleDeliveries = deliveries.filter(d => d.status === DeliveryStatus.CREATED);
  const readyToSchedulePickups = pickups.filter(p => p.status === PickUpStatus.CREATED);
  const loading = getSchedulesAPI.loading || getScheduleItemsAPI.loading || unassignScheduleItemAPI.loading || createScheduleItemAPI.loading || changeScheduleScheduleItemAPI.loading || changeScheduleItemOrderAPI.loading;

  let scheduleDate = moment();
  if (parseInt(moment().format("HH")) > 16) {
    scheduleDate.add(1, 'day')
  }
  const [dateMoment, setDateMoment] = React.useState<moment.Moment>(scheduleDate);
  const [date, setDate] = React.useState<string>(dateMoment.format('dddd, Do MMMM'));
  const [collapsedDeliveries, setCollapsedDeliveries] = React.useState<boolean>(false);
  const [collapsedPickups, setCollapsedPickups] = React.useState<boolean>(false);
  const [collapsedDumpYards, setCollapsedDumpYards] = React.useState<boolean>(true);
  const [collapsedStorages, setCollapsedStorages] = React.useState<boolean>(true);
  const [collapsedQuickStops, setCollapsedQuickStops] = React.useState<boolean>(true);
  const [viewType, setViewType] = React.useState<string>('schedule');

  React.useEffect(() => {
    const dateString = dateMoment.format('YYYY-MM-DD');
    dispatch(getSchedules(dateString));
    dispatch(getDeliveries(dateString));
    dispatch(getPickups(dateString));
    dispatch(getDumpYards(dateString));

    const yesterdayDateString = dateMoment.clone().add(-1, 'day').format('YYYY-MM-DD');
    dispatch(getUncompletedDumpYardsCount({
      date: yesterdayDateString,
      status: DumpYardStatus.CANCELED
    }));
  }, [date]);

  React.useEffect(() => {
    if (!createScheduleItem.loading && !!createScheduleItem.value && createScheduleItem.value.type === ScheduleItemType.DUMP_YARD) {
      const dateString = dateMoment.format('YYYY-MM-DD');
      dispatch(getDumpYards(dateString));
    }
  }, [createScheduleItem.loading, !!createScheduleItem.value])

  React.useEffect(() => {
    // Fetch new dump yards and refresh schedules
    if (!changeScheduleScheduleItemAPI.loading && !!changeScheduleScheduleItemAPI.value && !!changeScheduleScheduleItemAPI.value.dumpYardId) {
      const dateString = dateMoment.format('YYYY-MM-DD');

      dispatch(getSchedules(dateString));
      dispatch(refreshDumpYards(dateString));
    }
  }, [changeScheduleScheduleItemAPI.loading, !!changeScheduleScheduleItemAPI.value]);

  React.useEffect(() => {
    // Fetch new dump yards
    if (!getScheduleAPI.loading && !!getScheduleAPI.value && !getScheduleAPI.value.isOptimizing) {
      const dateString = dateMoment.format('YYYY-MM-DD');

      dispatch(refreshDumpYards(dateString));
    }
  }, [getScheduleAPI.loading, !!getScheduleAPI.value]);

  const onDayBeforeClick = () => {
    setDateMoment(dateMoment.add(-1, 'day'));
    setDate(dateMoment.format('dddd, Do MMMM'));
    dispatch(reduxSetDate(dateMoment.format('YYYY-MM-DD')));
  };

  const onDayAfterClick = () => {
    setDateMoment(dateMoment.add(1, 'day'));
    setDate(dateMoment.format('dddd, Do MMMM'));
    dispatch(reduxSetDate(dateMoment.format('YYYY-MM-DD')));
  };

  const onCreateSchedule = () => {
    dispatch(toggleShowCreateScheduleModal());
  }

  const scheduledDeliveriesCount = deliveries.filter(d => d.status === DeliveryStatus.SCHEDULED).length;
  const scheduledPickupsCount = pickups.filter(p => p.status === PickUpStatus.SCHEDULED).length;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='schedules-component'>
        <div className='header-container'>
          <div className='date-container'>
            <div className='icon-container' onClick={onDayBeforeClick}>
              <BsChevronLeft className='icon'/>
            </div>
            <div className='icon-container' onClick={onDayAfterClick}>
              <BsChevronRight className='icon'/>
            </div>

            <div className='date-text'>{date}</div>
          </div>

          <ScheduleViewToggle selected={viewType} onChange={setViewType}/>

          <div className='add-button' onClick={onCreateSchedule}>
            <BsPlusCircle className='icon'/>  
            Create Schedule
          </div>
        </div>

        <div className='content-container'>
          {viewType === 'schedule'
            ? (
              <>
                <div className='schedules-container'>
                  <div className='title-container'>
                    <div className='row'>
                      <div className='title'>Schedules</div>
                      <div className='title-count'>{schedules.length}</div>
                      
                      <div style={{ marginRight: 8, marginLeft: 8 }}>Deliveries</div>
                      <div className='title-count'>{scheduledDeliveriesCount}</div>
      
                      <div style={{ marginRight: 8, marginLeft: 8  }}>Pickups</div>
                      <div className='title-count'>{scheduledPickupsCount}</div>
      
                      {loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
                    </div>
      
                    <div className='row'>
                      <div style={{ marginRight: 8 }}>Uncompleted dump yards</div>
                      {getUncompletedDumpYardsCountAPI.loading
                        ? <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>
                        : <div className='title-count'>{uncompletedDumpYards}</div>
                      }
                    </div>
                  </div>
                  <div className='schedule-items-container'>
                    {schedules.map(s => <ScheduleDriverWidget schedule={s} key={s.id}/>)}
                  </div>
                </div>

                <div className='order-items-container'>
                  <div className='deliveries-container'>
                    <div className='heading-bar'>
                      <div className='title'>Deliveries <span className='title-count'>{readyToScheduleDeliveries.length}</span></div>
                      <div className='items-collapse' onClick={ () => setCollapsedDeliveries(collapsedDeliveries ? false : true) }>
                        { collapsedDeliveries ? <BsChevronUp className='small-collapse-icon'/> : <BsChevronDown className='small-collapse-icon'/> }
                      </div>
                    </div>
                    { !collapsedDeliveries && 
                      <div className='delivery-items-container'>
                        { readyToScheduleDeliveries.map(d => <DeliveryItem delivery={d} key={d.id}/>) }
                      </div>
                    }
                  </div>

                  <div className='pick-ups-container'>
                    <div className='heading-bar'>
                      <div className='title'>Pick Ups <span className='title-count'>{readyToSchedulePickups.length}</span></div>
                      <div className='items-collapse' onClick={ () => setCollapsedPickups(collapsedPickups ? false : true) }>
                        { collapsedPickups ? <BsChevronUp className='small-collapse-icon'/> : <BsChevronDown className='small-collapse-icon'/> }
                      </div>
                    </div>
                    { !collapsedPickups &&
                      <div className='pickup-items-container'>
                        { readyToSchedulePickups.map(p => <PickupItem pickup={p} key={p.id}/>) }
                      </div>
                    }
                  </div>

                  <div className='dump-yards-container'>
                    <div className='heading-bar'>
                      <div className='title'>Dump Yards <span className='title-count'>{dumpYardTemplates.length}</span></div>
                      <div className='items-collapse' onClick={ () => setCollapsedDumpYards(collapsedDumpYards ? false : true) }>
                        { collapsedDumpYards ? <BsChevronUp className='small-collapse-icon'/> : <BsChevronDown className='small-collapse-icon'/> }
                      </div>
                    </div>
                    { !collapsedDumpYards &&
                      <div className='dump-yard-items-container'>
                        { dumpYardTemplates.map(t => <DumpYardTemplateItem template={t} key={t.id}/>) }
                      </div>
                    }
                  </div>

                  <div className='storages-container'>
                    <div className='heading-bar'>
                      <div className='title'>Storages <span className='title-count'>{storageTemplates.length}</span></div>
                      <div className='items-collapse' onClick={ () => setCollapsedStorages(collapsedStorages ? false : true) }>
                        { collapsedStorages ? <BsChevronUp className='small-collapse-icon'/> : <BsChevronDown className='small-collapse-icon'/> }
                      </div>
                    </div>
                    { !collapsedStorages &&
                      <div className='dump-yard-items-container'>
                        { storageTemplates.map(t => <DumpYardTemplateItem template={t} key={t.id}/>) }
                      </div>
                    }
                  </div>

                  <div className='quick-stops-container'>
                    <div className='heading-bar'>
                      <div className='title'>Quick Stops <span className='title-count'>{quickStopTemplates.length}</span></div>
                      <div className='items-collapse' onClick={ () => setCollapsedQuickStops(collapsedQuickStops ? false : true) }>
                        { collapsedQuickStops ? <BsChevronUp className='small-collapse-icon'/> : <BsChevronDown className='small-collapse-icon'/> }
                      </div>
                    </div>
                    { !collapsedQuickStops &&
                      <div className='dump-yard-items-container'>
                        { quickStopTemplates.map(t => <DumpYardTemplateItem template={t} key={t.id}/>) }
                      </div>
                    }
                  </div>
                </div>
              </>
            )
            : <MapView />
          }
        </div>
      </div>
    </DndProvider>
  );
}
