import React from 'react';
import { useParams } from 'react-router-dom';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { Oval } from 'react-loader-spinner';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { OrderStatus, ServiceType } from '../../../models';
import { getOrder, getInvoice, resetInvoice, setShowUpdateOrderModal, setShowDeleteOrderModal, sendConfirmationEmail, resetSendEmailConfirmation } from '../ordersSlice';
import { base64toBlobPdf } from '../../../lib/base64ToBlobPdf';

import './Order.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export function Order() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const orderId = params.id;
  const order = useAppSelector(state => state.orders.orders.find(o => o.id === orderId));
  const cancelOrder = useAppSelector(state => state.cancelOrder.cancelOrder);
  const sendConfirmationEmailAPI = useAppSelector(state => state.orders.sendConfirmationEmailAPI);

  const invoice = useAppSelector(state => state.orders.invoice);
  const invoiceAPI = useAppSelector(state => state.orders.getInvoice);
  const blob = base64toBlobPdf(invoice || '');
  const url = React.useMemo(() => URL.createObjectURL(blob), [invoice]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  React.useEffect(() => {
    if (!order && orderId) {
      dispatch(getOrder(orderId));
    }

    return () => {
      dispatch(resetInvoice());
      dispatch(resetSendEmailConfirmation());
    }
  }, []);

  React.useEffect(() => {
    if (!invoice && !!order && order.status != OrderStatus.OrderStatusReceived) {
      dispatch(getInvoice(order.id));
    }
  }, [!!order, !!invoice]);

  React.useEffect(() => {
    if (!cancelOrder.loading && !!cancelOrder.value) {
      navigate('/orders');
    }
  }, [cancelOrder.loading, !!cancelOrder.value]);

  React.useEffect(() => {
    if (!sendConfirmationEmailAPI.loading && !!sendConfirmationEmailAPI.error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!sendConfirmationEmailAPI.loading && !!sendConfirmationEmailAPI.value) {
      toast.success('Successfully send confirmation email.');
      dispatch(setShowUpdateOrderModal(undefined));
    }
  }, [sendConfirmationEmailAPI.loading, !!sendConfirmationEmailAPI.error, !!sendConfirmationEmailAPI.value]);


  const onCancelOrderClick = () => {
    dispatch(setShowDeleteOrderModal(order!.id));
  }

  const onEditOrderClick = () => {
    dispatch(setShowUpdateOrderModal(order));
  }

  const onConfirmationEmailClick = () => {
    if (!order) return;

    dispatch(sendConfirmationEmail({
      id: order.id
    }));
  }

  return (
    <div className='order-component'>
      <div className='content'>
        <div className='title'>Order Details</div>

        <div className='item-container'>
          <div className='item-label'>ID:</div>
          <div className='item-value'>{order?.id}</div>
        </div>

        <div className='item-container'>
          <div className='item-label'>Address:</div>
          <div className='item-value'>{order?.address}</div>
        </div>

        <div className='item-container'>
          <div className='item-label'>Name:</div>
          <div className='item-value'>{order?.name}</div>
        </div>

        <div className='item-container'>
          <div className='item-label'>Phone Number:</div>
          <div className='item-value'>{order?.phoneNumber}</div>
        </div>

        <div className='item-container'>
          <div className='item-label'>Email:</div>
          <div className='item-value'>{order?.email}</div>
        </div>

        <div className='item-container'>
          <div className='item-label'>Delivery date:</div>
          <div className='item-value'>{order?.deliveryDate}</div>
        </div>

        <div className='item-container'>
          <div className='item-label'>Pickup date:</div>
          <div className='item-value'>{order?.pickupDate}</div>
        </div>

        <div className='item-container'>
          <div className='item-label'>Service:</div>
          <div className='item-value'>{order?.service}</div>
        </div>

        {order?.service === ServiceType.DUMPSTER && (
          <div className='item-container'>
            <div className='item-label'>Size of dumpster:</div>
            <div className='item-value'>{order?.dumpsterSize}</div>
          </div>
        )}

        {order?.service === ServiceType.DUMPSTER && (
          <div className='item-container'>
            <div className='item-label'>Material disposing:</div>
            <div className='item-value'>{order?.materialDisposing}</div>
          </div>
        )}

        {order?.service === ServiceType.DUMPSTER && (
          <div className='item-container'>
            <div className='item-label'>Description:</div>
            <div className='item-value'>{order?.description}</div>
          </div>
        )}

        <div className='item-container'>
          <div className='item-label'>Special Instructions:</div>
          <div className='item-value'>{order?.specialInstructions}</div>
        </div>

        <div className='buttons-container'>
          <div className='button-container' onClick={onCancelOrderClick}>
            <BsTrash className='icon'/>
            <div>Cancel Order</div>
            {cancelOrder.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
          </div>

          <div className='button-container' onClick={onEditOrderClick}>
            <BsPencil className='icon'/>
            <div>Update Order</div>
          </div>

          <div className='button-container' onClick={onConfirmationEmailClick}>
            <div>Send Confirmaion Email</div>
          </div>
        </div>
      </div>

      <div className='content' style={{ marginLeft: '16px' }}>
        { !!order && order.status != OrderStatus.OrderStatusReceived && 
          <>
            {
              invoiceAPI.loading
                ? <Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/>
                : (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.js">
                      <div style={{ display: 'flex', flex: 1 }}>
                          <Viewer
                              fileUrl={url}
                              plugins={[
                                  defaultLayoutPluginInstance,
                              ]}
                          />
                      </div>
                  </Worker>
                )
            }
          </>
        }
        { !!order && order.status == OrderStatus.OrderStatusReceived &&
          <div className='no-invoice-placeholder'>
            Invoice has not yet been created.
          </div>
        }
      </div>
    </div>
  );
}
