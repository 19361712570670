import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { login, resetLogin } from '../authSlice';

export interface LinkFormModel {
  email: string;
  password: string;
}

const initialValues: LinkFormModel = {
  email: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
});

export function LinkForm() {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(state => state.auth.login.loading);
  const error = useAppSelector(state => state.auth.login.error);
  const loginSuccess = useAppSelector(state => !!state.auth.login.value);

  React.useEffect(() => {
    if (!isLoading && !!error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!isLoading && !error && loginSuccess) {
      toast.success('Successfully logged in.');
      dispatch(resetLogin());
    }
  }, [loginSuccess, error]);

  const onSubmit = (form: LinkFormModel) => {
    dispatch(login({
      email: form.email,
      password: form.password
    }));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className='register-account-form-component'>
          <div className='form-field-container'>
            <Field id='email' name='email' placeholder='Email' className='form-field-text'/>
            {errors.email && touched.email && <div className='form-error'>{errors.email}</div>}
          </div>

          <div className='form-field-container'>
            <Field id='password' name='password' placeholder='Password' type="password" className='form-field-text'/>
            {errors.password && touched.password && <div className='form-error'>{errors.password}</div>}
          </div>

          <div className='button-container'>
            <button type='submit' className='login-button' disabled={isLoading}>
              Login
              {isLoading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
            </button>

            {!!error && (
              <div className='error-text'>
                Something went wrong. Please try again.
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
