import React from 'react';
import { useTable, Column } from 'react-table';

import { useAppSelector } from '../../../../app/hooks';
import { PunchCardsForAllDrivers, getWorkTimeForDriver } from '../../../../models';

import './PunchCardsTable.scss';

type ColumnsType = PunchCardsForAllDrivers;

export interface PunchCardsTableProps {
  onViewDriverDetails:  React.Dispatch<React.SetStateAction<PunchCardsForAllDrivers | undefined>>;
}

export function PunchCardsTable(props: PunchCardsTableProps) {
  const { onViewDriverDetails } = props;

  const punchCardSummaries = useAppSelector(state => state.punchCards.punchCardSummaries);

  const renderActionsCell = (props: any) => {
    return (
      <div className='view-button' onClick={onViewDetailsClick(props.row.original)}>View Details</div>
    );
  };

  const onViewDetailsClick = (driverSummary: PunchCardsForAllDrivers) => () => {
    onViewDriverDetails(driverSummary);
  }

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Driver',
        accessor: cell => `${cell.driver.firstName} ${cell.driver.lastName}`
      },
      {
        Header: 'On clock',
        accessor: cell => getWorkTimeForDriver(cell.summary)
      },
      {
        Header: 'Actions',
        width: 80,
        maxWidth: 80,
        Cell: renderActionsCell
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: punchCardSummaries });

  if (punchCardSummaries.length === 0) return (
    <div className='punch-cards-table-component empty-state'>
      <div className='text'>There are no punch card summaries.</div>
    </div>
  );

  return (
    <div className='punch-cards-table-component'>
      <table {...getTableProps()} className='new-table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}>
                  <div className='header-cell'>
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}
