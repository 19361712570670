import React from 'react';
import { IoMdPin } from 'react-icons/io';

import './MapMarker.scss';

export interface MapMarkerProps {
  name: string;
  address: string;
  zip: string
  lat: number;
  lng: number;
  className: string;
}

export function MapMarker(props: MapMarkerProps) {
  const { name, address, zip, className } = props;

  return (
    <div className={`map-marker-component ${className}`}>
      <IoMdPin className='pin'/>
      
      <div className='text-conatiner'>
        <div className='text'>{name}</div>
        <div className='text'>{address}</div>
        <div className='text'>{zip}</div>
      </div>
    </div>
  );
}
