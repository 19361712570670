import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ConfirmationIcon } from '../../assets/images/confirmation.svg';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { useAppDispatch } from '../../app/hooks';
import { resetCancelOrder } from './cancelOrderSlice';

import './CancelOrderConfirmation.scss';

export function CancelOrderConfirmation() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(resetCancelOrder());
    }
  }, []);

  return (
    <div className='cancel-order-confirmation-component'>
      <Link to='/'>
        <Close className='close-button'/>
      </Link>

      <div className='title'>Cancel Order Confirmation</div>
      <ConfirmationIcon className='confirmation-icon'/>
    </div>
  );
}
