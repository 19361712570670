import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { GetPunchCardSummariesParams, UpdatePunchCardsParams, triplePSDatasource } from '../../datasource';
import { PunchCard, PunchCardsForAllDrivers } from '../../models';

import { logout } from '../Auth/authSlice';

export interface PunchCardState {
  getPunchCardSummariesAPI: APIResult<PunchCardsForAllDrivers[]>;
  refreshPunchCardSummariesAPI: APIResult<PunchCardsForAllDrivers[]>;
  updatePunchCardAPI: APIResult<PunchCard>;

  punchCardSummaries: PunchCardsForAllDrivers[];
  punchCardToUpdate?: PunchCard;
}

const initialState: PunchCardState = {
  getPunchCardSummariesAPI: emptyAPIResult(),
  refreshPunchCardSummariesAPI: emptyAPIResult(),
  updatePunchCardAPI: emptyAPIResult(),

  punchCardSummaries: []
}

export const getPunchCardSummaries = createAsyncThunk<PunchCardsForAllDrivers[], GetPunchCardSummariesParams,{ rejectValue: ApiError }>(
  'punchCard/getPunchCardSummaries',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.getPunchCardSummaries(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const refreshPunchCardSummaries = createAsyncThunk<PunchCardsForAllDrivers[], GetPunchCardSummariesParams,{ rejectValue: ApiError }>(
  'punchCard/refreshPunchCardSummaries',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.getPunchCardSummaries(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const updatePunchCard = createAsyncThunk<PunchCard, UpdatePunchCardsParams,{ rejectValue: ApiError }>(
  'punchCard/updatePunchCard',
  async (params: UpdatePunchCardsParams, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.updatePunchCard(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const punchCardSlice = createSlice({
  name: 'punchCard',
  initialState,
  reducers: {
    setPunchCardToUpdate(state, action) { state.punchCardToUpdate = action.payload },
    resetUpdatePunchCard(state) { state.updatePunchCardAPI = initialState.updatePunchCardAPI },
  },
  extraReducers: builder => {
    builder
      .addCase(getPunchCardSummaries.pending, state => {
        state.getPunchCardSummariesAPI = loadingAPIResult();
      })
      .addCase(getPunchCardSummaries.fulfilled, (state, action) => {
        state.getPunchCardSummariesAPI = successAPIResult(action.payload);
        state.punchCardSummaries = action.payload;
      })
      .addCase(getPunchCardSummaries.rejected, (state, action) => {
        state.getPunchCardSummariesAPI = errorAPIResult(action.payload!);
      })
      .addCase(refreshPunchCardSummaries.pending, state => {
        state.refreshPunchCardSummariesAPI = loadingAPIResult();
      })
      .addCase(refreshPunchCardSummaries.fulfilled, (state, action) => {
        state.refreshPunchCardSummariesAPI = successAPIResult(action.payload);
        state.punchCardSummaries = action.payload;
      })
      .addCase(refreshPunchCardSummaries.rejected, (state, action) => {
        state.refreshPunchCardSummariesAPI = errorAPIResult(action.payload!);
      })
      .addCase(updatePunchCard.pending, state => {
        state.updatePunchCardAPI = loadingAPIResult();
      })
      .addCase(updatePunchCard.fulfilled, (state, action) => {
        state.updatePunchCardAPI = successAPIResult(action.payload);
      })
      .addCase(updatePunchCard.rejected, (state, action) => {
        state.updatePunchCardAPI = errorAPIResult(action.payload!);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getPunchCardSummariesAPI = initialState.getPunchCardSummariesAPI;
          state.refreshPunchCardSummariesAPI = initialState.refreshPunchCardSummariesAPI;
          state.updatePunchCardAPI = initialState.updatePunchCardAPI;

          state.punchCardSummaries = initialState.punchCardSummaries;
        }
      )
  }
});

export const { setPunchCardToUpdate, resetUpdatePunchCard } = punchCardSlice.actions;
export default punchCardSlice.reducer;
