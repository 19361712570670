import React from 'react';

import { ReactComponent as PieIcon } from '../../../../assets/images/pie_icon.svg';
import './OrderHistoryOverviewCard.scss';

export function OrderHistoryOverviewCard() {
  return (
    <div className='overview-card-component'>
      <div className='icon-container'>
        <PieIcon />
      </div>

      <div className='title-container'>
        <div className='title'>Order History</div>
        <div className='subtitle'>Your completed orders</div>
      </div>
    </div>
  );
}
