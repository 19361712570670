import React from 'react';
import { BsTruck, BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { BsPlusCircle } from 'react-icons/bs';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { toggleShowAddDriverModal } from '../driversSlice';

import './DriversWidget.scss';

export function DriversWidget() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const driversLength = useAppSelector(state => state.drivers.drivers?.length);

  const onWidgetClick = () => {
    navigate('/drivers', { replace: true });
  };

  const onAddDriver = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    dispatch(toggleShowAddDriverModal());
  };

  return (
    <div className='drivers-widget-component' onClick={onWidgetClick}>
      <div className='title-container'>
        <div className='title'>Drivers</div>
        <BsArrowRight />
      </div>

      <BsTruck className='icon'/>

      {driversLength && <div className='title-total'>Total: <span className='title-total-count'>{driversLength}</span></div>}

      <div className='cta-container'>
        <div className='add-driver-button' onClick={onAddDriver}>
          <BsPlusCircle style={{ marginRight: 4 }}/>
          Add Driver
        </div>
      </div>
    </div>
  );
}
