import React from 'react';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { resetUpdatePunchCard, updatePunchCard } from '../../punchCardSlice';
import { PunchCard } from '../../../../models';

import './EditPunchCardForm.scss';
import moment from 'moment';

export interface EditPunchCardFormProps {
  punchCard?: PunchCard;
}

export function EditPunchCardForm({ punchCard }: EditPunchCardFormProps) {
  const dispatch = useAppDispatch();

  const updatePunchCardAPI = useAppSelector(state => state.punchCards.updatePunchCardAPI);

  const [punchIn, setPunchIn] = React.useState<moment.Moment | null>(moment(punchCard?.punchIn));
  const [punchOut, setPunchOut] = React.useState<moment.Moment | null>(!!punchCard?.punchOut ? moment(punchCard?.punchOut) : null);

  React.useEffect(() => {
    return () => {
      dispatch(resetUpdatePunchCard());
    }
  }, []);

  React.useEffect(() => {
    if (!updatePunchCardAPI.loading) {
      if (!!updatePunchCardAPI.error) {
        toast.error('Something went wrong. Please try again.');
      }

      if (!!updatePunchCardAPI.value) {
        toast.success('Successfully updated punch card.');
      }
    }
  }, [updatePunchCardAPI.loading, !!updatePunchCardAPI.error, !!updatePunchCardAPI.value]);

  const onUpdate = () => {
    if (!punchCard) return;

    dispatch(updatePunchCard({
      id: punchCard.id,
      punchIn: punchIn!.format('YYYY-MM-DD HH:mm'),
      punchOut: !!punchOut ? formatPunchOut(punchOut) : undefined
    }));
  };

  const formatPunchOut = (punchOut: moment.Moment): string => {
    const newPunchOut = moment(`${punchCard?.date} ${punchOut.format('HH:mm')}`);

    return newPunchOut.format('YYYY-MM-DD HH:mm');
  }

  const onPunchOutChange = (newPunchOut: moment.Moment | null) => {
    if (!newPunchOut) setPunchOut(null);

    if (!punchOut) {
      newPunchOut = moment(`${punchCard?.date} ${newPunchOut!.format('HH:mm')}`);

      if (newPunchOut.isBefore(punchIn)) {
        toast.error('Punch out must be after punch in!');
        return;
      }
    }

    setPunchOut(newPunchOut);
  }

  if (!punchCard) return null;

  return (
    <div className='edit-punch-card-form'>
      <div className='date-container'>
        <div>Date:&nbsp;</div>
        <div>{moment(punchCard.date).format('ll')}</div>
      </div>

      <div className='time-picker-container'>
        <TimePicker
          label='Punch In'
          value={punchIn}
          onChange={setPunchIn}
        />
      </div>

      <div className='time-picker-container'>
        <TimePicker
          label='Punch Out'
          value={punchOut}
          onChange={onPunchOutChange}
        />
      </div>

      {updatePunchCardAPI.loading
        ? <div className='view-button'><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div> 
        : <div className='view-button' onClick={onUpdate}>Update</div>
      }
    </div>
  );
}
