import React from 'react';
import { useTable, Column } from 'react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { useAppSelector } from '../../../../app/hooks';
import { Order, OrderStatus, orderStatusToText, dumpsterSizeToText, DumpsterSize } from '../../../../models';

import './ActiveOrdersTable.scss';

type ColumnsType = Order;

export function ActiveOrdersTable() {
  const orders = useAppSelector(state => state.clientDashboard.activeOrders);

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Address',
        accessor: cell => `${cell.address}, ${cell.zip}`,
        width: 300
      },
      {
        Header: 'Dumpster Size',
        accessor: cell => dumpsterSizeToText[cell.dumpsterSize as DumpsterSize],
        width: 150
      },
      {
        Header: 'Delivery Date',
        accessor: cell => moment(cell.deliveryDate).format('ll'),
        width: 150
      },
      {
        Header: 'Pickup Date',
        accessor: cell => moment(cell.pickupDate).format('ll'),
        width: 150
      },
      {
        Header: 'Price',
        accessor: cell => `$${cell.price + cell.additionalCharges + cell.deliveryFee - cell.discount}`,
        width: 150
      },
      {
        Header: 'Status',
        accessor: cell => orderStatusToText[cell.status as OrderStatus],
        width: 150
      },
      {
        Header: 'Actions',
        accessor: 'id',
        width: 200,
        Cell: props => {
          const { value } = props;

          return (
            <div className='actions-cell'>
              <Link to={`/customer_orders/${value}`} className='view-profile-button'>View order</Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: orders });

  if (orders.length === 0) return (
    <div className='active-orders-table-component empty-state'>
      <div className='text'>There are no active orders.</div>
    </div>
  );

  return (
    <div className='active-orders-table-component'>
      <table {...getTableProps()} className='new-table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}>
                  <div className='header-cell'>
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}
