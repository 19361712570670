import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { AddPickupInstructionsParams, OrderFilters, SendConfirmationEmailParams, TriplePPagination, triplePSDatasource, UpdateOrderFormModel } from '../../datasource';
import { DashboardOrderStats, Order, OrderStatus, PickUp } from '../../models';
import { createOrderAPI } from '../CreateOrder/createOrderSlice';
import { cancelOrderAPI } from '../CancelOrder/cancelOrderSlice';
import { logout } from '../Auth/authSlice';

export interface OrdersState {
  getOrders: APIResult<Order[]>;
  getOrder: APIResult<Order>;
  getInvoice: APIResult<string>;
  getDashboardOrderStats: APIResult<DashboardOrderStats>;
  updateOrder: APIResult<Order>;
  getPickupInstructionsAPI: APIResult<PickUp>;
  addPickupInstructionsAPI: APIResult<string>;
  sendConfirmationEmailAPI: APIResult<string>;

  orders: Order[];
  invoice?: string;
  dashboardOrderStats?: DashboardOrderStats;
  showUpdateOrderModal?: Order;
  showDeleteOrderModal?: string;
  showOrderDetailsModal?: string;
  pickupInstructions?: string;
  showPickupInstructionsModal?: string;
  signature?: string;
}

const initialState: OrdersState = {
  getOrders: emptyAPIResult(),
  getOrder: emptyAPIResult(),
  getInvoice: emptyAPIResult(),
  getDashboardOrderStats: emptyAPIResult(),
  updateOrder: emptyAPIResult(),
  getPickupInstructionsAPI: emptyAPIResult(),
  addPickupInstructionsAPI: emptyAPIResult(),
  sendConfirmationEmailAPI: emptyAPIResult(),

  orders: []
}

export const getOrders = createAsyncThunk(
  'orders/getOrders',
  async (data: { pagination: TriplePPagination, filters: OrderFilters}) => {
    return triplePSDatasource.getOrders(data.filters, data.pagination);
  }
);

export const getOrder = createAsyncThunk(
  'orders/getOrder',
  async (id: string) => {
    return triplePSDatasource.getOrder(id);
  }
);

export const updateOrder = createAsyncThunk<Order, UpdateOrderFormModel,{ rejectValue: ApiError }>(
  'orders/updateOrder',
  async (form, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.updateOrder(form);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const getInvoice = createAsyncThunk(
  'orders/getInvoice',
  async (id: string) => {
    return triplePSDatasource.getInvoice(id);
  }
);

export const getDashboardOrderStats = createAsyncThunk(
  'orders/getDashboardOrderStats',
  async () => {
    return triplePSDatasource.getOrderStats();
  }
);

export const getPickupInstructions = createAsyncThunk<PickUp, string,{ rejectValue: ApiError }>(
  'orders/getPickupInstructions',
  async (id, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.getPickupInstructions(id);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const addPickupInstructions = createAsyncThunk<string, AddPickupInstructionsParams,{ rejectValue: ApiError }>(
  'orders/addPickupInstructions',
  async (params: AddPickupInstructionsParams, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.addPickupInstructions(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const sendConfirmationEmail = createAsyncThunk<string, SendConfirmationEmailParams,{ rejectValue: ApiError }>(
  'orders/sendConfirmationEmail',
  async (params: SendConfirmationEmailParams, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.sendConfirmationEmail(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetInvoice(state) {
      state.invoice = initialState.invoice;
      state.getInvoice = initialState.getInvoice;
    },
    refreshOrders(state) {
      state.getOrders = initialState.getOrders;
      state.orders = [];
    },
    setShowUpdateOrderModal(state, action) { state.showUpdateOrderModal = action.payload },
    setShowDeleteOrderModal(state, action) { state.showDeleteOrderModal = action.payload },
    setShowOrderDetailsModal(state, action) { state.showOrderDetailsModal = action.payload },
    setShowPickupInstructionsModal(state, action) { state.showPickupInstructionsModal = action.payload },
    updateSignature(state, action) {
      state.signature = action.payload 
    },
    resetGetPickupInstructions(state) {
      state.getPickupInstructionsAPI = initialState.getPickupInstructionsAPI;
      state.pickupInstructions = undefined;
    },
    resetAddPickupInstructions(state) {state.addPickupInstructionsAPI = initialState.addPickupInstructionsAPI},
    resetSendEmailConfirmation(state) {state.sendConfirmationEmailAPI = initialState.sendConfirmationEmailAPI}
  },
  extraReducers: builder => {
    builder
      .addCase(getOrders.pending, state => {
        state.getOrders = loadingAPIResult();
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.getOrders = successAPIResult(action.payload);

        if (action.meta.arg.pagination.offset === 0) {
          state.orders = action.payload;
        } else {
          state.orders.push(...action.payload);
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.getOrders = errorAPIResult(action.error);
      })
      .addCase(getOrder.pending, state => {
        state.getOrder = loadingAPIResult();
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.getOrder = successAPIResult(action.payload);
        state.orders.push(action.payload);
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.getOrder = errorAPIResult(action.error);
      })
      .addCase(getInvoice.pending, state => {
        state.getInvoice = loadingAPIResult();
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.getInvoice = successAPIResult(action.payload);
        state.invoice = action.payload;
      })
      .addCase(getInvoice.rejected, (state, action) => {
        state.getInvoice = errorAPIResult(action.error);
      })
      .addCase(getDashboardOrderStats.pending, state => {
        state.getDashboardOrderStats = loadingAPIResult();
      })
      .addCase(getDashboardOrderStats.fulfilled, (state, action) => {
        state.getDashboardOrderStats = successAPIResult(action.payload);
        state.dashboardOrderStats = action.payload;
      })
      .addCase(getDashboardOrderStats.rejected, (state, action) => {
        state.getDashboardOrderStats = errorAPIResult(action.error);
      })
      .addCase(updateOrder.pending, state => {
        state.updateOrder = loadingAPIResult();
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.updateOrder = successAPIResult(action.payload);
        const newOrders = state.orders.map(o => {
          return o.id === action.payload.id ? action.payload : o;
        });
        state.orders = newOrders;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.updateOrder = errorAPIResult(action.payload!);
      })
      .addCase(getPickupInstructions.pending, state => {
        state.getPickupInstructionsAPI = loadingAPIResult();
      })
      .addCase(getPickupInstructions.fulfilled, (state, action) => {
        state.getPickupInstructionsAPI = successAPIResult(action.payload);
        state.pickupInstructions = action.payload.instructions;
      })
      .addCase(getPickupInstructions.rejected, (state, action) => {
        state.getPickupInstructionsAPI = errorAPIResult(action.payload!);
      })
      .addCase(addPickupInstructions.pending, state => {
        state.addPickupInstructionsAPI = loadingAPIResult();
      })
      .addCase(addPickupInstructions.fulfilled, (state, action) => {
        state.addPickupInstructionsAPI = successAPIResult(action.payload);
      })
      .addCase(addPickupInstructions.rejected, (state, action) => {
        state.addPickupInstructionsAPI = errorAPIResult(action.payload!);
      })
      .addCase(sendConfirmationEmail.pending, state => {
        state.sendConfirmationEmailAPI = loadingAPIResult();
      })
      .addCase(sendConfirmationEmail.fulfilled, (state, action) => {
        state.sendConfirmationEmailAPI = successAPIResult(action.payload);
      })
      .addCase(sendConfirmationEmail.rejected, (state, action) => {
        state.sendConfirmationEmailAPI = errorAPIResult(action.payload!);
      })
      .addMatcher(
        isAnyOf(
        createOrderAPI.fulfilled
        ),
        (state, action) => {
          state.orders.push(action.payload);
          state.signature = undefined;
        } 
      )
      .addMatcher(
        isAnyOf(
          cancelOrderAPI.fulfilled
        ),
        (state, action) => {
          const order = state.orders.find(o => o.id === action.payload);
          if (!!order) {
            order.status = OrderStatus.OrderStatusCanceled;
          }
        } 
      )
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getOrders = initialState.getOrders;
          state.getOrder = initialState.getOrder;
          state.getInvoice = initialState.getInvoice;
          state.getDashboardOrderStats = initialState.getDashboardOrderStats;
          state.updateOrder = initialState.updateOrder;
          state.getPickupInstructionsAPI = initialState.getPickupInstructionsAPI;
          state.addPickupInstructionsAPI = initialState.addPickupInstructionsAPI;
          state.sendConfirmationEmailAPI = initialState.sendConfirmationEmailAPI;

          state.orders = initialState.orders;
          state.invoice = undefined;
          state.dashboardOrderStats = undefined;
          state.showUpdateOrderModal = undefined;
          state.showDeleteOrderModal = undefined;
          state.showOrderDetailsModal = undefined;
          state.pickupInstructions = undefined;
          state.showPickupInstructionsModal = undefined;
          state.signature = undefined;
        } 
      )
  }
});

export const {
  resetInvoice,
  refreshOrders,
  setShowUpdateOrderModal,
  setShowDeleteOrderModal,
  setShowOrderDetailsModal,
  updateSignature,
  setShowPickupInstructionsModal,
  resetGetPickupInstructions,
  resetAddPickupInstructions,
  resetSendEmailConfirmation
} = ordersSlice.actions;
export default ordersSlice.reducer;
