import React from 'react';
import Modal from 'react-modal';
import { Field, Form, Formik } from 'formik';
import { Oval } from 'react-loader-spinner';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { toggleShowAddPermanentRentalModal, createPermanentRentalTemplate } from '../permanentRentalsSlice';
import { ReactComponent as Close } from '../../../assets/images/close.svg';

import './AddPermanentRentalModal.scss';
import { DumpsterSize, PermanentRentalTemplate } from '../../../models';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { RadioButton } from '../../Shared';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF"
  },
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().required('Email is required'),
  phoneNumber: Yup.string()
  .required('Phone number is required')
  .test({
    name: 'phone test',
    message: 'Phone number must be valid US number.',
    test: value => !!value && isValidPhoneNumber(value, 'US')
  }),
  address: Yup.string().required('Address is required'),
  zip: Yup.string().required('ZIP is required'),
});

export type AddPermanentRentalFormModel = Pick<PermanentRentalTemplate, 'name' | 'email' | 'phoneNumber' | 'address' | 'zip' | 'dumpsterSize'>

const initialValues: AddPermanentRentalFormModel = {
  name: '',
  email: '',
  phoneNumber: '',
  address: '',
  zip: '',
  dumpsterSize: DumpsterSize.SIZE_10
};

export function AddPermanentRentalModal() {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(state => state.permanentRentals.showAddPermanentRentalModal);
  const addPermanentRentalAPI = useAppSelector(state => state.permanentRentals.createPermanentRentalTemplate);

  React.useEffect(() => {
    if (!addPermanentRentalAPI.loading && !!addPermanentRentalAPI.error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!addPermanentRentalAPI.loading && !addPermanentRentalAPI.error && addPermanentRentalAPI.value) {
      toast.success('Successfully added permanent rental.');
      dispatch(toggleShowAddPermanentRentalModal());
    }
  }, [addPermanentRentalAPI.loading, !!addPermanentRentalAPI.error, !!addPermanentRentalAPI.value]);

  const toggleModal = () => {
    dispatch(toggleShowAddPermanentRentalModal());
  }

  const onSubmit = (form: AddPermanentRentalFormModel) => {
    dispatch(createPermanentRentalTemplate(form));
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      style={modalStyles}
    >
      <div className='add-permanent-rental-modal-component'>
        <Close className='close-button' onClick={toggleModal}/>

        <div className='title'>Add Permanent Rental</div>
        
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form className='add-permanent-rental-form'>
              <div className='field-container'>
                <div className='label'>Name:</div>
                <Field id='name' name='name' className='text-input'/>
                {errors.name && touched.name && <div className='form-error'>{errors.name}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Email:</div>
                <Field id='email' name='email' className='text-input'/>
                {errors.email && touched.email && <div className='form-error'>{errors.email}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Phone Number:</div>
                <Field id='phoneNumber' name='phoneNumber' className='text-input'/>
                {errors.phoneNumber && touched.phoneNumber && <div className='form-error'>{errors.phoneNumber}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Address:</div>
                <Field id='address' name='address' className='text-input'/>
                {errors.address && touched.address && <div className='form-error'>{errors.address}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>ZIP:</div>
                <Field id='zip' name='zip' className='text-input'/>
                {errors.zip && touched.zip && <div className='form-error'>{errors.zip}</div>}
              </div>

              <div className='field-container'>
                  <div className='label'>Size of dumpster:</div>
                  <div className='radio-button-options-container'>
                    <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_10} label='10 yards'/>
                    <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_15} label='15 yards'/>
                    <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_CLEAN_FILL} label='Clean Fill'/>
                  </div>
                </div>

              <button type='submit' className='add-button'  disabled={addPermanentRentalAPI.loading}>
                Add Permanent Rental
                {addPermanentRentalAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
              </button>

              {!!addPermanentRentalAPI.error && (
                <div className='error-text'>
                  Something went wrong. Please try again.
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
