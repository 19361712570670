import { PunchCardsForDriver, PunchCardSummary, PunchCard } from '../../../models';

export const getPunchCardsForDriver = (data: any): PunchCardsForDriver => {
  return {
    punchCards: getPunchCards(data.punch_cards),
    summary: getSummary(data.summary)
  };
}

const getPunchCards = (data: any): PunchCard[] => {
  const punchCards: PunchCard[] = [];

  data.forEach((pc: any) => {
    const punchCard: PunchCard = {
      id: pc.id,
      driverId: pc.employee_id,
      date: pc.date,
      punchIn: pc.check_in_time,
      punchOut: pc.check_out_time
    };

    punchCards.push(punchCard);
  });

  return punchCards;
}

const getSummary = (data: any): PunchCardSummary => {
  return {
    workingTime: data.working_time,
    onClock: data.on_clock,
  };
}
