import axios, { AxiosInstance } from 'axios';

import { Auth0DatasourceInterface, LoginRequest } from './Auth0DatasourceInterface';

export interface Auth0DatasourceConfig {
  baseURL: string;
}

export class Auth0Datasource implements Auth0DatasourceInterface {
  private client: AxiosInstance;

  constructor(config: Auth0DatasourceConfig) {
    const { baseURL } = config;

    this.client = axios.create({
      baseURL,
    });
  }

  async getToken(loginForm: LoginRequest): Promise<string> {
    const { email, password } = loginForm;

    const payload = {
      response_type: "token id_token",
      grant_type: "password",
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
      scope: "offline_access",
      username: email,
      password
    };
    const { data } = await this.client.post('/oauth/token', payload);
    const token = data.id_token;

    return token;
  }
}
