import React from 'react';
import { BsJoystick, BsArrowRight, BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../app/hooks';

import './PermanentRentalsWidget.scss';

export function PermanentRentalsWidget() {
  const navigate = useNavigate();

  const permanentRentalsLength = useAppSelector(state => state.permanentRentals.templates.length);

  const onWidgetClick = () => {
    navigate('/permanent_rentals', { replace: true });
  };

  const onAddPermanentRental = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div className='dumpyard-widget-component' onClick={onWidgetClick}>
      <div className='title-container'>
        <div className='title'>Permanent Rentals</div>
        <BsArrowRight />
      </div>

      <BsJoystick className='icon'/>

      {permanentRentalsLength && <div className='title-total'>Total: <span className='title-total-count'>{permanentRentalsLength}</span></div>}

      <div className='cta-container'>
        <div className='add-driver-button' onClick={onAddPermanentRental}>
          <BsPlusCircle style={{ marginRight: 4 }}/>
          Add Permanent Rental
        </div>
      </div>
    </div>
  );
}
