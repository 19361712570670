import React from 'react';
import { Row } from 'react-table';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier } from 'dnd-core';

import { useAppDispatch } from '../../../app/hooks';
import { DriversScheduleItem } from '../../../models';
import { updateScheduleItemOrder } from '../driversSlice';

interface DragItem {
  index: number
  id: string
  type: string
}

const ScheduleItemTypeDragType = 'ScheduleItemTypeDragType';

export interface ScheduleTableItemProps {
  row: Row<DriversScheduleItem>;
}

export function ScheduleTableItem(props: ScheduleTableItemProps) {
  const dispatch = useAppDispatch();

  const { row } = props;

  const [currentHoverIndex, setCurrentHoverIndex] = React.useState<number | undefined>(undefined);

  const onDrop = (id: string) => {
    if (currentHoverIndex !== undefined) {
      dispatch(updateScheduleItemOrder({
        id,
        newOrder: currentHoverIndex + 1
      }));
      setCurrentHoverIndex(undefined);
    }
  };

  const ref = React.useRef<HTMLTableRowElement>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ScheduleItemTypeDragType,
    item: { id: row.original.scheduleItemId, index: row.index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  const [{ canDrop, isOver }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null, canDrop: boolean, isOver: boolean }
  >({
    accept: ScheduleItemTypeDragType,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      canDrop: !!monitor.canDrop() && monitor.getItem().id !== row.original.scheduleItemId,
      isOver: !!monitor.isOver()
    }),
    drop: (item: { id: string }) => onDrop(item.id),
    hover(item: DragItem) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index;
      const hoverIndex = row.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      if (currentHoverIndex == hoverIndex) return;

      setCurrentHoverIndex(hoverIndex);
    },
  })

  drag(drop(ref));

  return (
    <tr {...row.getRowProps()} ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }} className={`${canDrop && isOver ? 'over' : ''}`}>
      {row.cells.map(cell => {
        return (
          <td {...cell.getCellProps()} className='data-cell'>
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  );
}
