import React from 'react';
import { BsChevronLeft, BsChevronRight, BsPrinter } from 'react-icons/bs';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import PDFMerger from 'pdf-merger-js/browser';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setScheduleDate, getSchedule, getTripTicket, resetGetTripTicketl } from '../driversSlice';
import { ScheduleTable } from './ScheduleTable';
import { base64toBlobPdf } from '../../../lib/base64ToBlobPdf';

import './Schedule.scss';
import { ScheduleItemType } from '../../../models';

export function Schedule() {
  const dispatch = useAppDispatch();
  const params = useParams();

  const driverId = params.id;

  const driver = useAppSelector(state => state.drivers.drivers.find(d => d.id === driverId));
  const scheduleDate = useAppSelector(state => state.drivers.scheduleDate);
  const updateOrderAPI = useAppSelector(state => state.drivers.updateScheduleItemOrder);
  const getScheduleAPI = useAppSelector(state => state.drivers.getSchedule);
  const getTripTicketAPI = useAppSelector(state => state.drivers.getTripTicket);
  const schedule = useAppSelector(state => state.drivers.schedule);

  const [dateMoment, setDateMoment] = React.useState<moment.Moment>(moment(scheduleDate));
  const [date, setDate] = React.useState<string>(dateMoment.format('dddd, Do MMMM'));

  React.useEffect(() => {
    return () => {
      dispatch(resetGetTripTicketl());
    };
  }, []);

  React.useEffect(() => {
    if (!!driver) {
      dispatch(getSchedule({
        date: scheduleDate,
        driverId: driver.id
      }));
    }
  }, [driver, scheduleDate]);

  React.useEffect(() => {
    if (!!driver && !updateOrderAPI.loading && !!updateOrderAPI.value) {
      dispatch(getSchedule({
        date: scheduleDate,
        driverId: driver.id
      }));
    }
  }, [updateOrderAPI.loading, updateOrderAPI.value]);

  React.useEffect(() => {
    const createPDF = async () => {
      if (!getTripTicketAPI.value) return null;
      
      const pdfFiles = getTripTicketAPI.value.map(pdf =>base64toBlobPdf(pdf));

      const merger = new PDFMerger();
      for(const file of pdfFiles) {
        await merger.add(file);
      }

      const mergedPdf = await merger.saveAsBlob();

      return mergedPdf;
    };

    if (!getTripTicketAPI.loading && !!getTripTicketAPI.value) {
      createPDF()
        .then(pdf => {
          const fileURL = URL.createObjectURL(pdf!);
          //Open the URL on new Window
          const pdfWindow = window.open();
          pdfWindow!.location.href = fileURL;
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [getTripTicketAPI.loading, !!getTripTicketAPI.value]);

  if (!driver) return null;
  
  const onDayBeforeClick = () => {
    setDateMoment(dateMoment.add(-1, 'day'));
    setDate(dateMoment.format('dddd, Do MMMM'));
    dispatch(setScheduleDate(dateMoment.format('YYYY-MM-DD')));
  };
  
  const onDayAfterClick = () => {
    setDateMoment(dateMoment.add(1, 'day'));
    setDate(dateMoment.format('dddd, Do MMMM'));
    dispatch(setScheduleDate(dateMoment.format('YYYY-MM-DD')));
  };

  const onPrintClick = () => {
    if (!schedule) return;

    const orderIds = schedule.items
      .filter(i => i.type === ScheduleItemType.DELIVERY)
      .map(i => i.scheduleItemId);

    dispatch(getTripTicket({
      id: schedule.scheduleId,
      orderIds
    }));
  }

  return (
    <div className='schedule-component'>
      <div className='header-container'>
        <div className='date-container'>
          <div className='icon-container' onClick={onDayBeforeClick}>
            <BsChevronLeft className='icon'/>
          </div>
          <div className='icon-container' onClick={onDayAfterClick}>
            <BsChevronRight className='icon'/>
          </div>

          <div className='date-text'>{date}</div>
          {getScheduleAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
        </div>

        <div className='name-text'>
          {`${driver.firstName} ${driver.lastName}`}
        </div>

        <div className='print-button' onClick={onPrintClick}>
          <BsPrinter style={{ marginRight: 8 }}/>
          Print
          {getTripTicketAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
        </div>
      </div>

      <ScheduleTable />
    </div>
  );
}
