import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  
  const jwtToken = useAppSelector(state => state.auth.jwtToken);

  if (!jwtToken) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
}
