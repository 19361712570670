import { Entity } from './Entity';
import { DumpsterSize, ServiceType } from './Order';

export enum PickUpStatus {
  CREATED = 'created',
  SCHEDULED = 'scheduled',
  COMPLETE = 'complete',
  CANCELED = 'canceled'
}

export interface PickUp extends Entity {
  address: string;
  zip: string;
  service: ServiceType;
  dumpsterSize?: DumpsterSize;
  pickupDate: string;
  status: PickUpStatus;
  orderId: string;
  driverId?: string;
  name: string;
  instructions: string;
  materialDisposing: string;
  latitude: number;
  longitude: number;
  priority: number;
}
