import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { isAdminSelector } from '../Auth/authSlice';
import { store } from '../../app/store';

export function RequireAdmin({ children }: { children: JSX.Element }) {
  const location = useLocation();
  
  const isAdmin = isAdminSelector(store.getState());

  if (!isAdmin) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
}
