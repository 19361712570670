import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult } from '../../lib/redux';
import { CreateOrderParams, triplePSDatasource } from '../../datasource';
import { Order } from '../../models';
import { CreateOrderFormModel } from './CreateOrder';
import { logout } from '../Auth/authSlice';

export interface CreateOrderState {
  createOrder: APIResult<Order>;
}

const initialState: CreateOrderState = {
  createOrder: emptyAPIResult()
}

export const createOrderAPI = createAsyncThunk(
  'createOrder/createOrderAPI',
  async (params: CreateOrderParams) => {
    return triplePSDatasource.createOrder(params);
  }
);

export const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    resetCreateOrder(state) { state.createOrder = initialState.createOrder }
  },
  extraReducers: builder => {
    builder
      .addCase(createOrderAPI.pending, state => {
        state.createOrder = loadingAPIResult();
      })
      .addCase(createOrderAPI.fulfilled, (state, action) => {
        state.createOrder = successAPIResult(action.payload);
      })
      .addCase(createOrderAPI.rejected, (state, action) => {
        state.createOrder = errorAPIResult(action.error);
      })
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.createOrder = initialState.createOrder;
        }
      )
  }
});

export const { resetCreateOrder } = createOrderSlice.actions;
export default createOrderSlice.reducer;
