import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as LoginIcon } from '../../assets/images/loginIcon.svg';
import { isClientSelector, logout, isAdminSelector } from '../Auth/authSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { store } from '../../app/store';

import './NavigationBar.scss';

export function NavigationBar() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(state => !!state.auth.user);
  const isAdmin = isAdminSelector(store.getState());
  const isClient = isClientSelector(store.getState());

  const isDashboard = pathname.includes('dashboard');
  const isDrivers = pathname.includes('drivers');
  const isReceivedOrders = pathname.includes('received_orders');
  const isOrders = !isReceivedOrders && pathname.includes('orders');
  const isSchedules = pathname.includes('schedules');
  const isDumpYards = pathname.includes('dump_yards');
  const isPermanentRentals = pathname.includes('permanent_rentals');
  const isPunchCards = pathname.includes('punch_cards');

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <div className='navigation-bar-component'>
      <div className='left-controls-container'>
        <Link to='/'>
          <Logo className='logo'/>
        </Link>

        {isAuthenticated && (
          <>
            <Link to='/dashboard'><div className={`link ${isDashboard ? 'selected' : ''}`}>Dashboard</div></Link>
          </>
        )}

        {isAdmin && (
          <>
            <Link to='/orders'><div className={`link ${isOrders ? 'selected' : ''}`}>Orders</div></Link>
            <Link to='/received_orders'><div className={`link ${isReceivedOrders ? 'selected' : ''}`}>Received Orders</div></Link>
            <Link to='/drivers'><div className={`link ${isDrivers ? 'selected' : ''}`}>Drivers</div></Link>
            <Link to='/schedules'><div className={`link ${isSchedules ? 'selected' : ''}`}>Schedules</div></Link>
            <Link to='/dump_yards'><div className={`link ${isDumpYards ? 'selected' : ''}`}>Dump Yards</div></Link>
            <Link to='/permanent_rentals'><div className={`link ${isPermanentRentals ? 'selected' : ''}`}>Permanent Rentals</div></Link>
            <Link to='/punch_cards'><div className={`link ${isPunchCards ? 'selected' : ''}`}>Punch Cards</div></Link>
          </>
        )}
      </div>
      <div className='right-controls-container'>
        {isAuthenticated ? (
          <>
            {isClient && <Link to='/create-order'><div className='link-navigation'>Create Order</div></Link>}
            <div className='link-navigation' onClick={onLogout}>Logout</div>
          </>
        ) : (
          <>
            <Link to='/auth/register'>
              <div className='link-navigation'>Create Account</div>
            </Link>
            <Link to='/auth/login'>
              <div className='link-navigation'>Login</div>
            </Link>
          </>
        )}
        <LoginIcon className='login-icon'/>
      </div>
    </div>
  );
}

