import { Entity } from './Entity';

export interface ScheduleItem extends Entity {
  scheduleId: string;
  orderNumber: number;
  type: ScheduleItemType;
  deliveryId?: string;
  pickupId?: string;
  dumpYardId?: string;
}

export enum ScheduleItemType {
  DELIVERY = 'delivery',
  PICK_UP = 'pick_up',
  DUMP_YARD = 'dump_yard'
}

export const scheduleItemTypeToClassText: Record<ScheduleItemType, string> = {
  [ScheduleItemType.DELIVERY]: 'delivery',
  [ScheduleItemType.PICK_UP]: 'pick_up',
  [ScheduleItemType.DUMP_YARD]: 'dump_yard',
}
