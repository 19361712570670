import Modal from 'react-modal';
import React from 'react';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setShowPickupInstructionsModal } from '../ordersSlice';
import { addPickupInstructions, resetAddPickupInstructions, getPickupInstructions, resetGetPickupInstructions } from '../ordersSlice';
import { ReactComponent as Close } from '../../../assets/images/close.svg';

import './PickupInstructionsModal.scss'

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FFFFFF'
    },
  };

  export interface PickupInstructionsFormModel {
    instructions: string;
  }
  
  const validationSchema = Yup.object().shape({
    instructions: Yup.string()
  });
  
  
  export const PickupInstructionsModal = () => {
    const dispatch = useAppDispatch();
    
    const showPickupInstructionsModal = useAppSelector(state => state.orders.showPickupInstructionsModal);
    const addPickupInstructionsAPI = useAppSelector(state => state.orders.addPickupInstructionsAPI);
    const getPickupInstructionsAPI = useAppSelector(state => state.orders.getPickupInstructionsAPI);
    const pickupInstructions = useAppSelector(state => state.orders.pickupInstructions);
    
    const isOpen = !!showPickupInstructionsModal;
    
    React.useEffect(() => {
      isOpen && dispatch(getPickupInstructions(showPickupInstructionsModal));
    }, [isOpen]);
    
    React.useEffect(() => {
      if (!addPickupInstructionsAPI.loading && !!addPickupInstructionsAPI.error) {
        toast.error('Something went wrong. Please try again.');
      }
      
      if (!addPickupInstructionsAPI.loading && !addPickupInstructionsAPI.error && addPickupInstructionsAPI.value) {
        toast.success('Successfully added pickup instructions.');
        dispatch(resetAddPickupInstructions());
        dispatch(resetGetPickupInstructions());
        dispatch(setShowPickupInstructionsModal(undefined));
      }
    }, [!!addPickupInstructionsAPI.error, !!addPickupInstructionsAPI.value, !!addPickupInstructionsAPI.loading]);
    
    const onClose = () => {
      dispatch(setShowPickupInstructionsModal(undefined));
    }
    
    const onSubmit = (form: PickupInstructionsFormModel) => {
      if (!showPickupInstructionsModal) return;
      
      dispatch(addPickupInstructions({
        id: showPickupInstructionsModal,
        intructions: form.instructions
      }));
    }
    
    const initialValues: PickupInstructionsFormModel = {
      instructions: pickupInstructions || ''
    };

    const form: React.ReactNode = (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form className='pickup-instructions-form-component'>
            <div className='field-container'>
              <div className='label'>Instructions:</div>
              <Field id='instructions' name='instructions' className='text-input'/>
              {errors.instructions && touched.instructions && <div className='form-error'>{errors.instructions}</div>}
            </div>

            <div className='buttons-container'>
              <button className='button secondary-button' disabled={addPickupInstructionsAPI.loading} onClick={onClose}>
                Cancel
              </button>
              <button type='submit' className='button' disabled={addPickupInstructionsAPI.loading}>
                Add Instructions
                {addPickupInstructionsAPI.loading && <div style={{ marginLeft: 8}}><Oval color='#131313' height={20} width={20} secondaryColor='#FBD20A'/></div>}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
        <div className='delete-order-modal-container'>
          <Close className='close-button' onClick={onClose}/>

          <div className='title'>Add pickup instructions</div>

          {getPickupInstructionsAPI.loading
            ? <div style={{ marginLeft: 8}}><Oval color='#131313' height={20} width={20} secondaryColor='#FBD20A'/></div>
            : form
          }
        </div>
      </Modal>
    );
}
