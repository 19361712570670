import React from 'react';
import { useTable, Column } from 'react-table';

import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { Invoice, InvoiceType, invoiceTypeToText } from '../../../../models';
import { getInvoicePDF, resetInvoicePDF } from '../../clientDashboardSlice';
import { base64toBlobPdf } from '../../../../lib/base64ToBlobPdf';

import './InvoicesTable.scss';

type ColumnsType = Invoice;

export function InvoicesTable() {
  const dispatch = useAppDispatch();

  const invoices = useAppSelector(state => state.clientDashboard.invoices);
  const getInvoicePDFAPI = useAppSelector(state => state.clientDashboard.getInvoicePDFAPI);

  const renderActionsCell = (props: any) => {
    return (
      <div className='view-button' onClick={onViewInvoiceClick(props.row.original.id)}>View Invoice</div>
    );
  };

  const onViewInvoiceClick = (invoiceId: string) => () => {
    dispatch(getInvoicePDF({ id: invoiceId }));
  }

  React.useEffect(() => {
    if (!getInvoicePDFAPI.loading && !!getInvoicePDFAPI.value) {
      const pdf = base64toBlobPdf(getInvoicePDFAPI.value);
      const fileURL = URL.createObjectURL(pdf);
      const pdfWindow = window.open();
      pdfWindow!.location.href = fileURL;

      dispatch(resetInvoicePDF());
    }
  }, [getInvoicePDFAPI.loading, !!getInvoicePDFAPI.value]);

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: cell => invoiceTypeToText[cell.type as InvoiceType]
      },
      {
        Header: 'Amount',
        accessor: cell => `$${cell.total}`
      },
      {
        Header: 'Actions',
        accessor: 'id',
        width: 80,
        maxWidth: 80,
        Cell: renderActionsCell
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: invoices });

  if (invoices.length === 0) return (
    <div className='invoices-table-component empty-state'>
      <div className='text'>There are no invoices.</div>
    </div>
  );

  return (
    <div className='invoices-table-component'>
      <table {...getTableProps()} className='new-table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}>
                  <div className='header-cell'>
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}
