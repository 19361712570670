import React from 'react';
import GoogleMapReact from 'google-map-react';
import { BsCheck2 } from 'react-icons/bs';

import { useAppSelector } from '../../../../app/hooks';
import { MapMarker } from '../MapMarker/MapMarker';
import { Delivery, DeliveryStatus, DumpYardTemplate, PickUp, PickUpStatus, ScheduleItemType } from '../../../../models';

import * as simpleMapStyle from './simpleStyle.json';
import './MapView.scss';

enum MapStyle {
  DEFAULT = 'Default',
  SIMPLE = 'Simple'
}

enum FilterType {
  ORDERS = 'Orders',
  DRIVERS = 'Drivers'
}

enum StopType {
  DELIVERIES = 'Deliveries',
  PICKUPS = 'Pickups',
  DUMP_YARDS = 'Dump yards',
  STORAGES = 'Storages',
  QUICK_STOPS = 'Quick Stops',
}


export function MapView() {
  const dumpYardTemplates = useAppSelector(state => state.dumpYards.templates);
  const deliveries = useAppSelector(state => state.deliveries.deliveries);
  const pickups = useAppSelector(state => state.pickups.pickups);
  const dumpYards = dumpYardTemplates.filter(t => t.isDumpYard);
  const storages = dumpYardTemplates.filter(t => t.isStorage);
  const quickStops = dumpYardTemplates.filter(t => t.isQuickStop);

  const filteredDeliveries = deliveries.filter(d => d.status !== DeliveryStatus.CANCELED);
  const filteredPickups = pickups.filter(p => p.status !== PickUpStatus.CANCELED);

  const [mapStyle, setMapStyle] = React.useState<MapStyle>(MapStyle.SIMPLE);
  const [filterType, setFilterType] = React.useState<FilterType>(FilterType.ORDERS);
  const [stopTypes, setStopTypes] = React.useState<StopType[]>([StopType.DELIVERIES, StopType.PICKUPS]);

  const shouldRenderDeliveries = filterType === FilterType.ORDERS && stopTypes.includes(StopType.DELIVERIES);
  const shouldRenderPickups = filterType === FilterType.ORDERS && stopTypes.includes(StopType.PICKUPS);
  const shouldRenderDumpYards = filterType === FilterType.ORDERS && stopTypes.includes(StopType.DUMP_YARDS);
  const shouldRenderStorages = filterType === FilterType.ORDERS && stopTypes.includes(StopType.STORAGES);
  const shouldRenderQuickStops = filterType === FilterType.ORDERS && stopTypes.includes(StopType.QUICK_STOPS);

  const defaultProps = {
    center: {
      lat: 40.400160,
      lng: -80.049320
    },
    zoom: 11
  };

  const renderDumpYard = (dumpYard: DumpYardTemplate): React.ReactNode => {
    return <MapMarker name={dumpYard.name} address={dumpYard.address} zip={dumpYard.zip} lat={dumpYard.latitude} lng={dumpYard.longitude} className='dump_yard' />;
  }

  const renderDeliveries = (delivery: Delivery): React.ReactNode => {
    return (
      <MapMarker
        name={`${delivery.name}, ${delivery.dumpsterSize}`}
        address={delivery.address}
        zip={delivery.zip}
        lat={delivery.latitude}
        lng={delivery.longitude}
        className='delivery'
      />
    );
  }

  const renderPickups = (pickup: PickUp): React.ReactNode => {
    return (
      <MapMarker
        name={`${pickup.name}, ${pickup.dumpsterSize}`}
        address={pickup.address}
        zip={pickup.zip}
        lat={pickup.latitude}
        lng={pickup.longitude}
        className='pick_up'
      />
    );
  }

  const renderMapStyleItem = (item: MapStyle) : React.ReactNode => {
    const onPress = () => setMapStyle(item);

    const selected = item === mapStyle;

    return (
      <div onClick={onPress} key={item} className='style-item-container'>
        <div>{item}</div>

        {selected && <BsCheck2 />}
      </div>
    );
  }

  const renderFilterTypes = (type: FilterType) : React.ReactNode => {
    const onPress = () => setFilterType(type);

    const selected = type === filterType;

    return (
      <div onClick={onPress} key={type} className='item-container'>
        <div>{type}</div>

        {selected && <BsCheck2 />}
      </div>
    );
  }

  const renderStopType = (stop: StopType) : React.ReactNode => {
    const selected = stopTypes.includes(stop);

    const onPress = () => {
      let updatedStopTypes: StopType[];
  
      if (selected) {
        updatedStopTypes = stopTypes.filter(type => type !== stop);
      } else {
        updatedStopTypes = [...stopTypes, stop];
      }
  
      setStopTypes(updatedStopTypes);
    };

    return (
      <div onClick={onPress} key={stop} className='item-container'>
        <div>{stop}</div>

        {selected && <BsCheck2 />}
      </div>
    );
  }

  return (
    <div className='map-view-component'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDriqceHS-zAat-UBCGfVvt43yIU0OQ6mI' }}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          styles: mapStyle === MapStyle.DEFAULT ? undefined : simpleMapStyle
        }}
      >
        {shouldRenderDeliveries && filteredDeliveries.map(renderDeliveries)}
        {shouldRenderPickups && filteredPickups.map(renderPickups)}
        {shouldRenderDumpYards && dumpYards.map(renderDumpYard)}
        {shouldRenderStorages && storages.map(renderDumpYard)}
        {shouldRenderQuickStops && quickStops.map(renderDumpYard)}
      </GoogleMapReact>

      <div className='options-container'>
        <div className='style-container'>
          <div className='style-title'>Style</div>

          {[MapStyle.DEFAULT, MapStyle.SIMPLE].map(renderMapStyleItem)}
        </div>

        <div className='filters-container'>
          <div className='title'>Filter</div>

          {[FilterType.ORDERS].map(renderFilterTypes)}

          <div className='divider'/>

          {filterType === FilterType.ORDERS && (
            <div className='stop-types-container'>
              {Object.values(StopType).map(renderStopType)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
