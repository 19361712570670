import axios, { AxiosInstance } from 'axios';
import { Address, DashboardOrderStats, DashboardScheduleStats, Delivery, DriversSchedule, DumpYard, DumpYardTemplate, Invoice, OrdersDashboard, PickUp, PunchCard, PunchCardsForAllDrivers, Schedule, ScheduleItem, User } from '../../models';

import { TriplePInterface, CreateUserRequest, TriplePPagination, ScheduleForm, ScheduleItemForm, DumpYardTemplateForm, OrderFilters, UpdateOrderFormModel, PermanentRentalTemplateForm, CreateOrderParams, ApproveOrderParams, RejectOrderParams, GetDumpYardsParams, AddPickupInstructionsParams, SendConfirmationEmailParams, GetInvoicesParams, GetPunchCardSummariesParams, UpdatePunchCardsParams, ChangeDeliveryPriorityParams, ChangePickupPriorityParams, UpdateDumpYardInstructionsParams } from './TriplePInterface';
import * as normalizers from './normalizers';
import * as denormalizers from './denormalizers';
import { LOCAL_STORAGE_KEYS } from '../../lib/localStorage';
import { Order } from '../../models/Order';
import { PermanentRentalTemplate } from '../../models/PermanentRentalTemplate';
import moment from 'moment';

export interface TriplePServiceDatasourceConfig {
  baseURL: string;
}

export class TriplePServiceDatasource implements TriplePInterface {
  private client: AxiosInstance;

  constructor(config: TriplePServiceDatasourceConfig) {
    const { baseURL } = config;

    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    this.client.interceptors.request.use(config => {
      const jwtToken = localStorage.getItem(LOCAL_STORAGE_KEYS.JWT_TOKEN);

      if (!!jwtToken && !!config.headers) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
      }

      return config;
    });
  }

  async createUser(payload: CreateUserRequest): Promise<string> {
    const { data } = await this.client.post('/users', payload);
    return data.user_id;
  }

  async getProfile(): Promise<User> {
    const { data } = await this.client.get('/users/profile');
    return normalizers.getUserProfile(data);
  }

  async getDrivers(): Promise<User[]> {
    const { data } = await this.client.get('/users?role=driver');

    return normalizers.getUsers(data.data);
  }

  async getDriverAddreses(): Promise<Address[]> {
    const { data } = await this.client.get('/users/driver_address');

    return normalizers.getAddresses(data.data);
  }


  async addDriver(email: string): Promise<User> {
    const { data } = await this.client.post('/users/add_driver', { email });

    return normalizers.getUser(data.data);
  }

  async removeDriver(driverId: string): Promise<string> {
    await this.client.post('/users/remove_driver', { driver_id: driverId });

    return driverId;
  }

  async createOrder(params: CreateOrderParams): Promise<Order> {
    const payload = denormalizers.toOrder(params);
    const { data } = await this.client.post('/orders', payload);

    return normalizers.getOrder(data.data);
  }

  async cancelOrder(orderId: string): Promise<string> {
    await this.client.delete(`/orders/${orderId}`);

    return orderId;
  }
  
  async updateOrder(form: UpdateOrderFormModel): Promise<Order> {
    const payload = denormalizers.toOrderFromUpdate(form);
    const { data } = await this.client.put(`/orders/${form.id}`, payload);

    return normalizers.getOrder(data.data);
  }

  async getOrders(filters: OrderFilters, pagination: TriplePPagination): Promise<Order[]> {
    const params = new URLSearchParams({
      count: pagination.count + '',
      offset: pagination.offset + ''
    });
    
    if (filters.statuses.length > 0) {
      filters.statuses.forEach(status => params.append('status', status));
    }
    if (!!filters.createdStartDate) {
      params.append('created_start_date', filters.createdStartDate);
    }
    if (!!filters.createdEndDate) {
      params.append('created_end_date', filters.createdEndDate);
    }
    if (!!filters.pickupStartDate) {
      params.append('pickup_start_date', filters.pickupStartDate);
    }
    if (!!filters.pickupEndDate) {
      params.append('pickup_end_date', filters.pickupEndDate);
    }

    const { data } = await this.client.get(`/orders?${params.toString()}`);

    return normalizers.getOrders(data.data);
  }

  async getOrder(id: string): Promise<Order> {
    const { data } = await this.client.get(`/orders/${id}`);

    return normalizers.getOrder(data.data);
  }

  async getDeliveries(date: string): Promise<Delivery[]> {
    const { data } = await this.client.get(`/orders/deliveries?delivery_date=${date}`);

    return normalizers.getDeliveries(data.data);
  }

  async getPickups(date: string): Promise<PickUp[]> {
    const { data } = await this.client.get(`/orders/pickups?pickup_date=${date}`);

    return normalizers.getPickups(data.data);
  }

  async getInvoice(id: string): Promise<string> {
    const { data } = await this.client.get(`/orders/${id}/invoice`);

    return data;
  }

  async approveOrder(params: ApproveOrderParams): Promise<Order> {
    const payload = denormalizers.toApproveOrderPayload(params);
    const { data } = await this.client.post(`/orders/${params.id}/approve`, payload);

    return normalizers.getOrder(data.data);
  }

  async getOrdersDashboard(): Promise<OrdersDashboard> {
    const { data } = await this.client.get('/orders/dashboard');
    
    return normalizers.getOrdersDashboard(data);
  }

  async getPickupInstructions(orderId: string): Promise<PickUp> {
    const { data } = await this.client.get(`/orders/${orderId}/pickup_instructions`);

    return normalizers.getPickup(data.data);
  }

  async addPickupInstructions(params: AddPickupInstructionsParams): Promise<string> {
    const payload = { instructions: params.intructions };
    await this.client.post(`/orders/${params.id}/pickup_instructions`, payload);
    return params.id;
  }

  async rejectOrder(params: RejectOrderParams): Promise<Order> {
    const { data } = await this.client.post(`/orders/${params.id}/reject`);

    return normalizers.getOrder(data.data);
  }

  async sendConfirmationEmail(params: SendConfirmationEmailParams): Promise<string> {
    await this.client.post(`/orders/${params.id}/confirmation_email`);

    return params.id;
  }

  async changeDeliveryPriority(params: ChangeDeliveryPriorityParams): Promise<Delivery> {
    const payload = {
      priority: params.priority
    };

    const { data } = await this.client.post(`/orders/${params.id}/change_delivery_priority`, payload);

    return normalizers.getDelivery(data.data);
  }

  async changePickupPriority(params: ChangePickupPriorityParams): Promise<PickUp> {
    const payload = {
      priority: params.priority
    };

    const { data } = await this.client.post(`/orders/${params.id}/change_pickup_priority`, payload);

    return normalizers.getPickup(data.data);
  }

  async createSchedule(form: ScheduleForm): Promise<Schedule> {
    const payload = denormalizers.toSchedule(form);
    const { data } = await this.client.post('/schedules', payload);

    return normalizers.getSchedule(data.data, []);
  }

  async getSchedules(date: string, driverId?: string): Promise<Schedule[]> {
    const { data } = await this.client.get(`/schedules?date=${date}${driverId ? `&driver_id=${driverId}`: ''}`);

    return normalizers.getSchedules(data);
  }

  async getSchedule(id: string): Promise<Schedule> {
    const { data } = await this.client.get(`/schedules/${id}`);

    return normalizers.getSchedule(data.data, data.included);
  }

  async deleteSchedule(id: string): Promise<string> {
    await this.client.delete(`/schedules/${id}`);

    return id;
  }

  async optimizeSchedule(id: string): Promise<Schedule> {
    const { data } = await this.client.get(`/schedules/${id}/optimize`);

    return normalizers.getSchedule(data.data, []);
  }

  async calculateStats(id: string): Promise<Schedule> {
    const { data } = await this.client.get(`/schedules/${id}/calculate_stats`);

    return normalizers.getSchedule(data.data, []);
  }

  async getDriversSchedule(date: string, driverId?: string): Promise<DriversSchedule> {
    const { data } = await this.client.get(`/schedules/drivers_schedule?date=${date}${driverId ? `&driver_id=${driverId}`: ''}`);

    return normalizers.getDriversSchedule(data);
  }

  async getTripTicket(id: string, scheduleItemIds: string[]): Promise<string[]> {
    const promiseTripTicket = this.client.get(`/schedules/${id}/trip_ticket`).then(resp => resp.data);
    const promises: Promise<any>[] = [promiseTripTicket];

    scheduleItemIds.forEach(scheduleItemId => {
      const promise = this.client.get(`/schedule_items/${scheduleItemId}/invoice`).then(resp => resp.data);

      promises.push(promise);
    });

    return Promise.all(promises)
      .then(values => {
        return values;
      });
  }

  async createScheduleItem(form: ScheduleItemForm): Promise<ScheduleItem> {
    const payload = denormalizers.toScheduleItem(form);
    const { data } = await this.client.post('/schedule_items', payload);

    return normalizers.getScheduleItem(data.data);
  }

  async getScheduleItems(scheduleId: string): Promise<ScheduleItem[]> {
    const { data } = await this.client.get(`/schedule_items?schedule_id=${scheduleId}`);

    return normalizers.getScheduleItems(data.data);
  }

  async updateOrderScheduleItem(id: string, newOrderNumber: number): Promise<ScheduleItem> {
    const { data } = await this.client.post(`/schedule_items/${id}/update_order`, { new_order_number: newOrderNumber });

    return normalizers.getScheduleItem(data.data);
  }

  async unassign(id: string): Promise<string> {
    await this.client.delete(`/schedule_items/${id}/unassign`);

    return id;
  }

  async changeSchedule(id: string, newScheduleId: string): Promise<ScheduleItem> {
    const { data } = await this.client.post(`/schedule_items/${id}/change_schedule`, { new_schedule_id: newScheduleId });

    return normalizers.getScheduleItem(data.data);
  }

  async getDumpYardTemplates(): Promise<DumpYardTemplate[]> {
    const { data } = await this.client.get('/dump_yard_templates');

    return normalizers.getDumpYardTemplates(data.data);
  }

  async deleteDumpYardTemplate(id: string): Promise<string> {
    await this.client.delete(`/dump_yard_templates/${id}`);

    return id;
  }

  async createDumpYardTemplate(form: DumpYardTemplateForm): Promise<DumpYardTemplate> {
    const payload = denormalizers.toDumpYardTemplate(form);
    const { data } = await this.client.post(`/dump_yard_templates`, payload);

    return normalizers.getDumpYardTemplate(data.data);
  }

  async updateDumpYardTemplate(template: DumpYardTemplate): Promise<DumpYardTemplate> {
    const payload = denormalizers.toUpdateDumpYardTemplate(template);
    const { data } = await this.client.put(`/dump_yard_templates/${template.id}`, payload);

    return normalizers.getDumpYardTemplate(data.data);
  }

  async getDumpYards(params: GetDumpYardsParams): Promise<DumpYard[]> {
    let url = `/dump_yards?date=${params.date}`;
    if (!!params.status) {
      url += `&status=${params.status}`;
    }

    const { data } = await this.client.get(url);

    return normalizers.getDumpYards(data.data);
  }

  async updatePickupInstructions(params: UpdateDumpYardInstructionsParams): Promise<DumpYard> {
    const payload = { instructions: params.instructions };
    const { data } = await this.client.put(`/dump_yards/${params.id}/instructions`, payload);

    return normalizers.getDumpYard(data.data);
  }

  async getOrderStats(): Promise<DashboardOrderStats> {
    const { data } = await this.client.get('dashboard/order_stats');

    return normalizers.getDashboardOrderStats(data);
  }

  async getScheduleStats(): Promise<DashboardScheduleStats> {
    const { data } = await this.client.get('dashboard/schedule_stats');

    return normalizers.getDashboardScheduleStats(data);
  }

  async getPermanentRentalTemplates(): Promise<PermanentRentalTemplate[]> {
    const { data } = await this.client.get('/permanent_rental_templates');

    return normalizers.getPermanentRentalTemplates(data.data);
  }

  async createPermanentRentalTemplate(form: PermanentRentalTemplateForm): Promise<PermanentRentalTemplate> {
    const payload = denormalizers.toPermanentRentalTemplate(form);
    const { data } = await this.client.post('/permanent_rental_templates', payload);

    return normalizers.getPermanentRentalTemplate(data.data);
  }

  async deletePermanentRentalTemplate(id: string): Promise<string> {
    await this.client.delete(`/permanent_rental_templates/${id}`);

    return id;
  }

  async getInvoices(params: GetInvoicesParams): Promise<Invoice[]> {
    const { data } = await this.client.get(`/invoices?order_id=${params.orderId}`);

    return normalizers.getInvoices(data.data);
  }

  async getInvoicePDF(id: string): Promise<string> {
    const { data } = await this.client.get(`/invoices/${id}/download`);

    return data;
  }

  // Punch cards
  async getPunchCardSummaries(params: GetPunchCardSummariesParams): Promise<PunchCardsForAllDrivers[]> {
    const { data } = await this.client.get(`/punch_cards/summaries?from_date=${params.fromDate}&to_date=${params.toDate}`);

    return normalizers.getPunchCardSummaries(data);
  }

  async updatePunchCard(params: UpdatePunchCardsParams): Promise<PunchCard> {
    const payload = denormalizers.toPunchCard(params);
    const { data } = await this.client.put(`/punch_cards/${params.id}`, payload);

    return normalizers.getPunchCard(data.data);
  }
}
