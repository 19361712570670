import { PunchCard } from '../../../models';

export const getPunchCard = (data: any): PunchCard => {
  return {
    id: data.id,
    driverId: data.attributes.driver_id,
    date: data.attributes.date,
    punchIn: data.attributes.punch_in,
    punchOut: data.attributes.punch_out
  };
}

export const getPunchCards = (data: any): PunchCard[] => {
  return data.map(getPunchCard);
}
