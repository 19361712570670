import React from 'react';
import { useDrop } from 'react-dnd';
import { BsTrash, BsArrowUpRightSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

import { Schedule, ScheduleItemType, optimizedDistanceToHuman, optimizedTimeToHuman } from '../../../models';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { createScheduleItem, deleteSchedule, changeScheduleScheduleItem, getScheduleItems, optimizeSchedule, getSchedule, calculateStats } from '../../Schedules/scheduleSlice';
import { setScheduleDate } from '../../Drivers/driversSlice';
import { ScheduledDeliveryItem } from '../../Deliveries';
import { ScheduledPickupItem } from '../../Pickups';
import { ScheduledDumpYardItem } from '../../DumpYards';

import './ScheduleDriverWidget.scss';

export interface ScheduleDriverWidgetProps {
  schedule: Schedule;
}

export function ScheduleDriverWidget(props: ScheduleDriverWidgetProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { schedule } = props;

  const driver = useAppSelector(state => state.drivers.drivers.find(d => d.id === schedule.driverId));
  const changeScheduleItemOrderStatus = useAppSelector(state => state.schedules.changeScheduleItemOrder);
  const changeScheduleItemOrderScheduleId = useAppSelector(state => state.schedules.changeScheduleItemOrder.value?.scheduleId);
  const optimizeScheduleAPI = useAppSelector(state => state.schedules.optimizeScheduleAPI);
  const calculateStatsAPI = useAppSelector(state => state.schedules.calculateStatsAPI);
  const getScheduleAPI = useAppSelector(state => state.schedules.getScheduleAPI);
  const showDelete = schedule.scheduleItems.length === 0;
  const pollScheduleTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (!changeScheduleItemOrderStatus.loading && !!changeScheduleItemOrderStatus.value) {
      if (!!changeScheduleItemOrderScheduleId && schedule.id === changeScheduleItemOrderScheduleId) {
        dispatch(getScheduleItems(changeScheduleItemOrderScheduleId));
      }
    }
  }, [changeScheduleItemOrderStatus.loading, !!changeScheduleItemOrderStatus.value]);

  React.useEffect(() => {
    if (!optimizeScheduleAPI.loading && !!optimizeScheduleAPI.value && optimizeScheduleAPI.value.id === schedule.id) {
      pollSchedule();
    }
  }, [optimizeScheduleAPI.loading, !!optimizeScheduleAPI.value]);

  React.useEffect(() => {
    if (!getScheduleAPI.loading && !!getScheduleAPI.value && !getScheduleAPI.value.isOptimizing) {
      if (!!pollScheduleTimeoutRef.current) {
        clearTimeout(pollScheduleTimeoutRef.current);
      }
    }
  }, [getScheduleAPI.loading, !!getScheduleAPI.value]);


  React.useEffect(() => {
    return () => {
      if (!!pollScheduleTimeoutRef.current) {
        clearTimeout(pollScheduleTimeoutRef.current);
      }
    };
  }, []);

  const onDeleteSchedule = () => {
    dispatch(deleteSchedule(schedule.id));
  }

  const onSchedulePress = () => {
    dispatch(setScheduleDate(schedule.date));
    navigate(`/drivers/${driver?.id}`, { replace: true });
  }

  const onOptimizePress = () => {
    if (!schedule.isOptimizing) {
      dispatch(optimizeSchedule(schedule.id));

      schedule.isOptimizing = true
      schedule.optimizedDistance = 0
      schedule.optimizedTime = 0
    }
  }

  const onCalculateStatsPress = () => {
    if (!schedule.isOptimizing) {
      dispatch(calculateStats(schedule.id));

      schedule.isOptimizing = true
      schedule.optimizedDistance = 0
      schedule.optimizedTime = 0
    }
  }

  const pollSchedule = () => {
    let interval = 5000;

    function poolFunction() {
      dispatch(getSchedule(schedule.id));
      console.log(`Pooling function executed at interval: ${interval}ms`);
  
      interval *= 1.5;
  
      pollScheduleTimeoutRef.current = setTimeout(poolFunction, interval);
    }
  
    pollScheduleTimeoutRef.current = setTimeout(poolFunction, interval);
  }

  const onDrop = (type: ScheduleItemType, id: string, scheduleId: string) => {
    if (scheduleId === schedule.id) return;

    if (!scheduleId) {
      dispatch(createScheduleItem({
        scheduleId: schedule.id,
        type,
        deliveryId: type === ScheduleItemType.DELIVERY ? id : undefined,
        pickupId: type === ScheduleItemType.PICK_UP ? id : undefined,
        dumpYardId: type === ScheduleItemType.DUMP_YARD ? id : undefined
      }));
    } else {
      dispatch(changeScheduleScheduleItem({
        id,
        newScheduleId: schedule.id
      }));
    }
  }

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [ScheduleItemType.DELIVERY, ScheduleItemType.PICK_UP, ScheduleItemType.DUMP_YARD],
      drop: (item: { type: ScheduleItemType, id: string, scheduleId: string }) => onDrop(item.type, item.id, item.scheduleId),
      collect: (monitor) => ({
        canDrop: !!monitor.canDrop() && monitor.getItem().scheduleId !== schedule.id,
        isOver: !!monitor.isOver()
      })
    })
  )
  
  const renderItems = (): React.ReactNode => {
    const data = [...schedule.scheduleItems].sort((a, b) => a.orderNumber - b.orderNumber);

    return (
      <div className='items-container'>
        {data.map(si => {
          const allowMoveUp = si.orderNumber > 1;
          const allowMoveDown = si.orderNumber < schedule.scheduleItems.length;

          if (si.type === ScheduleItemType.DELIVERY) {
            return si.deliveryId ? <ScheduledDeliveryItem scheduleItem={si} key={si.id} allowMoveUp={allowMoveUp} allowMoveDown={allowMoveDown}/> : null;
          } else if (si.type === ScheduleItemType.PICK_UP) {
            return si.pickupId ? <ScheduledPickupItem scheduleItem={si} key={si.id} allowMoveUp={allowMoveUp} allowMoveDown={allowMoveDown}/> : null;
          } else if (si.dumpYardId) {
            return si.dumpYardId ? <ScheduledDumpYardItem scheduleItem={si} key={si.id} allowMoveUp={allowMoveUp} allowMoveDown={allowMoveDown}/> : null ;
          }
        })}
      </div>
    );
  }

  const renderOptimizing = (): React.ReactNode => {
    const copy = calculateStatsAPI.loading ? 'Calculating stats...' : 'Optimizing...';

    return (
      <div className='optimizing-container'>
        {copy}
        <Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/>
      </div>
    );
  }

  return (
    <div
      ref={drop}
      className={`schedule-driver-widget-component ${canDrop ? 'dropable' : ''} ${canDrop && isOver ? 'over' : ''}`}
      key={schedule.id}
    >
      <div className='schedule-driver-header-container'>
        <div className='name-container'>
          <div className='name-text'>{`${driver?.firstName} ${driver?.lastName}`}</div>
          <div className='schedule-icon' onClick={onSchedulePress}>
            <BsArrowUpRightSquare className='schedule-icon'/>
          </div>
        </div>
        <div className='total'>{`Total: ${schedule.scheduleItems.length}`}</div>
        {showDelete && <BsTrash className='delete-icon' onClick={onDeleteSchedule}/>}
      </div>

      <div className='name-border'/>

      <div className='optimization-container'>
        <div className='details-container'>
          <div className='item-container'>
            <div className='item-label'>Optimized: </div>
            <div className='item-value'>{schedule.optimizedDistance > 0 ? 'Yes' : 'No'}</div>
          </div>
          <div className='item-container'>
            <div className='item-label'>Distance: </div>
            <div className='item-value'>{optimizedDistanceToHuman(schedule.optimizedDistance)}</div>
          </div>
          <div className='item-container'>
            <div className='item-label'>Drive time: </div>
            <div className='item-value'>{optimizedTimeToHuman(schedule.optimizedTime)}</div>
          </div>
        </div>

        <div className='actions-container'>
          {!schedule.isOptimizing && <div className='optimize-button' onClick={onOptimizePress}>Optimize</div>}
          {!schedule.isOptimizing && <div className='calculate-stats-button' onClick={onCalculateStatsPress}>Calculate stats</div>}
        </div>
      </div>

      <div className='name-border'/>

      {schedule.isOptimizing || calculateStatsAPI.loading
        ? renderOptimizing()
        : renderItems()
      }
    </div>
  );
}
