import { Entity } from './Entity';
import { ScheduleItem } from './ScheduleItem';

export interface Schedule extends Entity {
  driverId: string;
  date: string;
  isOptimizing: boolean;
  optimizedTime: number;
  optimizedDistance: number;

  scheduleItems: ScheduleItem[];
}

export const optimizedTimeToHuman = (optimizedTime: number): string => {
  const hours = Math.floor(optimizedTime / 60);
  const minutes = Math.ceil(optimizedTime % 60);

  return `${hours} h ${minutes} m`;
}

export const optimizedDistanceToHuman = (optimizedDistance: number): string => {
  const metersInOneMile = 1609.34;
  const miles = optimizedDistance / metersInOneMile;
  const milesString = miles.toFixed(2);

  return `${milesString} mi`;
}
