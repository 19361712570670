import React from 'react';
import Modal from 'react-modal';
import { Field, Form, Formik } from 'formik';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setShowUpdateDumpYardInstructionsModal, updateDumpYardInstructions } from '../dumpYardsSlice';
import { ReactComponent as Close } from '../../../assets/images/close.svg';

import './UpdateInstructionsModal.scss';
import { DumpYard } from '../../../models';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF"
  },
};

export type UpdateDumpYardInstructionsFormModel = Pick<DumpYard, 'instructions'>;

export function UpdateInstructionsModal() {
  const dispatch = useAppDispatch();

  const dumpYard = useAppSelector(state => state.dumpYards.showUpdateDumpYardInstructionsModal);
  const updateDumpYardInstructionsAPI = useAppSelector(state => state.dumpYards.updateDumpYardInstructionsAPI);

  const isOpen = !!dumpYard;

  const initialValues: UpdateDumpYardInstructionsFormModel  = {
    instructions: !!dumpYard ? dumpYard.instructions : ''
  };

  React.useEffect(() => {
    if (!updateDumpYardInstructionsAPI.loading && !!updateDumpYardInstructionsAPI.error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!updateDumpYardInstructionsAPI.loading && !updateDumpYardInstructionsAPI.error && updateDumpYardInstructionsAPI.value) {
      toast.success('Successfully updated instructions.');
      toggleModal();
    }
  }, [updateDumpYardInstructionsAPI.loading, !!updateDumpYardInstructionsAPI.error, !!updateDumpYardInstructionsAPI.value]);


  const toggleModal = () => {
    dispatch(setShowUpdateDumpYardInstructionsModal(undefined));
  }

  const onSubmit = (form: UpdateDumpYardInstructionsFormModel) => {
    if (!dumpYard) return;

    dispatch(updateDumpYardInstructions({
      id: dumpYard?.id,
      instructions: form.instructions,
    }));
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      style={modalStyles}
    >
      <div className='update-dump-yard-instructions-modal-component'>
        <Close className='close-button' onClick={toggleModal}/>

        <div className='title'>Update Dump Yard Instructions</div>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className='update-dump-yard-instructions-form'>
              <div className='field-container'>
                <div className='label'>Instructions:</div>
                <Field id='instructions' name='instructions' className='text-input'/>
              </div>

              <button type='submit' className='update-button'  disabled={updateDumpYardInstructionsAPI.loading}>
                Update Instructions
                {updateDumpYardInstructionsAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
              </button>

              {!!updateDumpYardInstructionsAPI.error && (
                <div className='error-text'>
                  Something went wrong. Please try again.
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
