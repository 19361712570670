import React from 'react';
import { useTable, Column, useSortBy } from 'react-table';
import { BsPlusCircle, BsFilter, BsSearch, BsSortDown, BsSortUp, BsTrash, BsCalendarPlus } from 'react-icons/bs';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { toggleShowAddPermanentRentalModal, showDeletePermanentRentalConfirmationModal, setCreateOrderPermanentRental } from '../permanentRentalsSlice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { PermanentRentalTemplate } from '../../../models';

import './PermanentRentals.scss';

type ColumnsType = PermanentRentalTemplate

export function PermanentRentals() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const permanentRentalTemplates = useAppSelector(state => state.permanentRentals.templates);
  const deletePermanentRentalTemplateAPI = useAppSelector(state => state.permanentRentals.deletePermanentRentalTemplate);
  const templateToRemove = useAppSelector(state => state.permanentRentals.templateToRemove);

  const [search, setSearch] = React.useState<string>('');

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value);
  }

  const onAddPermanentRental = () => {
    dispatch(toggleShowAddPermanentRentalModal());
  }

  const data = React.useMemo(() => {
    if (search === '') return permanentRentalTemplates;

    return permanentRentalTemplates.filter(d => d.address.toLowerCase().includes(search) || d.zip.toLowerCase().includes(search));
  }, [permanentRentalTemplates, search]);

  const columns: Array<Column<ColumnsType>> = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'ZIP',
        accessor: 'zip',
      },
      {
        Header: 'Dumpster Size',
        accessor: 'dumpsterSize',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: props => {
          const { value, row } = props;

          const onRemoveTemplate = () => {
            dispatch(showDeletePermanentRentalConfirmationModal(row.original));
          };

          const onAddOrder = () => {
            dispatch(setCreateOrderPermanentRental(row.original));
            navigate('/create-order');
          };

          return (
            <div className='actions-cell'>
              {deletePermanentRentalTemplateAPI.loading && templateToRemove === value ? <Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/> : <BsTrash className='delete-icon' onClick={onRemoveTemplate}/>}
              <BsCalendarPlus className='add-icon' onClick={onAddOrder}/>
            </div>
          );
        }
      }
    ],
    [templateToRemove]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  return (
    <div className='permanent-rentals-component'>
      <div className='controllers-container'>
        <div className='left-controllers-container'>
          <div className='title-container'>
            <div className='title-text'>Permanent rentals</div>
            <div className='title-count'>{data.length}</div>
          </div>

          <div className='search-container'>
            <BsSearch className='search-icon'/>
            <input
              value={search}
              placeholder='Search'
              onChange={onInputChange}
            />
          </div>
        </div>

        <div className='add-button' onClick={onAddPermanentRental}>
          <BsPlusCircle className='icon'/>  
          Add Rental
        </div>
      </div>

      <div className='table-container'>
        <table {...getTableProps()} className='tp-table'>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className='header-cell'>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <BsSortDown className='sorting-icon'/>
                          : <BsSortUp className='sorting-icon'/>
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
