import { DumpsterSize } from '../../../models';
import { PermanentRentalTemplateForm } from '../TriplePInterface';

export interface PermanentRentalTemplatePayload {
  data: {
    type: string;
    attributes: {
      name: string;
      phone_number: string;
      email: string;
      address: string;
      zip: string;
      dumpster_size: DumpsterSize;
    }
  }
}

export const toPermanentRentalTemplate = (form: PermanentRentalTemplateForm): PermanentRentalTemplatePayload => {
  return {
    data: {
      type: 'permanent_rental_templates',
      attributes: {
        address: form.address,
        zip: form.zip,
        name: form.name,
        email: form.email,
        phone_number: form.phoneNumber,
        dumpster_size: form.dumpsterSize
      }
    }
  };
}
