import React from 'react';
import Modal from 'react-modal';
import { Field, Form, Formik } from 'formik';
import { Oval } from 'react-loader-spinner';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { toggleShowAddDumpYardModal, createDumpYardTemplate } from '../dumpYardsSlice';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { RadioButton } from '../../Shared';
import { DumpYardTemplate, DumpYardTemplateType } from '../../../models';

import './AddDumpYardModal.scss';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF"
  },
};

const validationSchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  zip: Yup.string().required('ZIP is required')
});

export type AddDumpYardFormModel = Pick<DumpYardTemplate, 'address' | 'zip' | 'name' | 'isDumpYard' | 'isStorage' | 'isQuickStop'> & { type: DumpYardTemplateType }

const initialValues: AddDumpYardFormModel = {
  address: '',
  zip: '',
  name: '',
  isDumpYard: false,
  isStorage: false,
  isQuickStop: false,
  type: DumpYardTemplateType.DUMP_YARD
};

export function AddDumpYardModal() {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(state => state.dumpYards.showAddDumpYardModal);
  const addDumpYardAPI = useAppSelector(state => state.dumpYards.createDumpYardTemplate);

  React.useEffect(() => {
    if (!addDumpYardAPI.loading && !!addDumpYardAPI.error) {
      toast.error('Something went wrong. Please try again.');
    }

    if (!addDumpYardAPI.loading && !addDumpYardAPI.error && addDumpYardAPI.value) {
      toast.success('Successfully added dump yard.');
      dispatch(toggleShowAddDumpYardModal());
    }
  }, [addDumpYardAPI.loading, !!addDumpYardAPI.error, !!addDumpYardAPI.value]);

  const toggleModal = () => {
    dispatch(toggleShowAddDumpYardModal());
  }

  const onSubmit = (form: AddDumpYardFormModel) => {
    form.isDumpYard = form.type === DumpYardTemplateType.DUMP_YARD;
    form.isStorage = form.type === DumpYardTemplateType.STORAGE;
    form.isQuickStop = form.type === DumpYardTemplateType.QUICK_STOP;

    dispatch(createDumpYardTemplate(form));
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      style={modalStyles}
    >
      <div className='add-dump-yard-modal-component'>
        <Close className='close-button' onClick={toggleModal}/>

        <div className='title'>Add Dump Yard</div>
        
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form className='add-dump-yard-form'>
              <div className='field-container'>
                <div className='label'>Address:</div>
                <Field id='address' name='address' className='text-input'/>
                {errors.address && touched.address && <div className='form-error'>{errors.address}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Name:</div>
                <Field id='name' name='name' className='text-input'/>
                {errors.name && touched.name && <div className='form-error'>{errors.name}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>ZIP:</div>
                <Field id='zip' name='zip' className='text-input'/>
                {errors.zip && touched.zip && <div className='form-error'>{errors.zip}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Type:</div>
                <div className='radio-button-options-container'>
                  <RadioButton name="type" value={DumpYardTemplateType.DUMP_YARD} label='Dump Yard'/>
                  <RadioButton name="type" value={DumpYardTemplateType.STORAGE} label='Storage'/>
                  <RadioButton name="type" value={DumpYardTemplateType.QUICK_STOP} label='Quick Stop'/>
                </div>
              </div>

              <button type='submit' className='add-button'  disabled={addDumpYardAPI.loading}>
                Add Dump Yard
                {addDumpYardAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
              </button>

              {!!addDumpYardAPI.error && (
                <div className='error-text'>
                  Something went wrong. Please try again.
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
