import React from 'react';
import { useField } from 'formik';

import './RadioButton.scss';

export interface RadioButtonProps {
  name: string;
  value: string;
  label: string;
}

export const RadioButton = (props: RadioButtonProps) => {
  const { name, value, label } = props;

  const [field, meta, helpers] = useField({ name, value, type: 'radio' });

  const onClick = (e: React.MouseEvent<any>) => {
    helpers.setValue(field.value);
  }

  const classes = `form-radio-button ${meta.value === value ? 'selected' : ''}`

  return (
    <div id={field.name} onClick={onClick} className={classes}>
      {label}
    </div>
  );
};
