import React from 'react';

import { ReactComponent as NavigationBackIcon } from '../../../../assets/images/navigation_back_icon.svg';
import './BackButton.scss';

export interface BackButtonProps {
  onClick: () => void;
}

export function BackButton(props: BackButtonProps) {
  const { onClick } = props;

  return (
    <div className='back-button-component' onClick={onClick}>
      <NavigationBackIcon />
      <div className='copy'>Back</div>
    </div>
  );
}
