import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Close } from '../../../assets/images/close.svg';

import { LinkForm } from './LoginForm';

import './Login.scss';

export function Login() {
  return (
    <div className='login-component'>
      <div className='layout-container'>
      <Link to='/'>
        <Close className='close-button'/>
      </Link>
        <div className='content-container'>
          <div className='title'>Login</div>

          <LinkForm />

          <div className='login-container'>
            <div className='already-text'>Don't have an account?</div>
            <Link to='/auth/register' className='register-link'>
              <div className='login-text'>Create account</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
